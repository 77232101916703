import { AxiosResponse, AxiosResponseHeaders, RawAxiosResponseHeaders } from 'axios';
import queryString from 'query-string';
import { Nullable } from 'vitest';

import { InputDataOrVoid } from '../NoddiAsync';
import {
  AddressOutput,
  Availability,
  AvailabilityUpdateInput,
  AvailableBookingTimeWindowsByDateNew,
  AvailableBookingTimeWindowsByDateNewV2,
  AvailableBookingTimeWindowsInput,
  AvailableBookingTimeWindowsInputV2,
  AvailableSalesItem,
  AvailableSalesItemsForBooking,
  AvailableSalesItemsForBookingInput,
  AvailableSalesItemsForBookingV2,
  BookingAddress,
  BookingListItem,
  BookingListItemWithSalesItems,
  BookingReceipt,
  BookingSMS,
  BookingSMSCreateInput,
  BookingStatusDto,
  Capability,
  CapabilityCreateInput,
  CapabilityListItem,
  CapabilityUpdateInput,
  CapacityContributionDetails,
  CapacityDay,
  CarDetailType,
  CarGeneration,
  CarGenerationCreateInput,
  CarGenerationListInput,
  CarGenerationUpdateInput,
  CarListItem,
  CarMake,
  CarMakeCreateInput,
  CarMakeUpdateInput,
  CarModel,
  CarModelCreateInput,
  CarModelUpdateInput,
  CarPostInputType,
  CarWheelPosition,
  CarWheelSetSpecifications,
  CarWheelSetSpecificationsCreateInput,
  CarWheelSetSpecificationsListInput,
  CarWheelSetSpecificationsPatchBulkUpdateInput,
  CarWheelSetSpecificationsUpdateInput,
  CarWheelSetType,
  ContactPerson,
  ContactPersonAvailability,
  ContactPersonAvailabilityCreateInput,
  ContactPersonCreateInput,
  ContactPersonDepartmentAddress,
  ContactPersonDepartmentAddressCreateInput,
  ContactPersonListItem,
  ContactPersonUpdateInput,
  CreateCapacityContributionDto,
  EditBookingPermissions,
  EditBookingTimeWindowData,
  GetSalesItemForBooking,
  GroupAdminType,
  Invoice,
  InvoiceCreateInput,
  InvoiceListItem,
  InvoiceUpdateInput,
  Language,
  LicensePlateInfo,
  LicensePlateNumberData,
  ListAvailabilitiesInput,
  ListContactPersonDepartmentAddressInput,
  ListContactPersonInput,
  MembershipDiscount,
  MembershipDiscountCreateInput,
  MembershipDiscountListItem,
  MembershipDiscountUpdateInput,
  MembershipProgram,
  MembershipProgramCreateInput,
  MembershipProgramListItem,
  MembershipProgramUpdateInput,
  OptionsTypeString,
  PaginatedInput,
  PaginatedResponse,
  PostCreateBookingV2,
  PostUserInput,
  PostUserWithOrganizationInput,
  PublicCoupon,
  RouteItem,
  SalesItemCategory,
  SalesItemV2,
  SelectedSalesItemsCarGrouped,
  Skill,
  SkillListItem,
  SkillPatchInput,
  SkillPostInput,
  StorageUnit,
  StorageUnitInput,
  TierHotelPickupSalesItemOption,
  UnavailableSalesItemV2,
  UpdateCapacityContributionDto,
  User,
  UserData,
  UserDataProps,
  UserGroupAddress,
  UserGroupBooking,
  UserGroupMemberNotificationSettings,
  UserGroupType,
  UserGroupsType,
  UserListItem
} from '../types';
import { RearrangeRouteItem } from '../types/adminapp/route/rearrangeRoutes';
import { AddressForNewBooking, AddressSuggestion } from '../types/customerapp/booking/shared';
import { DeliveryNotificationRequestInput } from '../types/shared/notifications';
import { CarWheelSetToPickUp, CarWheelSetToPickUpInput } from '../types/shared/pickUpList';
import { TireQuote, TireQuoteById } from '../types/shared/quotes';
import { CarWheelSetForLabelPrint, RouteLabel, RouteLabelInput } from '../types/workerapp/routeLabels';
import { convertObjectKeysToSnakeCase, downloadToExcel } from '../utils';
import {
  AdminExternalServerRequests,
  AdminExternalURLKeys,
  AdminExternalURLKeysType,
  getAdminExternalUrls
} from './adminExternalResources';
import { AdminServerRequests, AdminURLKeys, AdminURLKeysType, getAdminUrls } from './adminResources';
import { FetchMethods, ResponseType, apiVersion } from './commonResourceTypes';
import { CustomerServerRequests, CustomerURLKeys, CustomerURLKeysType, getCustomerUrls } from './customerResources';
import {
  OrganizationServerRequests,
  OrganizationURLKeys,
  OrganizationURLKeysType,
  getOrganizationUrls
} from './organizationResources';
import { WorkerServerRequests, WorkerURLKeys, WorkerURLKeysType, getWorkerUrls } from './workerResources';

export function getNoddiUrl<Type extends URLKeysTypes>({
  queryParams,
  path
}: {
  queryParams?: Partial<InputData<Type>>;
  path: string;
}): string {
  // filter out empty params
  const filteredQueryParams = Object.entries(queryParams || {}).reduce(
    (acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, unknown>
  );

  const _queryParams = queryString.stringify(convertObjectKeysToSnakeCase(filteredQueryParams || {}), {
    arrayFormat: 'none'
  });

  const queryParamString = _queryParams ? `?${_queryParams}` : '';

  return `/${path}${queryParamString}`;
}

// Base urls that are used across multiple apps
const BaseUrlKeys = {
  getPaginatedCapabilities: 'getPaginatedCapabilities',
  getCapabilities: 'getCapabilities',
  postCapability: 'postCapability',
  patchCapability: 'patchCapability',
  getCapability: 'getCapability',
  getTimeZones: 'getTimeZones',
  getRouteItemStatusById: 'getRouteItemStatusById',
  getUserGroupMembersNotificationSettings: 'getUserGroupMembersNotificationSettings',
  getUserGroupMemberNotificationSettings: 'getUserGroupMemberNotificationSettings',
  postTokenLogin: 'postTokenLogin',
  getPaginatedSalesItemCategories: 'getPaginatedSalesItemCategories',
  postSalesItemCategory: 'postSalesItemCategory',
  patchSalesItemCategory: 'patchSalesItemCategory',
  postVerifyPhoneNumber: 'postVerifyPhoneNumber',
  postUser: 'postUser',
  postDeliveryNotificationRequest: 'postDeliveryNotificationRequest',
  postEmailLogin: 'postEmailLogin',
  getPhoneNumberVerification: 'getPhoneNumberVerification',
  getPaginatedUsers: 'getPaginatedUsers',
  getUser: 'getUser',
  getGroupsOfUser: 'getGroupsOfUser',
  getDataFromLicensePlateNumber: 'getDataFromLicensePlateNumber',
  getCarFromLicensePlateNumber: 'getCarFromLicensePlateNumber',
  getSalesItemsForBooking: 'getSalesItemsForBooking',
  getWheelStorageSalesItemsOptions: 'getWheelStorageSalesItemsOptions',
  getTierHotelPickupSalesItemsOptionsV2: 'getTierHotelPickupSalesItemsOptionsV2',
  getTierHotelCancelSalesItemsOptionsV2: 'getTierHotelCancelSalesItemsOptionsV2',
  getAddonsForBooking: 'getAddonsForBooking',
  getUserGroupCoupons: 'getUserGroupCoupons',
  // TODO | New booking flow | deprecate this
  getUserGroupAddresses: 'getUserGroupAddresses',
  getUserGroupAddressesV2: 'getUserGroupAddressesV2',
  getUserGroupAddressesValidForNewBooking: 'getUserGroupAddressesValidForNewBooking',
  postCar: 'postCar',
  getPaginatedBookings: 'getPaginatedBookings',
  getPaginatedBookingSMS: 'getPaginatedBookingSMS',
  getBookingSMS: 'getBookingSMS',
  postBookingSMS: 'postBookingSMS',
  postBookingSMSResend: 'postBookingSMSResend',
  postAddUserToUserGroup: 'postAddUserToUserGroup',
  getPaginatedMembershipDiscounts: 'getPaginatedMembershipDiscounts',
  getMembershipDiscount: 'getMembershipDiscount',
  postMembershipDiscount: 'postMembershipDiscount',
  patchMembershipDiscount: 'patchMembershipDiscount',
  deleteMembershipDiscount: 'deleteMembershipDiscount',
  getMembershipProgram: 'getMembershipProgram',
  getPaginatedMembershipPrograms: 'getPaginatedMembershipPrograms',
  postMembershipProgram: 'postMembershipProgram',
  patchMembershipProgram: 'patchMembershipProgram',
  deleteMembershipProgram: 'deleteMembershipProgram',
  postCreateBooking: 'postCreateBooking',
  getCar: 'getCar',
  getCarWheelSetTypes: 'getCarWheelSetTypes',
  getCarWheelPositions: 'getCarWheelPositions',
  getAvailableBookingTimeWindowsByDateNew: 'getAvailableBookingTimeWindowsByDateNew',
  getAvailableBookingTimeWindowsByDateNewV2: 'getAvailableBookingTimeWindowsByDateNewV2',
  getLatestCreatedBookingTimeWindowDate: 'getLatestCreatedBookingTimeWindowDate',
  getLanguages: 'getLanguages',
  postMakeGroupAdmin: 'postMakeGroupAdmin',
  postRemoveUserFromGroup: 'postRemoveUserFromGroup',
  patchUser: 'patchUser',
  getCapacityDays: 'getCapacityDays',
  getLatestCapacityDay: 'getLatestCapacityDay',
  postCapacityContribution: 'postCapacityContribution',
  patchCapacityContribution: 'patchCapacityContribution',
  getCapacityContribution: 'getCapacityContribution',
  getBookingsForCar: 'getBookingsForCar',
  getNumBookings: 'getNumBookings',
  getBookingsCosts: 'getBookingsCosts',
  patchBookingItem: 'patchBookingItem',
  postCancelBookingItem: 'postCancelBookingItem',
  getBookingStatuses: 'getBookingStatuses',
  getPaginatedUserGroupCarsForBooking: 'getPaginatedUserGroupCarsForBooking',
  downloadBookingReceipt: 'downloadBookingReceipt',
  getPaginatedInvoices: 'getPaginatedInvoices',
  getInvoice: 'getInvoice',
  deleteInvoice: 'deleteInvoice',
  postInvoiceToExcel: 'postInvoiceToExcel',
  postInvoice: 'postInvoice',
  patchInvoice: 'patchInvoice',
  getPaginatedCarMakes: 'getPaginatedCarMakes',
  getCarMake: 'getCarMake',
  postCarMake: 'postCarMake',
  patchCarMake: 'patchCarMake',
  getPaginatedCarModels: 'getPaginatedCarModels',
  getPaginatedCarModelsForMake: 'getPaginatedCarModelsForMake',
  getCarModel: 'getCarModel',
  postCarModel: 'postCarModel',
  patchCarModel: 'patchCarModel',
  getPaginatedCarGenerations: 'getPaginatedCarGenerations',
  getPaginatedCarGenerationsForModel: 'getPaginatedCarGenerationsForModel',
  getCarGeneration: 'getCarGeneration',
  postCarGeneration: 'postCarGeneration',
  patchCarGeneration: 'patchCarGeneration',
  getCarGenerationSearchNameApiProviders: 'getCarGenerationSearchNameApiProviders',
  getCarWheelSetSpecifications: 'getCarWheelSetSpecifications',
  postCarWheelSetSpecifications: 'postCarWheelSetSpecifications',
  patchCarWheelSetSpecifications: 'patchCarWheelSetSpecifications',
  patchBulkCarWheelSetSpecifications: 'patchBulkCarWheelSetSpecifications',
  getPaginatedCarWheelSetSpecifications: 'getPaginatedCarWheelSetSpecifications',
  getPaginatedCarWheelSetSpecificationsForGeneration: 'getPaginatedCarWheelSetSpecificationsForGeneration',
  getPaginatedContactPersons: 'getPaginatedContactPersons',
  getContactPerson: 'getContactPerson',
  postContactPerson: 'postContactPerson',
  patchContactPerson: 'patchContactPerson',
  getPaginatedAvailabilities: 'getPaginatedAvailabilities',
  getAvailability: 'getAvailability',
  patchAvailability: 'patchAvailability',
  deleteAvailability: 'deleteAvailability',
  getPaginatedContactPersonDepartmentAddresses: 'getPaginatedContactPersonDepartmentAddresses',
  postContactPersonDepartmentAddress: 'postContactPersonDepartmentAddress',
  postContactPersonAvailability: 'postContactPersonAvailability',
  getPaginatedSkills: 'getPaginatedSkills',
  getSkill: 'getSkill',
  postSkill: 'postSkill',
  patchSkill: 'patchSkill',
  deleteSkill: 'deleteSkill',
  getStorageUnits: 'getStorageUnits',
  getRouteLabels: 'getRouteLabels',
  getPaginatedPrintingLabels: 'getPaginatedPrintingLabels',
  getUserGroupBookings: 'getUserGroupBookings',
  getUserGroupNumberOfBookings: 'getUserGroupNumberOfBookings',
  getCarWheelSetToPickUp: 'getCarWheelSetToPickUp',
  getEditBookingPermissions: 'getEditBookingPermissions',
  getUpdateBookingTimeWindowData: 'getUpdateBookingTimeWindowData',
  postUserWithOrganization: 'postUserWithOrganization',
  postDeleteCar: 'postDeleteCar',
  getRoutesDragNDrop: 'getRoutesDragNDrop',
  postAddSalesItemsToBooking: 'postAddSalesItemsToBooking',
  getAvailableSalesItemsForBooking: 'getAvailableSalesItemsForBooking',
  getUnavailableSalesItems: 'getUnavailableSalesItems',
  postFetchAddons: 'postFetchAddons',
  getInitialAvailableSalesItems: 'getInitialAvailableSalesItems',
  getAvailableTireHotelCancellationOptions: 'getAvailableTireHotelCancellationOptions',
  getCarTireQuoteBySlug: 'getCarTireQuoteBySlug',
  getCarTireQuoteById: 'getCarTireQuoteById',
  getCarWheelSetsDimensions: 'getCarWheelSetsDimensions',
  postRejectTireOfferQuote: 'postRejectTireOfferQuote',
  getAddressSuggestions: 'getAddressSuggestions',
  postAddressCreatedFromPlaceId: 'postAddressCreatedFromPlaceId',
  getMe: 'getMe',
  getCurrencies: 'getCurrencies',
  getServiceWorkerContractAddresses: 'getServiceWorkerContractAddresses'
} as const;

export const URLKeys = {
  ...BaseUrlKeys,
  ...WorkerURLKeys,
  ...AdminURLKeys,
  ...CustomerURLKeys,
  ...AdminExternalURLKeys,
  ...OrganizationURLKeys
};

type BaseUrlKeysType = keyof typeof BaseUrlKeys;

export type URLKeysTypes =
  | BaseUrlKeysType
  | CustomerURLKeysType
  | AdminURLKeysType
  | WorkerURLKeysType
  | AdminExternalURLKeysType
  | OrganizationURLKeysType;

export type InputData<urlKey extends URLKeysTypes> = ServerRequests[urlKey]['input'];
export type OutputData<urlKey extends URLKeysTypes> = ServerRequests[urlKey]['output'];

export type ReturnType<Type extends URLKeysTypes, _> = OutputData<Type>;

export type CommonAxiosResType<key extends URLKeysTypes> = AxiosResponse<
  OutputData<key>,
  {
    status: number;
    statusText: string;
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
    config: unknown;
    request?: unknown;
  }
>;

export const getUrls: {
  [key in URLKeysTypes]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>> | FormData;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    getHeaders?: (input: InputDataOrVoid<key>) => Record<string, string>;
    useSuperUserToken?: boolean;
    skipConvertingToCamelCase?: boolean;
    skipBodyTransformation?: boolean;
  };
} = {
  getPaginatedCapabilities: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCapabilities: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/`
      }),
    method: FetchMethods.get
  },
  postCapability: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/`
      }),
    method: FetchMethods.post,
    getBody: (data) => data
  },
  patchCapability: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/${id}/`,
        queryParams
      }),
    method: FetchMethods.patch,
    getBody: (data) => data
  },
  getCapability: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}capabilities/${id}/`
      }),
    method: FetchMethods.get
  },
  getCurrencies: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}currencies/`
      }),
    method: FetchMethods.get
  },
  postAddSalesItemsToBooking: {
    getUrl({ bookingId }) {
      return getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/add-sales-items/`
      });
    },
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getTimeZones: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}time-zones/`
      }),
    method: FetchMethods.get
  },
  getRoutesDragNDrop: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}routes/to-drag-and-drop/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRouteLabels: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}routes/for-label-printing/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedPrintingLabels: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/for-label-printing/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getRouteItemStatusById: {
    getUrl: ({ routeItemId }) =>
      getNoddiUrl({
        path: `${apiVersion}route-items/${routeItemId}/status-details/`
      }),
    method: FetchMethods.get
  },
  getUserGroupMembersNotificationSettings: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-group-members/`
      }),
    method: FetchMethods.get
  },
  getUserGroupMemberNotificationSettings: {
    getUrl: ({ userGroupMemberId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-group-members/${userGroupMemberId}/`
      }),
    method: FetchMethods.get
  },
  getPaginatedSalesItemCategories: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-item-categories/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postSalesItemCategory: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-item-categories/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  patchSalesItemCategory: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}sales-item-categories/${id}/`
      }),
    method: FetchMethods.patch,
    getBody: (args) => args
  },
  postTokenLogin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}login-token/`
      }),
    getBody: (data) => data,
    getHeaders: ({ token }) => ({
      Authorization: `Token ${token}`
    }),
    method: FetchMethods.post
  },
  postVerifyPhoneNumber: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/verify-phone-number/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postUser: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  postDeliveryNotificationRequest: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}delivery-notification-requests/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  postUserWithOrganization: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}users/create-with-organization/`
      }),
    method: FetchMethods.post,
    getBody: (args) => args
  },
  patchUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}users/${id}/`
      }),
    method: FetchMethods.patch,
    getBody: (args) => args
  },
  postEmailLogin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}login/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPhoneNumberVerification: {
    getUrl: ({ phoneNumber }) => {
      return getNoddiUrl({
        path: `${apiVersion}users/send-phone-number-verification/?phone_number=${phoneNumber}`
      });
    },
    method: FetchMethods.get
  },
  getPaginatedUsers: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}users/`,
        queryParams
      }),
    method: FetchMethods.get,
    useSuperUserToken: true
  },
  getUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}users/${id}/`
      }),
    method: FetchMethods.get
  },
  getMe: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user`
      }),
    method: FetchMethods.get
  },
  postAddUserToUserGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/add-user-to-group/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getGroupsOfUser: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/get-user-groups/${id}/`
      }),
    method: FetchMethods.get
  },
  getPaginatedCarGenerations: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarGenerationsForModel: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/car-generations/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getDataFromLicensePlateNumber: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}cars/data-from-license-plate-number/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarFromLicensePlateNumber: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}cars/from-license-plate-number/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getSalesItemsForBooking: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/for-booking/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getWheelStorageSalesItemsOptions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/wheel-storage-options/`
      }),
    method: FetchMethods.get
  },
  getTierHotelPickupSalesItemsOptionsV2: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/wheels-pickup-options/`
      }),
    method: FetchMethods.get
  },
  getTierHotelCancelSalesItemsOptionsV2: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/tire-hotel-cancellation-options/`
      }),
    method: FetchMethods.get
  },
  getAddonsForBooking: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/addons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUserGroupCoupons: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/coupons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postCar: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}cars/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPaginatedBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUserGroupBookings: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/bookings/`
      }),
    method: FetchMethods.get
  },
  getUserGroupNumberOfBookings: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/number-of-bookings/`
      }),
    method: FetchMethods.get
  },
  getPaginatedBookingSMS: {
    getUrl: ({ bookingId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/sms/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getBookingSMS: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-sms/${id}/`
      }),
    method: FetchMethods.get
  },
  postBookingSMS: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-sms/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postBookingSMSResend: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-sms/${id}/retry-failed-sms/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getUserGroupAddresses: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/addresses?user_group_id=${userGroupId}`
      }),
    method: FetchMethods.get
  },
  getUserGroupAddressesV2: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/addresses`
      }),
    method: FetchMethods.get
  },
  getUserGroupAddressesValidForNewBooking: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/addresses-for-new-booking/`
      }),
    method: FetchMethods.get
  },
  getPaginatedMembershipDiscounts: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}/`
      }),
    method: FetchMethods.get
  },
  postMembershipDiscount: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postCreateBooking: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/create-v2/`
      }),
    getBody: (data) => data,
    useSuperUserToken: true,
    method: FetchMethods.post
  },
  patchMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteMembershipDiscount: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}memberships-discounts/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.delete
  },
  getMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}/`
      }),
    method: FetchMethods.get
  },
  getPaginatedMembershipPrograms: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postMembershipProgram: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteMembershipProgram: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}membership-programs/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.delete
  },
  getAvailableBookingTimeWindowsByDateNew: {
    getUrl: (args) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/for-booking/`,
        queryParams: args
      }),
    useSuperUserToken: true,
    method: FetchMethods.get
  },
  getAvailableBookingTimeWindowsByDateNewV2: {
    getUrl: (args) =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/for-new-booking/`,
        queryParams: args
      }),
    method: FetchMethods.get
  },
  getCar: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}/`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetTypes: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-types/`
      }),
    method: FetchMethods.get
  },
  getCarWheelPositions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-positions/`
      }),
    method: FetchMethods.get
  },
  patchBookingItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${id}/`
      }),
    getHeaders: () => ({
      'Content-Type': 'multipart/form-data'
    }),
    getBody: (args) => args.formData,
    skipBodyTransformation: true,
    method: FetchMethods.patch
  },
  postCancelBookingItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/${id}/cancel/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postMakeGroupAdmin: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/change-member-permission/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postRemoveUserFromGroup: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/remove-user-from-group/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getLatestCreatedBookingTimeWindowDate: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}booking-time-windows/latest-created-date/`
      }),
    method: FetchMethods.get
  },
  getLanguages: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}languages/`
      }),
    method: FetchMethods.get
  },
  getCapacityDays: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-days/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getLatestCapacityDay: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-days/latest/`
      }),
    method: FetchMethods.get
  },
  postCapacityContribution: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCapacityContribution: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCapacityContribution: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}capacity-contributions/${id}/`
      }),
    method: FetchMethods.get
  },
  getBookingsForCar: {
    getUrl: ({ carId, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${carId}/bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getNumBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/num-bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getBookingsCosts: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/costs/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getBookingStatuses: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}bookings/statuses/`
      }),
    method: FetchMethods.get
  },
  getPaginatedUserGroupCarsForBooking: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${id}/cars-for-booking/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  downloadBookingReceipt: {
    getUrl: ({ bookingId }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${bookingId}/download-receipt/`
      }),
    responseType: 'arraybuffer',
    handleRes: async (res) => {
      const contentDisposition = res.headers['content-disposition'];
      const filename = contentDisposition.split('filename=')[1].split(';')[0];

      const bytes = new Uint8Array(res.data);
      const blob = new Blob([bytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      return res.data;
    },
    method: FetchMethods.get
  },
  getPaginatedInvoices: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/`
      }),
    method: FetchMethods.get
  },
  deleteInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/`
      }),
    method: FetchMethods.delete
  },
  postInvoiceToExcel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/to-excel/`
      }),
    method: FetchMethods.post,
    handleRes: async (res) => downloadToExcel(res),
    responseType: 'arraybuffer'
  },
  postInvoice: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}invoices/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchInvoice: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}invoices/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedCarMakes: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarMake: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarMake: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCarMake: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedCarModels: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarModelsForMake: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}car-makes/${id}/car-models/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarModel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarModel: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-models/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCarModel: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-models/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCarGeneration: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarGeneration: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCarGeneration: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getCarGenerationSearchNameApiProviders: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-generation-search-names/api-providers/`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetSpecifications: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/${id}/`
      }),
    method: FetchMethods.get
  },
  postCarWheelSetSpecifications: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchCarWheelSetSpecifications: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  patchBulkCarWheelSetSpecifications: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/bulk-update/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedCarWheelSetSpecifications: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-set-specifications/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedCarWheelSetSpecificationsForGeneration: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}car-generations/${id}/car-wheel-set-specifications/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedContactPersons: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}contact-persons/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getContactPerson: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}contact-persons/${id}/`
      }),
    method: FetchMethods.get
  },
  postContactPerson: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}contact-persons/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchContactPerson: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}contact-persons/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  getPaginatedAvailabilities: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}availabilities/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getAvailability: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}availabilities/${id}/`
      }),
    method: FetchMethods.get
  },
  patchAvailability: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}availabilities/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteAvailability: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}availabilities/${id}/`
      }),
    method: FetchMethods.delete
  },
  getPaginatedContactPersonDepartmentAddresses: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}contact-person-department-addresses/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postContactPersonDepartmentAddress: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}contact-person-department-addresses/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postContactPersonAvailability: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}contact-person-availabilities/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPaginatedSkills: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}skills/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}/`
      }),
    method: FetchMethods.get
  },
  postSkill: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}skills/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}/`
      }),
    getBody: (data) => data,
    method: FetchMethods.patch
  },
  deleteSkill: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}skills/${id}/`
      }),
    method: FetchMethods.delete
  },
  getStorageUnits: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}storage-units/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarWheelSetToPickUp: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/to-pickup/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getEditBookingPermissions: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/edit-permissions/`
      }),
    method: FetchMethods.get
  },
  getUpdateBookingTimeWindowData: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/${id}/update-time-window-data/`
      }),
    method: FetchMethods.get
  },
  postDeleteCar: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}cars/${id}/`
      }),
    method: FetchMethods.delete
  },
  getAvailableSalesItemsForBooking: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/available-for-booking/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getUnavailableSalesItems: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/unavailable-for-new-booking/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postFetchAddons: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/addons-new/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getInitialAvailableSalesItems: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/initial-available-for-booking/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getAvailableTireHotelCancellationOptions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}sales-items/available-for-tire-hotel-cancellation/`
      }),
    method: FetchMethods.get
  },
  getCarTireQuoteBySlug: {
    getUrl: ({ slug }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${slug}/`
      }),
    method: FetchMethods.get
  },
  getCarTireQuoteById: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${id}/`
      }),
    method: FetchMethods.get
  },
  getCarWheelSetsDimensions: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}car-wheel-sets/dimensions/`
      }),
    method: FetchMethods.get
  },
  getAddressSuggestions: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}addresses/suggestions/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postRejectTireOfferQuote: {
    getUrl: ({ tireQuoteId }) =>
      getNoddiUrl({
        path: `${apiVersion}car-tire-quotes/${tireQuoteId}/reject/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  postAddressCreatedFromPlaceId: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}addresses/create-from-google-place-id/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getServiceWorkerContractAddresses: {
    getUrl: ({ serviceWorkerContractId }) =>
      getNoddiUrl({
        path: `${apiVersion}service-worker-contracts/${serviceWorkerContractId}/addresses/`
      }),
    method: FetchMethods.get
  },

  ...getAdminUrls,
  ...getCustomerUrls,
  ...getWorkerUrls,
  ...getOrganizationUrls,
  ...getAdminExternalUrls
} as const;

interface ServerRequests
  extends CustomerServerRequests,
    AdminServerRequests,
    WorkerServerRequests,
    AdminExternalServerRequests,
    OrganizationServerRequests {
  getPaginatedCapabilities: {
    input: PaginatedInput;
    output: PaginatedResponse<CapabilityListItem[]>;
  };
  getCapabilities: {
    input: undefined;
    output: CapabilityListItem[];
  };
  getCapability: {
    input: { id: string };
    output: Capability;
  };
  postCapability: {
    input: CapabilityCreateInput;
    output: AxiosResponse<{ id: number }>;
  };
  patchCapability: {
    input: CapabilityUpdateInput;
    output: undefined;
  };
  postAddSalesItemsToBooking: {
    input: {
      bookingId: number;
      cars: {
        licensePlateNumber: string;
        countryCode: string;
        salesItemIds: number[];
      }[];
    };
    output: undefined;
  };
  getCurrencies: {
    input: undefined;
    output: string[];
  };
  getTimeZones: {
    input: undefined;
    output: string[];
  };
  getRoutesDragNDrop: {
    input: { date: string; serviceDepartmentsIds: number[] };
    output: RearrangeRouteItem[];
  };
  getRouteItemStatusById: {
    input: {
      routeItemId: number;
    };
    output: RouteItem;
  };
  getUserGroupMembersNotificationSettings: {
    input: undefined;
    output: PaginatedResponse<UserGroupMemberNotificationSettings[]>;
  };
  getUserGroupMemberNotificationSettings: {
    input: { userGroupMemberId?: number | null | undefined };
    output: UserGroupMemberNotificationSettings;
  };
  getPaginatedPrintingLabels: {
    input: PaginatedInput;
    output: PaginatedResponse<CarWheelSetForLabelPrint[]>;
  };
  getPaginatedSalesItemCategories: {
    input: PaginatedInput;
    output: PaginatedResponse<SalesItemCategory[]>;
  };
  postSalesItemCategory: {
    input: { name: string };
    output: SalesItemCategory;
  };
  patchSalesItemCategory: {
    input: SalesItemCategory;
    output: SalesItemCategory;
  };
  postTokenLogin: {
    input: {
      token: string;
      userGroupsType: UserGroupsType;
    };
    output: AxiosResponse<UserDataProps>;
  };
  postVerifyPhoneNumber: {
    input: {
      code: string;
      phoneNumber: string;
    };
    output: AxiosResponse<UserDataProps>;
  };
  postUser: {
    input: PostUserInput;
    output: AxiosResponse<UserDataProps>;
  };
  postUserWithOrganization: {
    input: PostUserWithOrganizationInput;
    output: AxiosResponse<UserDataProps>;
  };
  postDeliveryNotificationRequest: {
    input: DeliveryNotificationRequestInput;
    output: unknown;
  };
  patchUser: {
    input: Omit<Partial<User>, 'id'> & { id: number; languageCode?: string };
    output: AxiosResponse<UserData>;
  };
  postEmailLogin: {
    input: {
      email: string;
      password: string;
    };
    output: AxiosResponse<UserDataProps>;
  };
  getPhoneNumberVerification: {
    input: {
      phoneNumber: string;
    };
    output: unknown;
  };
  getPaginatedUsers: {
    input: PaginatedInput;
    output: PaginatedResponse<UserListItem[]>;
  };
  getUser: {
    input: { id: number | string | undefined | null };
    output: User;
  };
  postAddUserToUserGroup: {
    input: { userId: number; groupId: number };
    output: { message: string };
  };
  getGroupsOfUser: {
    input: { id: number };
    output: UserGroupType[];
  };
  getPaginatedCarGenerations: {
    input: PaginatedInput & CarGenerationListInput;
    output: PaginatedResponse<CarGeneration[]>;
  };
  getPaginatedCarGenerationsForModel: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarGeneration[]>;
  };
  getDataFromLicensePlateNumber: {
    input: {
      licensePlateNumber: string;
      countryCode: string;
    };
    output: LicensePlateNumberData;
  };
  getCarFromLicensePlateNumber: {
    input: {
      number: string;
      countryCode: string;
    };
    output: CarDetailType;
  };
  patchBookingItem: {
    input: { formData: FormData; id: number };
    output: unknown;
  };
  postCancelBookingItem: {
    input: { id: number };
    output: unknown;
  };
  getSalesItemsForBooking: {
    input: GetSalesItemForBooking;
    output: AvailableSalesItem[];
  };
  getAvailableSalesItemsForBooking: {
    input: AvailableSalesItemsForBookingInput;
    output: AvailableSalesItemsForBooking;
  };
  getUnavailableSalesItems: {
    input: {
      addressId?: number;
      cars: SelectedSalesItemsCarGrouped[];
    };
    output: AxiosResponse<UnavailableSalesItemV2[]>;
  };
  postFetchAddons: {
    input: {
      addressId?: number;
      cars: SelectedSalesItemsCarGrouped[];
    };
    output: AxiosResponse<AvailableSalesItemsForBookingV2>;
  };
  getInitialAvailableSalesItems: {
    input: {
      addressId?: number;
      licensePlates: LicensePlateInfo[];
    };
    output: AxiosResponse<AvailableSalesItemsForBookingV2>;
  };
  getWheelStorageSalesItemsOptions: {
    input: undefined;
    output: AvailableSalesItem[];
  };
  getTierHotelPickupSalesItemsOptionsV2: {
    input: undefined;
    output: TierHotelPickupSalesItemOption[];
  };
  getTierHotelCancelSalesItemsOptionsV2: {
    input: undefined;
    output: SalesItemV2[];
  };
  getAddonsForBooking: {
    input: {
      carSizeIds: number[];
      salesItemIds: number[];
    };
    output: AvailableSalesItem[];
  };
  getUserGroupCoupons: {
    input: {
      id: number;
      includeUsed?: boolean;
      includeUsedOnBookingIds: number[];
    };
    output: PublicCoupon[];
  };
  postCar: {
    input: CarPostInputType;
    output: CarDetailType;
  };
  getPaginatedBookings: {
    input: PaginatedInput & {
      userGroupId?: string;
      fromDate?: string | null;
      toDate?: string | null;
      invoiceId?: number | null;
    };
    output: PaginatedResponse<BookingListItem[]>;
  };
  getPaginatedBookingSMS: {
    input: PaginatedInput & { bookingId: number };
    output: BookingSMS[];
  };
  getBookingSMS: {
    input: {
      id: number;
    };
    output: BookingSMS;
  };
  postBookingSMS: {
    input: BookingSMSCreateInput;
    output: BookingSMS;
  };

  postBookingSMSResend: {
    input: {
      id: number;
    };
    output: BookingSMS;
  };
  getUserGroupAddresses: {
    input: {
      userGroupId: number;
    };
    output: UserGroupAddress[];
  };
  getUserGroupAddressesV2: {
    input: {
      id: number;
    };
    output: AddressOutput[];
  };
  getUserGroupAddressesValidForNewBooking: {
    input: {
      id: number;
    };
    output: BookingAddress[];
  };
  getPaginatedMembershipDiscounts: {
    input: PaginatedInput;
    output: PaginatedResponse<MembershipDiscountListItem[]>;
  };
  getMembershipDiscount: {
    input: { id: number };
    output: MembershipDiscount;
  };
  postMembershipDiscount: {
    input: MembershipDiscountCreateInput;
    output: MembershipDiscount;
  };
  postCreateBooking: {
    input: PostCreateBookingV2;
    output: AxiosResponse<{ id: number }>;
  };
  patchMembershipDiscount: {
    input: MembershipDiscountUpdateInput;
    output: MembershipDiscount;
  };
  deleteMembershipDiscount: {
    input: { id: number };
    output: undefined;
  };
  getMembershipProgram: {
    input: { id: number };
    output: MembershipProgram;
  };
  getPaginatedMembershipPrograms: {
    input: PaginatedInput;
    output: PaginatedResponse<MembershipProgramListItem[]>;
  };
  postMembershipProgram: {
    input: MembershipProgramCreateInput;
    output: MembershipProgram;
  };
  patchMembershipProgram: {
    input: MembershipProgramUpdateInput;
    output: MembershipProgram;
  };
  deleteMembershipProgram: {
    input: { id: number };
    output: undefined;
  };
  getCar: {
    input: { id: number };
    output: CarDetailType;
  };
  getCarWheelSetTypes: {
    input: undefined;
    output: CarWheelSetType[];
  };
  getCarWheelPositions: {
    input: undefined;
    output: CarWheelPosition[];
  };
  getAvailableBookingTimeWindowsByDateNew: {
    input: AvailableBookingTimeWindowsInput;
    output: AvailableBookingTimeWindowsByDateNew[];
  };
  getAvailableBookingTimeWindowsByDateNewV2: {
    input: AvailableBookingTimeWindowsInputV2;
    output: AvailableBookingTimeWindowsByDateNewV2[];
  };
  postMakeGroupAdmin: {
    input: GroupAdminType;
    output: { message: string };
  };
  postRemoveUserFromGroup: {
    input: GroupAdminType;
    output: { message: string };
  };
  getLatestCreatedBookingTimeWindowDate: {
    input: undefined;
    output: string;
  };
  getLanguages: {
    input: undefined;
    output: Language[];
  };
  getCapacityDays: {
    input: { fromDate?: string; toDate?: string };
    output: CapacityDay[];
  };
  getLatestCapacityDay: {
    input: undefined;
    output: CapacityDay;
  };
  postCapacityContribution: {
    input: CreateCapacityContributionDto;
    output: undefined;
  };
  patchCapacityContribution: {
    input: UpdateCapacityContributionDto;
    output: undefined;
  };
  getCapacityContribution: {
    input: { id: number };
    output: CapacityContributionDetails;
  };
  getBookingsForCar: {
    input: { carId: number; page: number; pageSize: number; search: string };
    output: PaginatedResponse<BookingListItemWithSalesItems[]>;
  };
  getNumBookings: {
    input: { userGroupIds?: number[]; fromDate?: string; toDate?: string };
    output: number;
  };
  getBookingsCosts: {
    input: { userGroupIds?: number[]; fromDate?: string; toDate?: string; vatIncluded: boolean };
    output: number;
  };
  getBookingStatuses: {
    input: undefined;
    output: BookingStatusDto[];
  };
  getPaginatedUserGroupCarsForBooking: {
    input: PaginatedInput & { id: number | string | undefined | null };
    output: PaginatedResponse<CarListItem[]>;
  };
  downloadBookingReceipt: {
    input: { bookingId: number };
    output: BookingReceipt;
  };
  getPaginatedInvoices: {
    input: PaginatedInput & {
      userGroupId?: number;
      fromDate: Nullable<string>;
      toDate: Nullable<string>;
    };
    output: PaginatedResponse<InvoiceListItem[]>;
  };
  getInvoice: {
    input: { id: number };
    output: Invoice;
  };
  deleteInvoice: {
    input: { id: number };
    output: undefined;
  };
  postInvoiceToExcel: {
    input: { id: number };
    output: ArrayBufferLike;
  };
  postInvoice: {
    input: InvoiceCreateInput;
    output: Invoice;
  };
  patchInvoice: {
    input: InvoiceUpdateInput;
    output: Invoice;
  };
  getPaginatedCarMakes: {
    input: PaginatedInput;
    output: PaginatedResponse<CarMake[]>;
  };
  getCarMake: {
    input: { id: number };
    output: CarMake;
  };
  postCarMake: {
    input: CarMakeCreateInput;
    output: CarMake;
  };
  patchCarMake: {
    input: CarMakeUpdateInput;
    output: CarMake;
  };
  getPaginatedCarModels: {
    input: PaginatedInput;
    output: PaginatedResponse<CarModel[]>;
  };
  getPaginatedCarModelsForMake: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarModel[]>;
  };
  getCarModel: {
    input: { id: number };
    output: CarModel;
  };
  postCarModel: {
    input: CarModelCreateInput;
    output: CarModel;
  };
  patchCarModel: {
    input: CarModelUpdateInput;
    output: CarModel;
  };
  getCarGeneration: {
    input: { id: number };
    output: CarGeneration;
  };
  postCarGeneration: {
    input: CarGenerationCreateInput;
    output: CarGeneration;
  };
  patchCarGeneration: {
    input: CarGenerationUpdateInput;
    output: CarGeneration;
  };
  getCarGenerationSearchNameApiProviders: {
    input: undefined;
    output: OptionsTypeString[];
  };
  getCarWheelSetSpecifications: {
    input: { id: number };
    output: CarWheelSetSpecifications;
  };
  postCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsCreateInput;
    output: CarWheelSetSpecifications;
  };
  patchCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsUpdateInput;
    output: CarWheelSetSpecifications;
  };
  patchBulkCarWheelSetSpecifications: {
    input: CarWheelSetSpecificationsPatchBulkUpdateInput;
    output: unknown;
  };
  getPaginatedCarWheelSetSpecifications: {
    input: PaginatedInput & CarWheelSetSpecificationsListInput;
    output: PaginatedResponse<CarWheelSetSpecifications[]>;
  };
  getPaginatedCarWheelSetSpecificationsForGeneration: {
    input: PaginatedInput & { id: number };
    output: PaginatedResponse<CarWheelSetSpecifications[]>;
  };
  getPaginatedContactPersons: {
    input: PaginatedInput & ListContactPersonInput;
    output: PaginatedResponse<ContactPersonListItem[]>;
  };
  getContactPerson: {
    input: { id: number };
    output: ContactPerson;
  };
  postContactPerson: {
    input: ContactPersonCreateInput;
    output: ContactPerson;
  };
  patchContactPerson: {
    input: ContactPersonUpdateInput;
    output: ContactPerson;
  };
  getPaginatedAvailabilities: {
    input: ListAvailabilitiesInput & PaginatedInput;
    output: PaginatedResponse<Availability[]>;
  };
  getAvailability: {
    input: { id: number };
    output: Availability;
  };
  patchAvailability: {
    input: AvailabilityUpdateInput;
    output: Availability;
  };
  deleteAvailability: {
    input: { id: number };
    output: unknown;
  };
  getPaginatedContactPersonDepartmentAddresses: {
    input: ListContactPersonDepartmentAddressInput & PaginatedInput;
    output: PaginatedResponse<ContactPersonDepartmentAddress[]>;
  };
  postContactPersonDepartmentAddress: {
    input: ContactPersonDepartmentAddressCreateInput;
    output: ContactPersonDepartmentAddress;
  };
  postContactPersonAvailability: {
    input: ContactPersonAvailabilityCreateInput;
    output: ContactPersonAvailability;
  };
  getPaginatedSkills: {
    input: PaginatedInput;
    output: PaginatedResponse<SkillListItem[]>;
  };
  getSkill: {
    input: { id: number };
    output: Skill;
  };
  postSkill: {
    input: SkillPostInput;
    output: Skill;
  };
  patchSkill: {
    input: SkillPatchInput;
    output: Skill;
  };
  deleteSkill: {
    input: { id: number };
    output: unknown;
  };
  getStorageUnits: {
    input: StorageUnitInput;
    output: StorageUnit[];
  };
  getRouteLabels: {
    input: RouteLabelInput;
    output: RouteLabel[];
  };
  getCarWheelSetToPickUp: {
    input: CarWheelSetToPickUpInput;
    output: CarWheelSetToPickUp[];
  };
  getEditBookingPermissions: {
    input: { id: string };
    output: EditBookingPermissions;
  };
  getUpdateBookingTimeWindowData: {
    input: { id: string };
    output: EditBookingTimeWindowData;
  };
  getUserGroupBookings: {
    input: { userGroupId?: number | null };
    output: UserGroupBooking[];
  };
  getUserGroupNumberOfBookings: {
    input: { userGroupId: number };
    output: number;
  };
  postDeleteCar: {
    input: { id: number };
    output: undefined;
  };
  getAvailableTireHotelCancellationOptions: {
    input: undefined;
    output: AvailableSalesItem[];
  };
  getCarTireQuoteBySlug: {
    input: { slug: string };
    output: TireQuote;
  };
  getCarTireQuoteById: {
    input: { id: string };
    output: TireQuoteById;
  };
  getCarWheelSetsDimensions: {
    input: undefined;
    output: string[];
  };
  postRejectTireOfferQuote: {
    input: { tireQuoteId: number; comments: string };
    output: undefined;
  };
  getAddressSuggestions: {
    input: { queryInput: string; countryCodes: string[] };
    output: AddressSuggestion[];
  };
  postAddressCreatedFromPlaceId: {
    input: { placeId?: string };
    output: AxiosResponse<AddressForNewBooking>;
  };
  getMe: {
    input: undefined;
    output: UserData;
  };
  getServiceWorkerContractAddresses: {
    input: { serviceWorkerContractId: number };
    output: AddressOutput[];
  };
}
