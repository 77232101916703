import { Path, Svg, SvgProps } from 'react-native-svg';

const RightArrowAlt = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M12.8 8.533 19.2 16l-6.4 7.467'
    />
  </Svg>
);
export { RightArrowAlt };
