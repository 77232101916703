import { t, Trans } from '@lingui/macro';
import { NoddiBasicCard, NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';
import routes from '../../appRoutes';
import TireSeasonChange from './TireSeasonChange';
import TireStation from './TireStation';
import TireStationWithWorker from './TireStationWithWorker';

const descriptionType = {
  pickTire: 'pickTire',
  orderTire: 'orderTire',
  installTire: 'installTire'
} as const;

type DescriptionType = (typeof descriptionType)[keyof typeof descriptionType];

interface TireOfferExplanationSectionProps {
  description: string;
  type: DescriptionType;
  index: number;
}

function Illustration({ type }: { type: DescriptionType }) {
  switch (type) {
    case 'pickTire':
      return <TireSeasonChange />;
    case 'orderTire':
      return <TireStation />;

    default:
      return <TireStationWithWorker />;
  }
}

const TireOfferExplanationSection = ({ description, index, type }: TireOfferExplanationSectionProps) => {
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex h-25 min-h-[140px] items-end gap-10 lg:gap-6'>
        <div className='mb-3 flex size-10 items-center justify-center rounded-full bg-primary-orange'>
          <p className='font-bold text-5 text-primary-white'>{index}</p>
        </div>
        <div>
          <Illustration type={type} />
        </div>
        <div />
      </div>
      <p className='text-primary-darkPurple'>{description}</p>
    </div>
  );
};

const TireOfferExplanation = ({ slug }: { slug?: string }) => {
  const navigate = useNavigate();
  const explanationData = [
    {
      type: descriptionType.pickTire,
      description: t`Choose tires from the offer we have prepared based on the tire dimensions your car is registered with at the Norwegian Public Roads Administration.`
    },
    {
      type: descriptionType.orderTire,
      description: t`Once you approve the offer, we will order the tires from our central warehouse and arrange a time for installation at your home.`
    },
    {
      type: descriptionType.installTire,
      description: t`At the agreed time, one of our technicians will come to your home and install the new tires quickly and safely.`
    }
  ];
  return (
    <NoddiBasicCard className='flex flex-col gap-6 p-6 lg:grid lg:grid-cols-3'>
      {explanationData.map((data, index) => (
        <TireOfferExplanationSection
          key={data.type}
          description={data.description}
          index={index + 1}
          type={data.type}
        />
      ))}
      {slug && (
        <div className='flex self-end'>
          <NoddiButton onClick={() => navigate(routes.tireOfferSelection.getPath({ slug }))} endIcon='ArrowRight'>
            <Trans>See offer for new tires</Trans>
          </NoddiButton>
        </div>
      )}
    </NoddiBasicCard>
  );
};

export default TireOfferExplanation;
