import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const Wheel = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Circle cx='15' cy='15' r='10' stroke={color} strokeWidth='1.5' />
    <Circle cx='15' cy='15' r='6' stroke={color} strokeWidth='1.5' />
    <Circle cx='15' cy='15' r='2' stroke={color} strokeWidth='1.5' />
    <Path d='M9 15L13 15' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <Path d='M17 15L21 15' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <Path d='M12 20.1963L14 16.7322' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <Path d='M16 13.2681L18 9.80396' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <Path d='M18 20.1963L16 16.7322' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <Path d='M14 13.2681L12 9.80396' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
  </Svg>
);

export { Wheel };
