import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const Warning = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      d='M6 13.4167C6 10.2191 6 8.62028 6.37752 8.08241C6.75503 7.54454 8.25832 7.02996 11.2649 6.00079L11.8377 5.80472C13.405 5.26824 14.1886 5 15 5C15.8114 5 16.595 5.26824 18.1623 5.80472L18.7351 6.00079C21.7417 7.02996 23.245 7.54454 23.6225 8.08241C24 8.62028 24 10.2191 24 13.4167C24 13.8996 24 14.4234 24 14.9914C24 20.6294 19.761 23.3655 17.1014 24.5273C16.38 24.8424 16.0193 25 15 25C13.9807 25 13.62 24.8424 12.8986 24.5273C10.239 23.3655 6 20.6294 6 14.9914C6 14.4234 6 13.8996 6 13.4167Z'
      stroke={color}
      strokeWidth='1.5'
    />
    <Path d='M15 11V15' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <Circle cx='15' cy='18' r='1' fill={color} />
  </Svg>
);

export { Warning };
