import { Trans, t } from '@lingui/macro';
import { useAuthContext } from 'noddi-provider';
import { FixedBottomBarContainer, LoadingScreen, NoddiBasicCard, NoddiIcon, getLongAddressName } from 'noddi-ui';

import { URLKeys, noddiAsync } from 'noddi-async';
import HelpScoutChat from '../../../../components/BookingFlow/HelpScoutChat';
import { CustomerSMSLogin } from '../../../../components/CustomerSMSLogin';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { Error404PageWithTranslations } from '../../../Error404';
import {
  useBookingActions,
  useBookingAddress,
  useBookingComment,
  useBookingSelectedSalesItemsCars,
  useBookingTimeWindow
} from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { PriceSummary } from '../../components/PriceSummary';
import { getBookingTimeWindowDisplayText } from '../../helpers/getTimeWindowDisplayText';
import { groupCars } from '../../helpers/utils';
import CommentBox from './CommentBox';
import { ConfirmBooking } from './ConfirmBooking';

export const Summary = () => {
  const { isLoggedIn } = useAuthContext();

  const selectedAddress = useBookingAddress();
  const comment = useBookingComment();
  // TODO | New booking I guess we want to debounce this value, as now confirmBooking.tsx which subscribes is rerendered on every key stroke
  const { setComment } = useBookingActions();

  const { currentUserGroupId: userGroupId } = useAuthContext();
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const bookingTimeWindow = useBookingTimeWindow();

  const {
    data: shoppingCart,
    isLoading: isShoppingCartPending,
    error: shoppingCartError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.postGetShoppingCartForNewBooking,
    input: {
      addressId: selectedAddress?.id,
      cars: groupCars(selectedSalesItemsCars),
      bookingTimeWindowId: bookingTimeWindow?.id,
      userGroupId
    },
    queryConfig: {
      staleTime: Infinity,
      enabled: !!selectedAddress && !!selectedSalesItemsCars.length
    }
  });

  if (shoppingCartError) {
    return <ErrorPageWithTranslations apiError={shoppingCartError} />;
  }

  if (isShoppingCartPending) {
    return <LoadingScreen />;
  }

  // TODO | New booking flow, Because we cleanup store on summary page, the navigateOnInvalidStep doesn't work without on this page
  // Cannot use isPending as it will just load forever if u dont have address or selected Items, so doing this instead.
  if (!shoppingCart?.data) {
    return <Error404PageWithTranslations />;
  }

  const timeWindowDisplay = bookingTimeWindow
    ? getBookingTimeWindowDisplayText(bookingTimeWindow)
    : t`Problems with the last time slot`;

  return (
    <BookingScreen
      title={t`Does this look correct?`}
      customBottomBar={
        <FixedBottomBarContainer>
          <HelpScoutChat />
          <ConfirmBooking />
        </FixedBottomBarContainer>
      }
    >
      <div className='flex flex-col gap-4'>
        {isLoggedIn ? <CommentBox comment={comment} handleChange={setComment} /> : <CustomerSMSLogin />}

        <NoddiBasicCard className='flex flex-col gap-3'>
          {selectedAddress && (
            <div className='flex gap-2'>
              <NoddiIcon name='LocationPin' />
              <p>{getLongAddressName(selectedAddress)}</p>
            </div>
          )}
          <div className='flex gap-2'>
            <NoddiIcon name='Calendar' />
            <p>{timeWindowDisplay}</p>
          </div>
          <div className='flex gap-2'>
            <NoddiIcon name='Phone' />
            <p>
              <Trans>We'll text you the day before with estimated arrival time and when you're next on the list</Trans>
            </p>
          </div>
        </NoddiBasicCard>

        <NoddiBasicCard>
          <PriceSummary shoppingCart={shoppingCart.data} />
        </NoddiBasicCard>
      </div>
    </BookingScreen>
  );
};
