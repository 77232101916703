import { Trans } from '@lingui/macro';
import { ShoppingCart, ShoppingCartCar } from 'noddi-async/src/types';
import { getCarDisplayName } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useBookingCars } from '../BookingStore';
import { compareLicensePlates } from '../helpers/utils';

type PriceSummaryProps = {
  shoppingCart: ShoppingCart;
};

export const PriceSummary = ({ shoppingCart }: PriceSummaryProps) => {
  const { cars, couponDiscounts, membershipDiscounts, totalPrice, deliveryFee } = shoppingCart;
  return (
    <div className='flex flex-col gap-6'>
      {cars.map((car) => (
        <ShoppingCartCars key={car.licensePlate.number} car={car} />
      ))}
      {!!couponDiscounts.length && (
        <div className='flex flex-col gap-1 border-t pt-6'>
          <h6 className='font-semibold'>
            <Trans>Coupons</Trans>
          </h6>
          {couponDiscounts.map((coupon) => (
            <div key={coupon.couponId} className='flex justify-between gap-4'>
              <span>{coupon.name}</span>
              <span>{formatCurrencyAmount(coupon.discount.amount, 0, coupon.discount.currency)}</span>
            </div>
          ))}
        </div>
      )}
      {!!membershipDiscounts.length && (
        <div className='flex flex-col gap-1'>
          <h6 className='font-semibold'>
            <Trans>Membership benefits</Trans>
          </h6>
          {membershipDiscounts.map((discount, idx) => (
            // Need to use combination here as the membershipDiscountId is not unique
            <div key={`${discount.membershipDiscountId - idx}`} className='flex justify-between gap-4'>
              <span>{discount.name}</span>
              <span>{formatCurrencyAmount(discount.discount.amount, 0, discount.discount.currency)}</span>
            </div>
          ))}
        </div>
      )}
      <div className='flex flex-col gap-1 border-t pt-6'>
        {!!deliveryFee && (
          <div className='flex justify-between'>
            <span>
              <Trans>Delivery</Trans>
            </span>
            <span>{formatCurrencyAmount(deliveryFee.amount, 0, deliveryFee.currency)}</span>
          </div>
        )}
        <div className='flex justify-between font-bold'>
          <span>
            <Trans>Total sum including VAT</Trans>
          </span>
          <span>{formatCurrencyAmount(totalPrice.amount, 0, totalPrice.currency)}</span>
        </div>
      </div>
    </div>
  );
};

const ShoppingCartCars = ({ car }: { car: ShoppingCartCar }) => {
  const selectedCars = useBookingCars();

  const selectedCar = selectedCars.find((selectedCar) =>
    compareLicensePlates(selectedCar.licensePlate, car.licensePlate)
  );

  if (!selectedCar) {
    return null;
  }
  return (
    <div className='flex flex-col gap-2'>
      <h1 className='font-semibold'>{getCarDisplayName(selectedCar)}</h1>

      <div className='flex flex-col gap-2'>
        {car.salesItems.map(({ name, price }) => (
          <div key={`${car.licensePlate.number} - ${name}`} className='flex justify-between gap-4'>
            <span>{name}</span>
            <span>{formatCurrencyAmount(price.amount, 0, price.currency)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
