import { cva, type VariantProps } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

import { ActivityIndicator, Pressable, PressableProps, Text, View } from 'react-native';
import { colors } from '../../tailwind-design-preset';
import { NoddiIcon } from '../atoms';
import { IconName } from '../types/icons';
import { cn } from '../utils';

// Don't add hover as effect persists on mobile after pressed, which makes the button look disabled. ( Can do for over md > )
export const buttonVariants = cva(
  'inline-flex size-fit min-w-fit items-center justify-center gap-2 whitespace-nowrap text-nowrap rounded-lg ring-offset-primary-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-systemColors-grey focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'border border-primary-darkPurple bg-primary-darkPurple active:bg-primary-purple',
        secondary: 'border border-primary-darkPurple bg-primary-white active:bg-systemColors-snow',
        ghost: 'active:bg-primary-white',
        success: 'border border-signal-success bg-signal-success active:bg-signal-success/80',
        destructive: 'border border-signal-danger bg-signal-danger active:bg-signal-danger/80',
        link: 'gap-1',
        lightLink: 'gap-1'
      },
      size: {
        default: 'px-4 py-2',
        small: 'px-3 py-1'
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default'
    }
  }
);

export const textVariants = cva('', {
  variants: {
    variant: {
      primary: 'text-text-secondary',
      secondary: 'text-primary-darkPurple',
      ghost: 'text-text-primary active:text-text-primaryInverted',
      success: 'text-text-secondary',
      destructive: 'text-text-secondary',
      link: 'text-primary-purple underline-offset-4 hover:underline',
      lightLink: 'text-text-secondary underline-offset-4 hover:underline active:text-text-secondary/60'
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

export const loadingVariants = cva('', {
  variants: {
    variant: {
      primary: colors.primary.white,
      secondary: colors.primary.darkPurple,
      ghost: colors.primary.darkPurple,
      success: colors.primary.white,
      destructive: colors.primary.white,
      link: colors.primary.darkPurple,
      lightLink: colors.primary.darkPurple
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

export const iconColorVariantsInNoddiButton = cva('', {
  variants: {
    variant: {
      primary: colors.text.secondary,
      secondary: colors.primary.darkPurple,
      ghost: colors.primary.black,
      success: colors.text.secondary,
      destructive: colors.text.secondary,
      link: colors.primary.purple,
      lightLink: colors.text.secondary
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});
export type ButtonProps = PressableProps &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
  } & ({ startIcon?: IconName; endIcon?: never } | { endIcon?: IconName; startIcon?: never });

export const NoddiButton = ({
  className,
  variant,
  size,
  loading,
  children,
  startIcon,
  endIcon,
  onPress,
  disabled,
  ...props
}: PropsWithChildren<ButtonProps>) => {
  const iconSize = size === 'small' ? 'small' : 'medium';
  const buttonClassName = cn(buttonVariants({ variant, size }), className);
  const textClassName = cn(textVariants({ variant }));
  const iconColor = iconColorVariantsInNoddiButton({ variant });
  const loadingColor = loadingVariants({ variant });

  return (
    <Pressable className={buttonClassName} accessibilityRole='button' disabled={disabled} onPress={onPress} {...props}>
      {loading ? (
        <View className='flex items-center justify-center' accessible>
          <ActivityIndicator size='small' color={loadingColor} />
        </View>
      ) : (
        <View className='flex flex-row items-center gap-2'>
          {startIcon && (
            <View>
              <NoddiIcon color={iconColor} name={startIcon} size={iconSize} />
            </View>
          )}
          <Text className={textClassName}>{children}</Text>
          {endIcon && (
            <View>
              <NoddiIcon color={iconColor} name={endIcon} size={iconSize} />
            </View>
          )}
        </View>
      )}
    </Pressable>
  );
};
