import { Path, Svg, SvgProps } from 'react-native-svg';

const UserCircleFilled = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      fill={color}
      fillRule='evenodd'
      d='M25 15c0 5.523-4.477 10-10 10S5 20.523 5 15 9.477 5 15 5s10 4.477 10 10Zm-7-3a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-3 11.5a8.46 8.46 0 0 0 4.807-1.489c.604-.415.862-1.205.51-1.848C19.59 18.83 18.09 18 15 18c-3.09 0-4.59.83-5.318 2.163-.351.643-.093 1.433.511 1.848A8.46 8.46 0 0 0 15 23.5Z'
      clipRule='evenodd'
    />
  </Svg>
);
export { UserCircleFilled };
