import { Path, Svg, SvgProps } from 'react-native-svg';

const File = ({ color, ...rest }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...rest}>
    <Path
      fill={color}
      d='m18.393 7.054-.502.557.502-.557Zm3.959 3.563-.502.557.502-.557Zm2.302 2.537-.685.305.685-.305ZM6.172 23.828l.53-.53-.53.53Zm17.656 0-.53-.53.53.53ZM17 24.25h-4v1.5h4v-1.5ZM5.75 17v-4h-1.5v4h1.5Zm18.5-.437V17h1.5v-.437h-1.5ZM17.891 7.61l3.959 3.563 1.003-1.115-3.958-3.563-1.004 1.115Zm7.859 8.952c0-1.688.015-2.757-.41-3.714l-1.371.61c.266.598.281 1.283.281 3.104h1.5Zm-3.9-5.389c1.353 1.218 1.853 1.688 2.119 2.285l1.37-.61c-.426-.957-1.23-1.66-2.486-2.79l-1.003 1.115ZM13.03 5.75c1.582 0 2.179.012 2.71.216l.538-1.4c-.852-.328-1.78-.316-3.248-.316v1.5Zm5.865.746c-1.086-.977-1.765-1.604-2.617-1.93l-.537 1.4c.532.204.98.592 2.15 1.645l1.004-1.115ZM13 24.25c-1.907 0-3.261-.002-4.29-.14-1.005-.135-1.585-.389-2.008-.812l-1.06 1.06c.748.75 1.697 1.081 2.869 1.239 1.15.155 2.625.153 4.489.153v-1.5ZM4.25 17c0 1.864-.002 3.338.153 4.489.158 1.172.49 2.121 1.238 2.87l1.06-1.06c-.422-.424-.676-1.004-.811-2.01-.138-1.027-.14-2.382-.14-4.289h-1.5ZM17 25.75c1.864 0 3.338.002 4.489-.153 1.172-.158 2.121-.49 2.87-1.238l-1.06-1.06c-.424.422-1.004.676-2.01.811-1.027.138-2.382.14-4.289.14v1.5ZM24.25 17c0 1.907-.002 3.262-.14 4.29-.135 1.005-.389 1.585-.812 2.008l1.06 1.06c.75-.748 1.081-1.697 1.239-2.869.155-1.15.153-2.625.153-4.489h-1.5Zm-18.5-4c0-1.907.002-3.261.14-4.29.135-1.005.389-1.585.812-2.008l-1.06-1.06c-.75.748-1.081 1.697-1.239 2.869-.155 1.15-.153 2.625-.153 4.489h1.5Zm7.28-8.75c-1.875 0-3.356-.002-4.511.153-1.177.158-2.129.49-2.878 1.238l1.06 1.06c.424-.422 1.005-.676 2.017-.811 1.033-.138 2.395-.14 4.312-.14v-1.5Z'
    />
    <Path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='M9 17.5h8M9 21h5.5' />
    <Path stroke={color} strokeWidth={1.5} d='M16 5.5V8c0 2.357 0 3.536.732 4.268C17.465 13 18.643 13 21 13h4' />
  </Svg>
);
export { File };
