import { t } from '@lingui/macro';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { TireHotelContent } from './TireHotel';

export const TireHotel = () => {
  return (
    <ContentWrapper title={t`Tire hotel`}>
      <TireHotelContent />
    </ContentWrapper>
  );
};
