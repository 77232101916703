import { t } from '@lingui/macro';
import { NoddiCollapseCard } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';

import { SALES_ITEM_TYPE, SalesItemsCar } from 'noddi-async/src/types';
import { SalesItem } from './SalesItem';
import { ServiceCategoryHeader } from './ServiceCategoryHeader';

type ServiceCategoryCollapseCardProps = {
  numberOfSelectedSalesItems: number;
  numberOfCategories: number;
  salesItems: SalesItemsCar['salesItems'];
  licensePlate: SalesItemsCar['licensePlate'];
  category: string;
  salesItemType: SALES_ITEM_TYPE;
};

export const ServiceCategoryCollapseCard = ({
  numberOfSelectedSalesItems,
  numberOfCategories,
  salesItemType,
  category,
  salesItems,
  licensePlate
}: ServiceCategoryCollapseCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // I guess we dont want the groups to say from 0 kr?
  const priceFrom = salesItems
    .filter((item) => item.price.amount > 0)
    .reduce((acc, item) => Math.min(acc, item.price.amount), Infinity);

  const currency = salesItems[0]?.price.currency;

  return (
    <NoddiCollapseCard
      sx={{ padding: 0, borderRadius: '16px' }}
      preventHandleExpand
      expandedChanged={(expanded) => setIsExpanded(expanded)}
      mountAsExpanded={salesItemType === SALES_ITEM_TYPE.ADDON}
      hideChevron
      header={
        <ServiceCategoryHeader
          numberOfSelectedSalesItems={numberOfSelectedSalesItems}
          numberOfCategories={numberOfCategories}
          priceFrom={`${t`from`} ${formatCurrencyAmount(priceFrom, 0, currency)}`}
          category={category}
          isExpanded={isExpanded}
          salesItemType={salesItemType}
        />
      }
      collapseBody={
        <div className='mx-3 mb-3 flex flex-col gap-1'>
          {salesItems.map((item) => (
            <div
              className='pb-1 [&:not(:last-child)]:border-b-[1.5px] [&:not(:last-child)]:border-primary-purple'
              key={item.name}
            >
              <SalesItem
                className='px-4 py-3 !shadow-none sm:px-6 sm:py-5'
                type={salesItemType}
                licensePlate={licensePlate}
                key={item.name}
                salesItem={item}
              />
            </div>
          ))}
        </div>
      }
    />
  );
};
