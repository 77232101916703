import { Path, Svg, SvgProps } from 'react-native-svg';

const AltArrowUp = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      d='M23.4667 19.2L16 12.8L8.53333 19.2'
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export { AltArrowUp };
