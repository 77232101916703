import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { RegNumberSearchV2 } from 'noddi-ui';
import { useState } from 'react';
import getCommonTranslations from '../../../../commonTranslations';
import { getErrorCodes } from '../../../../utils/translation';
import { useBookingActions, useBookingAddress, useBookingCars } from '../../BookingStore';
import { getLicensePlateCountryOptions } from '../../helpers/licensePlateCountryOptions';
import { compareLicensePlates } from '../../helpers/utils';
import { LicensePlateCountrySelector } from './LicensePlateCountrySelector';
import { SelectCarInBookingCard } from './SelectCarInBookingCard';

type AddCarsProps = {
  savedCars?: CarSpec[];
};

export const SelectCars = ({ savedCars = [] }: AddCarsProps) => {
  const selectedCars = useBookingCars();
  const selectedAddress = useBookingAddress();
  const { addCar, removeCar } = useBookingActions();

  const defaultCountryCodeOption = getLicensePlateCountryOptions().find(
    (option) => option.value === selectedAddress?.countryCode
  );

  const [licensePlateCountry, setLicensePlateCountry] = useState(defaultCountryCodeOption);

  const carsFromSearch = selectedCars.filter(
    (selectedCar) =>
      !savedCars.some((savedCar) => compareLicensePlates(savedCar.licensePlate, selectedCar.licensePlate))
  );

  // TODO | New booking, this is old tracking code |
  // GoogleTrackingService.trackEvent({ eventType: GtmEvents.addToCart });

  const translations = getCommonTranslations().getRegNumberText(licensePlateCountry?.value);

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-col gap-2'>
        <RegNumberSearchV2
          addCar={addCar}
          errorCodes={getErrorCodes()}
          translations={translations}
          countryCode={licensePlateCountry?.value}
        />
        <LicensePlateCountrySelector
          setLicensePlateCountry={setLicensePlateCountry}
          licensePlateCountry={licensePlateCountry}
        />
      </div>
      <div className='flex flex-col gap-4'>
        {carsFromSearch.map((carFromSearch) => (
          <SelectCarInBookingCard
            key={carFromSearch.licensePlate.number}
            description={carFromSearch.licensePlate.number}
            title={`${carFromSearch.make} - ${carFromSearch.model}`}
            isSelected
            action={() => removeCar(carFromSearch)}
          />
        ))}
        {savedCars.map((car) => {
          const isSelected = selectedCars.some((selectedCar) =>
            compareLicensePlates(selectedCar.licensePlate, car.licensePlate)
          );
          return (
            <SelectCarInBookingCard
              title={`${car.make} - ${car.model}`}
              description={car.licensePlate.number}
              isSelected={isSelected}
              action={isSelected ? () => removeCar(car) : () => addCar(car)}
              key={car.licensePlate.number}
            />
          );
        })}
      </div>
    </div>
  );
};
