import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

export const QuestionMark = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Circle cx={15} cy={15} r={10} stroke={color} strokeWidth={1.5} />
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M13.125 11.875a1.875 1.875 0 1 1 2.828 1.615c-.475.281-.953.708-.953 1.26V16'
    />
    <Circle cx={15} cy={19} r={1} fill={color} />
  </Svg>
);
