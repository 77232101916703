import { Trans, t } from '@lingui/macro';
import { LicensePlateInfo, SALES_ITEM_TYPE, TierHotelPickupSalesItemOption } from 'noddi-async/src/types';
import {
  AddressSearch,
  NoddiBasicCard,
  NoddiDummyButton,
  NoddiFeedbackBox,
  NoddiIcon,
  NoddiIconButton,
  NoddiLinearProgressLoader,
  cn,
  colors,
  getLongAddressName
} from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';

import { URLKeys, noddiAsync } from 'noddi-async';
import getCommonTranslations from '../../../../commonTranslations';
import { useBookingActions, useBookingAddress, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { compareLicensePlates } from '../../helpers/utils';

type WheelStorageOptionProps = {
  tireHotelSalesItem: TierHotelPickupSalesItemOption;
  licensePlate: LicensePlateInfo;
};

export const TierHotelPickUpOption = ({ tireHotelSalesItem, licensePlate }: WheelStorageOptionProps) => {
  const {
    name,
    description,
    price,
    salesItemId,
    requiresPickupAddressSelection,
    mutuallyExclusiveSalesItemIds,
    isAddonsAvailable,
    isWheelPickupRequired
  } = tireHotelSalesItem;
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const selectedAddress = useBookingAddress();

  const selectedSalesItemsCar = selectedSalesItemsCars.find(
    (item) => compareLicensePlates(item.licensePlate, licensePlate) && item.salesItemId === salesItemId
  );

  const isSelected = !!selectedSalesItemsCar;

  const pickUpAddress = selectedSalesItemsCar?.metaData?.selectedTierHotelPickUpAddress;

  const { removeSalesItem, addSalesItem } = useBookingActions();

  const removeOption = () => {
    removeSalesItem({ licensePlate, salesItemId, type: SALES_ITEM_TYPE.TIRE_HOTEL_PICKUP });
  };

  const pickUpAtHomeToggle = () => {
    isSelected
      ? removeOption()
      : addSalesItem({
          licensePlate,
          salesItemId,
          type: SALES_ITEM_TYPE.TIRE_HOTEL_PICKUP,
          mutuallyExclusiveSalesItemIds,
          isAddonsAvailable,
          isWheelPickupRequired
        });
  };

  const {
    mutateAsync: createAddressFromPlaceId,
    reset: resetCreateAddressFromPlaceId,
    isPending,
    error: addressError,
    data: createdAddress
  } = noddiAsync.usePost({
    type: URLKeys.postAddressCreatedFromPlaceId,
    queryConfig: {
      onSuccess: ({ data: { isInDeliveryArea, ...rest } }) => {
        if (isInDeliveryArea) {
          addSalesItem({
            licensePlate,
            salesItemId,
            type: SALES_ITEM_TYPE.TIRE_HOTEL_PICKUP,
            mutuallyExclusiveSalesItemIds,
            metaData: { selectedTierHotelPickUpAddress: rest },
            isAddonsAvailable,
            isWheelPickupRequired
          });
        }
      }
    }
  });

  const isFree = price.amount === 0;
  const priceFormatted = isFree ? t`Free` : formatCurrencyAmount(price.amount, 0, price.currency);

  if (!requiresPickupAddressSelection) {
    return (
      <NoddiBasicCard
        onClick={pickUpAtHomeToggle}
        className={cn('flex flex-col gap-2', isSelected && 'bg-primary-purple text-primary-white')}
      >
        <span className='text-6'>{name}</span>
        <span>{description}</span>
        <div className='-ml-1 flex gap-1'>
          <NoddiIcon name='LocationPin' color={isSelected ? colors.primary.white : colors.primary.black} />
          {selectedAddress && <span>{getLongAddressName(selectedAddress)}</span>}
        </div>
        <div className='flex items-center justify-between'>
          <span className={cn('font-semibold', isFree && !isSelected && 'text-signal-success')}>{priceFormatted}</span>
          <NoddiDummyButton variant='secondary' startIcon={isSelected ? 'Minus' : 'Plus'} size='small'>
            {isSelected ? <Trans>Remove</Trans> : <Trans>Choose</Trans>}
          </NoddiDummyButton>
        </div>
      </NoddiBasicCard>
    );
  }

  const isSelectedAndHasAddress = isSelected && pickUpAddress?.id;

  return (
    <NoddiBasicCard
      className={cn('flex flex-col gap-2', isSelectedAndHasAddress && 'bg-primary-purple text-primary-white')}
    >
      <span className='text-6'>{name}</span>
      <span>{description}</span>

      <span className={cn('font-semibold', isFree && !isSelected && 'text-signal-success')}>{priceFormatted}</span>
      {pickUpAddress?.id ? (
        <NoddiBasicCard className='flex w-full items-center justify-between gap-3 rounded-md pl-2 text-primary-black'>
          <div className='flex items-center gap-2'>
            <NoddiIcon name='LocationPin' size='large' />
            <span>{getLongAddressName(pickUpAddress)}</span>
          </div>
          <NoddiIconButton
            iconName='Cross'
            iconSize='medium'
            variant='destructive'
            onClick={() => {
              resetCreateAddressFromPlaceId();
              removeOption();
            }}
          />
        </NoddiBasicCard>
      ) : (
        <>
          <AddressSearch
            translations={getCommonTranslations().addressSearch}
            onSelect={async (addressSuggestion) => {
              await createAddressFromPlaceId({ placeId: addressSuggestion.placeId });
            }}
          />
          {isPending && <NoddiLinearProgressLoader />}
          {addressError && (
            <NoddiFeedbackBox variant='error' className='mt-4' heading={getCommonTranslations().couldNotFindAddress} />
          )}
        </>
      )}
      {createdAddress && !createdAddress.data.isInDeliveryArea && (
        <NoddiFeedbackBox
          variant='error'
          className='mt-4 text-primary-black'
          heading={getCommonTranslations().weDoNotOfferTheseServicesAtThisAddress}
        />
      )}
    </NoddiBasicCard>
  );
};
