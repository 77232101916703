import {
  AvailableBookingTimeWindowV2,
  BOOKING_TYPES,
  BookingAddress,
  SALES_ITEM_TYPE,
  SelectedSalesItemsCar
} from 'noddi-async/src/types';
import { CarSpec } from 'noddi-async/src/types/customerapp/booking/shared';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { compareLicensePlates } from './helpers/utils';

interface BookingState {
  clearStore: () => void;

  bookingType: BOOKING_TYPES;
  setBookingType: (bookingType: BOOKING_TYPES) => void;

  selectedAddress?: BookingAddress;
  setSelectedAddress: (address?: BookingAddress) => void;

  selectedCars: CarSpec[];
  addCar: (carToAdd: CarSpec) => void;
  removeCar: (carToRemove: CarSpec) => void;

  // Here car + PrimaryItems or Addons or TierHotelPickUpItems are stored
  selectedSalesItemsCars: SelectedSalesItemsCar[];
  addSalesItem: (
    params: SelectedSalesItemsCar & {
      // SalesItems that are mutually exclusive is auto deselected when new item is added, ex; selecting car wash basic removes car wash premium
      mutuallyExclusiveSalesItemIds: number[];
    }
  ) => void;
  removeSalesItem: (params: {
    licensePlate: SelectedSalesItemsCar['licensePlate'];
    salesItemId: SelectedSalesItemsCar['salesItemId'];
    type: SelectedSalesItemsCar['type'];
  }) => void;

  selectedTimeWindow?: AvailableBookingTimeWindowV2;
  setSelectedTimeWindow: (timeWindow: AvailableBookingTimeWindowV2) => void;

  comment?: string;
  setComment: (comment: string) => void;
}

// Initial state with empty values
const initialStateValues = {
  bookingType: BOOKING_TYPES.NORMAL,
  selectedAddress: undefined,
  selectedCars: [],
  selectedSalesItemsCars: [],
  selectedTimeWindow: undefined,
  comment: ''
};

const useBookingStore = create<BookingState>()(
  persist(
    devtools((set) => ({
      ...initialStateValues,
      clearStore: () => set(initialStateValues),

      setBookingType: (bookingType) => set({ bookingType }),

      setSelectedAddress: (selectedAddress) =>
        set({
          selectedAddress,
          selectedSalesItemsCars: [],
          selectedTimeWindow: undefined
        }),

      addCar: (carToAdd) => {
        set((state) => {
          const selectedCars = state.selectedCars;
          const isAlreadySelected = selectedCars.some((selectedCar) =>
            compareLicensePlates(selectedCar.licensePlate, carToAdd.licensePlate)
          );

          if (isAlreadySelected) {
            return state;
          }

          return {
            selectedCars: [...selectedCars, carToAdd]
          };
        });
      },
      removeCar: (carToRemove) => {
        set((state) => ({
          selectedCars: state.selectedCars.filter(
            (selectedCar) => !compareLicensePlates(selectedCar.licensePlate, carToRemove.licensePlate)
          ),
          selectedSalesItemsCars: state.selectedSalesItemsCars.filter(
            (selectedSalesItemsCar) =>
              !compareLicensePlates(selectedSalesItemsCar.licensePlate, carToRemove.licensePlate)
          )
        }));
      },

      addSalesItem: (params) => {
        set((state) => {
          const { mutuallyExclusiveSalesItemIds, ...restParams } = params;

          const selectedSalesItemsCars = state.selectedSalesItemsCars;

          const isAlreadySelected = selectedSalesItemsCars.some(
            (item) =>
              compareLicensePlates(item.licensePlate, restParams.licensePlate) &&
              item.salesItemId === restParams.salesItemId
          );

          if (isAlreadySelected) {
            return state;
          }

          // Remove any mutually exclusive items for this car
          const filteredItems = selectedSalesItemsCars.filter(
            (item) =>
              !(
                compareLicensePlates(item.licensePlate, restParams.licensePlate) &&
                mutuallyExclusiveSalesItemIds.includes(item.salesItemId)
              )
          );

          return {
            selectedSalesItemsCars: [...filteredItems, restParams],
            selectedTimeWindow: undefined
          };
        });
      },
      removeSalesItem: ({ licensePlate, salesItemId, type }) => {
        set((state) => {
          if (type !== SALES_ITEM_TYPE.PRIMARY) {
            // For non-PRIMARY salesItems, only remove the specific item
            const salesItemsExcludingCurrent = state.selectedSalesItemsCars.filter(
              (item) => !(compareLicensePlates(item.licensePlate, licensePlate) && item.salesItemId === salesItemId)
            );

            return {
              selectedSalesItemsCars: salesItemsExcludingCurrent,
              selectedTimeWindow: undefined
            };
          }

          // If PRIMARY, remove all ADDON and TIER_HOTEL_PICKUP salesItems for this car as well
          const filteredItems = state.selectedSalesItemsCars.filter(
            (item) =>
              !compareLicensePlates(item.licensePlate, licensePlate) || // Keep items from other cars
              (compareLicensePlates(item.licensePlate, licensePlate) &&
                item.type === SALES_ITEM_TYPE.PRIMARY &&
                item.salesItemId !== salesItemId) // Only keep PRIMARY items on this car (except the one being removed)
          );

          return {
            selectedSalesItemsCars: filteredItems,
            selectedTimeWindow: undefined
          };
        });
      },

      setSelectedTimeWindow: (selectedTimeWindow) => set({ selectedTimeWindow }),
      setComment: (comment) => set({ comment })
    })),
    {
      name: 'booking',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

// Getters
export const useBookingType = () => useBookingStore((state) => state.bookingType);
export const useBookingCars = () => useBookingStore((state) => state.selectedCars);
export const useBookingAddress = () => useBookingStore((state) => state.selectedAddress);
export const useBookingSelectedSalesItemsCars = () => useBookingStore((state) => state.selectedSalesItemsCars);
export const useBookingTimeWindow = () => useBookingStore((state) => state.selectedTimeWindow);
export const useBookingComment = () => useBookingStore((state) => state.comment);

// Actions
export const useBookingActions = () => {
  const setBookingType = useBookingStore((state) => state.setBookingType);
  const setSelectedAddress = useBookingStore((state) => state.setSelectedAddress);
  const addCar = useBookingStore((state) => state.addCar);
  const removeCar = useBookingStore((state) => state.removeCar);
  const clearStore = useBookingStore((state) => state.clearStore);
  const addSalesItem = useBookingStore((state) => state.addSalesItem);
  const removeSalesItem = useBookingStore((state) => state.removeSalesItem);
  const setSelectedTimeWindow = useBookingStore((state) => state.setSelectedTimeWindow);
  const setComment = useBookingStore((state) => state.setComment);

  return {
    setBookingType,
    setSelectedAddress,
    addCar,
    removeCar,
    clearStore,
    addSalesItem,
    removeSalesItem,
    setSelectedTimeWindow,
    setComment
  };
};

//Helpers
export const useIsAddonsAvailable = () => {
  const selectionResultsCars = useBookingSelectedSalesItemsCars();
  return selectionResultsCars.map((car) => car.isAddonsAvailable).includes(true);
};

export const useIsTierHotelPickupRequired = () => {
  const selectionResultsCars = useBookingSelectedSalesItemsCars();
  return selectionResultsCars.map((car) => car.isWheelPickupRequired).includes(true);
};
