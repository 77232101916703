import { LicensePlateInfo, TierHotelPickupSalesItemOption } from 'noddi-async/src/types';

import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingCars } from '../../BookingStore';
import { CarIdentifierWithIcon } from '../../components/CarIdentifierWithIcon';
import { compareLicensePlates } from '../../helpers/utils';
import { TierHotelPickUpOption } from './TierHotelPickUpOption';

type Props = {
  licensePlate: LicensePlateInfo;
  pickUpOptions: TierHotelPickupSalesItemOption[];
};

export const CarWithTierPickUpOptions = ({ licensePlate, pickUpOptions }: Props) => {
  const cars = useBookingCars();

  const car = cars.find((car) => compareLicensePlates(car.licensePlate, licensePlate));
  if (!car) {
    return <ErrorPageWithTranslations />;
  }

  const sortedPickUpOptions = pickUpOptions.toSorted((a, b) => a.uiSortOrder - b.uiSortOrder);

  return (
    <div className='flex flex-col gap-6'>
      <CarIdentifierWithIcon car={car} />

      <div className='flex flex-col gap-4'>
        {sortedPickUpOptions.map((pickUpSalesItem) => {
          return (
            <TierHotelPickUpOption
              tireHotelSalesItem={pickUpSalesItem}
              licensePlate={licensePlate}
              key={pickUpSalesItem.salesItemId}
            />
          );
        })}
      </div>
    </div>
  );
};
