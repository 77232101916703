import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const Settings = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Circle cx={16} cy={16} r={3.2} stroke={color} strokeWidth={2} />
    <Path
      stroke={color}
      strokeWidth={2}
      d='M17.883 5.496c-.392-.163-.889-.163-1.883-.163s-1.491 0-1.883.163a2.133 2.133 0 0 0-1.155 1.154c-.098.239-.137.516-.152.921-.023.595-.328 1.146-.843 1.443-.516.298-1.145.287-1.671.009-.358-.19-.618-.295-.874-.329a2.133 2.133 0 0 0-1.577.423c-.337.258-.585.689-1.082 1.55-.497.86-.746 1.29-.801 1.712a2.133 2.133 0 0 0 .422 1.577c.157.205.378.377.721.592.504.317.829.857.829 1.452s-.325 1.135-.829 1.452c-.343.215-.564.387-.72.592a2.133 2.133 0 0 0-.423 1.577c.055.421.303.852.8 1.712.497.861.746 1.292 1.083 1.55a2.134 2.134 0 0 0 1.577.423c.256-.034.515-.14.873-.329.527-.278 1.156-.289 1.672.009.515.297.82.848.843 1.443.015.405.054.682.152.92.217.523.632.939 1.155 1.155.392.163.889.163 1.883.163s1.491 0 1.883-.163a2.133 2.133 0 0 0 1.155-1.154c.099-.239.137-.516.152-.921.022-.595.328-1.146.843-1.443.516-.298 1.145-.287 1.671-.009.358.19.618.295.874.328a2.134 2.134 0 0 0 1.577-.422c.337-.258.585-.689 1.082-1.55.498-.86.746-1.291.801-1.712a2.134 2.134 0 0 0-.422-1.577c-.157-.205-.378-.377-.721-.592-.504-.317-.829-.857-.829-1.452s.325-1.135.829-1.451c.343-.216.564-.388.72-.593a2.133 2.133 0 0 0 .424-1.577c-.056-.421-.304-.851-.801-1.712-.497-.861-.746-1.292-1.083-1.55a2.133 2.133 0 0 0-1.577-.422c-.256.033-.515.138-.873.328-.527.278-1.156.29-1.672-.009-.515-.297-.82-.848-.843-1.443-.015-.405-.054-.682-.152-.92a2.133 2.133 0 0 0-1.155-1.155Z'
    />
  </Svg>
);
export { Settings };
