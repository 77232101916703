import { t } from '@lingui/macro';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import { LicensePlateInfo, SelectedSalesItemsCar, SelectedSalesItemsCarGrouped } from 'noddi-async/src/types';

export const groupCars = (items: SelectedSalesItemsCar[]): SelectedSalesItemsCarGrouped[] => {
  // Create composite key for grouping
  const grouped = groupBy(items, (item) => `${item.licensePlate.number}_${item.licensePlate.countryCode}`);

  return map(grouped, (group) => ({
    licensePlate: {
      number: group[0]!.licensePlate.number,
      countryCode: group[0]!.licensePlate.countryCode
    },
    selectedSalesItemIds: group.map((item) => item.salesItemId)
  }));
};

export const compareLicensePlates = (licensePlate1: LicensePlateInfo, licensePlate2: LicensePlateInfo) => {
  return licensePlate1.number === licensePlate2.number && licensePlate1.countryCode === licensePlate2.countryCode;
};

enum BookingCategory {
  WHEEL_SERVICES = 'wheel_services',
  WASH_SERVICES = 'wash_services',
  PACKAGES = 'packages'
}

export const translateBookingCategories = (bookingCategoryFromApi: BookingCategory | string): string => {
  switch (bookingCategoryFromApi) {
    case BookingCategory.WHEEL_SERVICES:
      return t`Wheel services`;
    case BookingCategory.WASH_SERVICES:
      return t`Wash services`;
    case BookingCategory.PACKAGES:
      return t`Packages`;
    default:
      return `Other`;
  }
};

export const bookingCategoryLabelToEnum = (serviceFromApi: BookingCategory | string): BOOKING_CATEGORY_ENUM => {
  switch (serviceFromApi) {
    case BookingCategory.WHEEL_SERVICES:
      return BOOKING_CATEGORY_ENUM.WHEEL_SERVICES;
    case BookingCategory.WASH_SERVICES:
      return BOOKING_CATEGORY_ENUM.WASHING_SERVICES;
    case BookingCategory.PACKAGES:
      return BOOKING_CATEGORY_ENUM.PACKAGES;
    default:
      return BOOKING_CATEGORY_ENUM.PACKAGES;
  }
};

export enum BOOKING_CATEGORY_ENUM {
  WHEEL_SERVICES,
  WASHING_SERVICES,
  PACKAGES
}
