import { t, Trans } from '@lingui/macro';

import { noddiAsync, URLKeys } from 'noddi-async';
import { BookingAddress } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import {
  AddressSearch,
  ErrorPage,
  getLongAddressName,
  LoadingScreen,
  NoddiBasicCard,
  NoddiDummyButton,
  NoddiFeedbackBox,
  NoddiIcon,
  NoddiIconButton,
  NoddiLinearProgressLoader
} from 'noddi-ui';
import getCommonTranslations from '../../../../commonTranslations';
import { ApiErrorMessageWithTrans } from '../../../../components/ApiErrorMessageWithTrans';
import { NoServiceOffered } from './NoServiceOffered';

type AddressPickerProps = {
  setSelectedAddress: (address?: BookingAddress) => void;
  selectedAddress: BookingAddress | undefined;
  onSavedAddressClick?: () => void;
};

export const AddressPicker = ({ setSelectedAddress, selectedAddress, onSavedAddressClick }: AddressPickerProps) => {
  const { currentUserGroupId: userGroupId } = useAuthContext();

  const {
    data: savedAddresses,
    isLoading,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupAddressesValidForNewBooking,
    input: { id: userGroupId! },
    queryConfig: { enabled: !!userGroupId, staleTime: Infinity }
  });

  const {
    mutateAsync: createAddressFromPlaceId,
    reset: resetCreateAddressFromPlaceId,
    isPending,
    error: addressError,
    data: createdAddress
  } = noddiAsync.usePost({
    type: URLKeys.postAddressCreatedFromPlaceId,
    queryConfig: {
      onSuccess: ({ data: { isInDeliveryArea, ...rest } }) => {
        if (isInDeliveryArea) {
          setSelectedAddress(rest);
        }
      }
    }
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorPage />;
  }

  const displayAddress = selectedAddress ?? createdAddress?.data;

  const availableSavedAddresses = savedAddresses?.filter((savedAddress) => savedAddress.id !== displayAddress?.id);
  return (
    <>
      {displayAddress ? (
        <div className='flex flex-col gap-2'>
          <NoddiBasicCard className='flex items-center justify-between gap-3 rounded-md pl-2'>
            <div className='flex items-center gap-2'>
              <NoddiIcon name='LocationPin' size='large' />
              <span>{getLongAddressName(displayAddress)}</span>
            </div>
            <NoddiIconButton
              iconName='Cross'
              iconSize='medium'
              variant='destructive'
              onClick={() => {
                resetCreateAddressFromPlaceId();
                setSelectedAddress(undefined);
              }}
            />
          </NoddiBasicCard>
          <NoddiFeedbackBox variant='success' heading={t`We deliver to your address!`} />
        </div>
      ) : (
        <>
          <AddressSearch
            translations={getCommonTranslations().addressSearch}
            onSelect={async (addressSuggestion) => {
              await createAddressFromPlaceId({ placeId: addressSuggestion.placeId });
            }}
          />
          {isPending && <NoddiLinearProgressLoader />}
          {addressError && <ApiErrorMessageWithTrans error={addressError} />}
        </>
      )}
      {createdAddress && !createdAddress.data.isInDeliveryArea && (
        <NoServiceOffered
          translations={{
            noServiceOfferedNotice: getCommonTranslations().noServiceOfferedNotice
          }}
          address={createdAddress.data}
        />
      )}
      {!!availableSavedAddresses?.length && (
        <div className='mt-8 flex flex-col gap-2'>
          <span className='font-semibold'>
            <Trans>Saved addresses</Trans>
          </span>

          <div className='flex flex-col gap-4'>
            {availableSavedAddresses.map((savedAddress) => (
              <NoddiBasicCard
                onClick={() => {
                  setSelectedAddress(savedAddress);
                  onSavedAddressClick?.();
                }}
                key={savedAddress.id}
                className='flex items-center justify-between gap-4'
              >
                <span>{getLongAddressName(savedAddress)}</span>
                <NoddiDummyButton endIcon='ArrowRight' variant='secondary' size='small'>
                  <Trans>Choose</Trans>
                </NoddiDummyButton>
              </NoddiBasicCard>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
