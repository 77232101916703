import { t } from '@lingui/macro';
import { NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import { useBookingActions } from '../../pages/BookingFlow/BookingStore';

export function useCleanUpAndNavigateToNewBooking() {
  const { clearStore } = useBookingActions();
  const navigate = useNavigate();

  function cleanUpAndNavigateToNewBooking() {
    clearStore();
    navigate(routes.newBookingAddress.getPath());
  }

  return { cleanUpAndNavigateToNewBooking };
}

type NewBookingButtonProps = {
  className?: string;
  defaultSize?: boolean;
  loading?: boolean;
};
const NewBookingButton = ({ className, loading, defaultSize }: NewBookingButtonProps) => {
  const { cleanUpAndNavigateToNewBooking } = useCleanUpAndNavigateToNewBooking();
  return (
    <NoddiButton
      className={className}
      size={defaultSize ? 'default' : 'small'}
      endIcon='ArrowRight'
      onClick={cleanUpAndNavigateToNewBooking}
      loading={loading}
    >{t`New booking`}</NoddiButton>
  );
};

export default NewBookingButton;
