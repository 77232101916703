import { AxiosResponse } from 'axios';

import {
  AddressProps,
  AvailableBookingTimeWindow,
  CarListItem,
  Department,
  DepartmentAddress,
  DepartmentAddressCreateInput,
  DepartmentCreateInput,
  DepartmentListItem,
  DepartmentUpdateInput,
  GetOrganizationCarsInput,
  OrganizationBookingItem,
  OrganizationBookingItems,
  OrganizationCar,
  PaginatedInput,
  PaginatedResponse,
  SalesItemListItem,
  ServiceContract,
  ServiceContractCreateInput,
  ServiceContractItemFull,
  ServiceContractUpdateInput,
  ServiceSchedulerTemplate,
  UserGroupMember,
  WeeklyCarTimeSchedule,
  WeeklyTimeSchedule
} from '../types';
import { SuggestedBooking } from '../types/organizationapp/suggestedBookings';
import { FetchMethods, ResponseType, apiVersion } from './commonResourceTypes';
import { CommonAxiosResType, getNoddiUrl } from './resources';

export const OrganizationURLKeys = {
  getOrganizationCarsPaginated: 'getOrganizationCarsPaginated',
  getOrganizationCars: 'getOrganizationCars',
  getServiceContracts: 'getServiceContracts',
  getServiceSchedulerTemplates: 'getServiceSchedulerTemplates',
  postServiceContract: 'postServiceContract',
  getPrivateSalesItemsForUserGroup: 'getPrivateSalesItemsForUserGroup',
  getServiceContract: 'getServiceContract',
  patchServiceContract: 'patchServiceContract',
  getPaginatedServiceContractCars: 'getPaginatedServiceContractCars',
  getPaginatedDepartments: 'getPaginatedDepartments',
  getDepartments: 'getDepartments',
  getDepartment: 'getDepartment',
  postDepartment: 'postDepartment',
  patchDepartment: 'patchDepartment',
  getDepartmentAddresses: 'getDepartmentAddresses',
  getPaginatedDepartmentAddresses: 'getPaginatedDepartmentAddresses',
  postDepartmentAddress: 'postDepartmentAddress',
  getDepartmentAddress: 'getDepartmentAddress',
  postServiceContractItems: 'postServiceContractItems',
  patchServiceContractItem: 'patchServiceContractItem',
  getServiceContractItem: 'getServiceContractItem',
  getUserGroupMembers: 'getUserGroupMembers',
  getBookingItemsForOrganization: 'getBookingItemsForOrganization',
  getCars: 'getCars',
  getCarsAddress: 'getCarsAddress',
  getOrganizationCar: 'getOrganizationCar',
  getSuggestedOrganizationBookings: 'getSuggestedOrganizationBookings',
  getWeeklyCarTimeSchedule: 'getWeeklyCarTimeSchedule',
  getSuggestedOrganizationBookingsTimeWindows: 'getSuggestedOrganizationBookingsTimeWindows'
} as const;

export type OrganizationURLKeysType = keyof typeof OrganizationURLKeys;

type InputDataOrVoid<Type extends OrganizationURLKeysType> = InputData<Type> extends void ? void : InputData<Type>;

type InputData<urlKey extends OrganizationURLKeysType> = OrganizationServerRequests[urlKey]['input'];

export const getOrganizationUrls: {
  [key in OrganizationURLKeysType]: {
    getUrl: (input: InputDataOrVoid<key>) => string;
    getBody?: (input: InputDataOrVoid<key>) => Partial<InputData<key>>;
    method: FetchMethods;
    responseType?: ResponseType;
    handleRes?: (res: CommonAxiosResType<key>) => Promise<unknown>;
    skipConvertingToCamelCase?: boolean;
  };
} = {
  getOrganizationCarsPaginated: {
    getUrl: ({ userGroupId, ...queryParams }) => {
      return getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/organization-cars`,
        queryParams
      });
    },
    method: FetchMethods.get
  },
  getOrganizationCars: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/organization-cars`
      }),
    method: FetchMethods.get
  },
  getOrganizationCar: {
    getUrl: ({ userGroupId, carId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/organization-cars/${carId}`
      }),
    method: FetchMethods.get
  },
  getServiceContracts: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/service-contracts`
      }),
    method: FetchMethods.get
  },
  getServiceSchedulerTemplates: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-scheduler-templates`
      }),
    method: FetchMethods.get
  },
  postServiceContract: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-contracts/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getPrivateSalesItemsForUserGroup: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/private-sales-items/`
      }),
    method: FetchMethods.get
  },
  getServiceContract: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-contracts/${id}`
      }),
    method: FetchMethods.get
  },
  patchServiceContract: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-contracts/${id}`
      }),
    getBody: ({ id, ...rest }) => rest,
    method: FetchMethods.patch
  },
  getPaginatedServiceContractCars: {
    getUrl: ({ id, ...queryParams }) =>
      getNoddiUrl({
        path: `${apiVersion}service-contracts/${id}/cars`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedDepartments: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}departments/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getDepartments: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}departments/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getDepartment: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}departments/${id}`
      }),
    method: FetchMethods.get
  },
  postDepartment: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}departments/`
      }),
    getBody: ({ name, userGroupId }) => ({
      name,
      userGroupId
    }),
    method: FetchMethods.post
  },
  patchDepartment: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}departments/${id}`
      }),
    getBody: ({ id, ...rest }) => rest,
    method: FetchMethods.patch
  },
  getDepartmentAddresses: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}department-addresses/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getPaginatedDepartmentAddresses: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}department-addresses/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  postDepartmentAddress: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}department-addresses/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  getDepartmentAddress: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}department-addresses/${id}`
      }),
    method: FetchMethods.get
  },
  postServiceContractItems: {
    getUrl: () =>
      getNoddiUrl({
        path: `${apiVersion}service-contract-items/`
      }),
    getBody: (data) => data,
    method: FetchMethods.post
  },
  patchServiceContractItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-contract-items/${id}`
      }),
    getBody: ({ id, ...rest }) => rest,
    method: FetchMethods.patch
  },
  getServiceContractItem: {
    getUrl: ({ id }) =>
      getNoddiUrl({
        path: `${apiVersion}service-contract-items/${id}`
      }),
    method: FetchMethods.get
  },
  getUserGroupMembers: {
    getUrl: ({ userGroupId }) =>
      getNoddiUrl({
        path: `${apiVersion}user-groups/${userGroupId}/user-group-members`
      }),
    method: FetchMethods.get
  },
  getBookingItemsForOrganization: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}booking-items/for-organization`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCarsAddress: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}addresses/default-for-cars/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getCars: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}cars`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getSuggestedOrganizationBookings: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}bookings/suggested-organization-bookings/`,
        queryParams
      }),
    method: FetchMethods.get
  },
  getSuggestedOrganizationBookingsTimeWindows: {
    getUrl: ({ serviceContractId }) =>
      getNoddiUrl({
        path: `${apiVersion}service-contract-items/${serviceContractId}/suggested-booking-time-windows/`
      }),
    method: FetchMethods.get
  },
  getWeeklyCarTimeSchedule: {
    getUrl: (queryParams) =>
      getNoddiUrl({
        path: `${apiVersion}cars/weekly-time-schedule/`,
        queryParams
      }),
    method: FetchMethods.get
  }
} as const;

export interface OrganizationServerRequests {
  getOrganizationCarsPaginated: {
    input: GetOrganizationCarsInput;
    output: PaginatedResponse<OrganizationCar[]>;
  };
  getOrganizationCars: {
    input: {
      userGroupId: number | undefined | null;
    };
    output: OrganizationCar[];
  };
  getOrganizationCar: {
    input: {
      userGroupId: number;
      carId: number;
    };
    output: OrganizationCar;
  };
  getServiceContracts: {
    input: {
      userGroupId: number;
    };
    output: ServiceContract[];
  };
  getServiceSchedulerTemplates: {
    input: null;
    output: ServiceSchedulerTemplate[];
  };
  postServiceContract: {
    input: ServiceContractCreateInput;
    output: ServiceContract;
  };
  getPrivateSalesItemsForUserGroup: {
    input: {
      userGroupId: number;
    };
    output: SalesItemListItem[];
  };
  getServiceContract: {
    input: {
      id: number;
    };
    output: ServiceContract;
  };
  patchServiceContract: {
    input: ServiceContractUpdateInput;
    output: ServiceContract;
  };
  getPaginatedServiceContractCars: {
    input: PaginatedInput & { id: number; isActive?: boolean };
    output: PaginatedResponse<OrganizationCar[]>;
  };
  getPaginatedDepartments: {
    input: PaginatedInput & { userGroupId?: number };
    output: PaginatedResponse<DepartmentListItem[]>;
  };
  getDepartments: {
    input: { userGroupId?: number };
    output: DepartmentListItem[];
  };
  getDepartment: {
    input: {
      id: number;
    };
    output: Department;
  };
  postDepartment: {
    input: DepartmentCreateInput;
    output: Department;
  };
  patchDepartment: {
    input: DepartmentUpdateInput;
    output: AxiosResponse<Department>;
  };
  getDepartmentAddresses: {
    input: { userGroupId?: number; departmentId?: number; contactPersonId?: number };
    output: DepartmentAddress[];
  };
  getPaginatedDepartmentAddresses: {
    input: PaginatedInput & { userGroupId?: number; departmentId?: number };
    output: PaginatedResponse<DepartmentAddress[]>;
  };
  postDepartmentAddress: {
    input: DepartmentAddressCreateInput;
    output: DepartmentAddress;
  };
  getDepartmentAddress: {
    input: {
      id: number;
    };
    output: DepartmentAddress;
  };
  postServiceContractItems: {
    input: {
      serviceContractId: number;
      address: AddressProps;
      startsAt: string;
      carIds?: number[];
      carId?: number;
      userGroupId: number;
      weeklyTimeSchedule: WeeklyTimeSchedule;
    };
    output: unknown;
  };
  patchServiceContractItem: {
    input: Pick<ServiceContractItemFull, 'id'> & Partial<Omit<ServiceContractItemFull, 'serviceContract' | 'car'>>;
    output: unknown;
  };
  getServiceContractItem: {
    input: {
      id: number;
    };
    output: ServiceContractItemFull;
  };
  getUserGroupMembers: {
    input: {
      userGroupId: number;
    };
    output: UserGroupMember[];
  };

  getBookingItemsForOrganization: {
    input: PaginatedInput & OrganizationBookingItems;
    output: PaginatedResponse<OrganizationBookingItem[]>;
  };
  getCars: {
    input: { ids: number[] };
    output: CarListItem[];
  };
  getCarsAddress: {
    input: { carIds: number[] };
    output: AddressProps | null;
  };
  getSuggestedOrganizationBookings: {
    input: PaginatedInput & { userGroupId: number; addressIds: number[] };
    output: PaginatedResponse<SuggestedBooking[]>;
  };
  getWeeklyCarTimeSchedule: {
    input: { userGroupId: number; licensePlateNumbers: string[] };
    output: WeeklyCarTimeSchedule[];
  };
  getSuggestedOrganizationBookingsTimeWindows: {
    input: { serviceContractId: number; overrideCapacity: boolean; limit?: number };
    output: AvailableBookingTimeWindow[];
  };
}
