import { Path, Svg, SvgProps } from 'react-native-svg';

const QrScan = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} fill='none' viewBox='0 0 30 30' {...props}>
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M25 17c0 3.771 0 5.657-1.172 6.828C22.657 25 20.771 25 17 25M13 25c-3.771 0-5.657 0-6.828-1.172C5 22.657 5 20.771 5 17M13 5C9.229 5 7.343 5 6.172 6.172 5 7.343 5 9.229 5 13M17 5c3.771 0 5.657 0 6.828 1.172C25 7.343 25 9.229 25 13'
    />
  </Svg>
);
export { QrScan };
