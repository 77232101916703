import { Trans, t } from '@lingui/macro';
import { tracking } from 'noddi-provider';
import { NoddiBasicCard, NoddiDummyButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import ContentWrapper from '../../components/Layouts/ContentWrapper';

interface BookingConfirmationProps {
  id: string;
}

const BookingConfirmation = ({ id }: BookingConfirmationProps) => {
  const navigate = useNavigate();

  return (
    <ContentWrapper hideBackButton title={t`Booking confirmed`}>
      <div className='flex flex-col gap-8'>
        <div className='flex flex-col gap-4'>
          <NoddiBasicCard
            onClick={() => navigate(routes.myBookingDetails.getPath({ id }))}
            className='flex flex-col gap-4 text-primary-darkPurple'
          >
            <p>
              <Trans>A confirmation message will be sent to you via SMS shortly.</Trans>
            </p>
            <div className='flex flex-col gap-1'>
              <p className='font-bold'>
                <Trans>Payment information</Trans> 💰
              </p>
              <p>
                <Trans>Once the job is complete, you'll receive a payment link via SMS.</Trans>
              </p>
            </div>
            <div className='flex justify-end'>
              <NoddiDummyButton className='-mr-2' endIcon='ArrowRight' size='small'>
                <Trans>See booking</Trans>
              </NoddiDummyButton>
            </div>
          </NoddiBasicCard>
        </div>
        <NoddiBasicCard
          onClick={() => {
            tracking.track('referralPressedAfterBooking');
            navigate(routes.bonus.getPath());
          }}
          className='flex flex-col gap-4 bg-secondary-lightPurple text-primary-darkPurple'
        >
          <p className='font-bold text-5'>
            <Trans>Enjoying Noddi so far?</Trans>
          </p>

          <p>
            <Trans>Refer a friend and you both get up to 300 kr in discounts on future bookings</Trans>💜
          </p>
          <div className='flex justify-end'>
            <NoddiDummyButton variant='link' className='-mr-2' endIcon='ArrowRight' size='small'>
              <Trans>See details</Trans>
            </NoddiDummyButton>
          </div>
        </NoddiBasicCard>
      </div>
    </ContentWrapper>
  );
};

export default BookingConfirmation;
