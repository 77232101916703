import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface AnnouncementStore {
  hiddenAnnouncements: Record<number, boolean>;
  hideAnnouncement: (addressId: number) => void;
}

const initialStateAnnouncementStore = {
  hiddenAnnouncements: {}
};

const useAnnouncementStore = create<AnnouncementStore>()(
  persist(
    (set) => ({
      ...initialStateAnnouncementStore,
      hideAnnouncement: (addressId) =>
        set((state) => ({
          hiddenAnnouncements: { ...state.hiddenAnnouncements, [addressId]: true }
        }))
    }),
    {
      name: 'announcementStore',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);

// Getters
export const useIsAnnouncementHidden = (addressId?: number) =>
  useAnnouncementStore((state) => (addressId ? state.hiddenAnnouncements[addressId] : false));

// Actions
export const useAnnouncementActions = () => {
  const hideAnnouncement = useAnnouncementStore((state) => state.hideAnnouncement);
  return {
    hideAnnouncement
  };
};
