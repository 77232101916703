import { IconName, NoddiIcon, cn, colors, useIsMobile } from 'noddi-ui';

import { t } from '@lingui/macro';
import { SALES_ITEM_TYPE } from 'noddi-async/src/types';
import { BOOKING_CATEGORY_ENUM, bookingCategoryLabelToEnum, translateBookingCategories } from '../../helpers/utils';

type Variant = {
  backgroundImage: string;
  iconName: IconName;
};

export const variants = (isMobile: boolean): Record<BOOKING_CATEGORY_ENUM, Variant> => ({
  [BOOKING_CATEGORY_ENUM.PACKAGES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/car_care.svg`,
    iconName: 'Heart'
  },
  [BOOKING_CATEGORY_ENUM.WASHING_SERVICES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/washing_package.svg`,
    iconName: 'Drop'
  },
  [BOOKING_CATEGORY_ENUM.WHEEL_SERVICES]: {
    backgroundImage: `/assets/svgs/booking/serviceCards/${isMobile ? 'mobile' : 'desktop'}/wheel_service.svg`,
    iconName: 'WheelChange'
  }
});

type ServiceCategoryHeaderProps = {
  priceFrom?: string;
  isExpanded: boolean;
  numberOfSelectedSalesItems: number;
  numberOfCategories: number;
  category: string;
  salesItemType: SALES_ITEM_TYPE;
};

export const ServiceCategoryHeader = ({
  priceFrom,
  isExpanded,
  numberOfSelectedSalesItems,
  numberOfCategories,
  category,
  salesItemType
}: ServiceCategoryHeaderProps) => {
  const isMobile = useIsMobile();
  const categoryType = bookingCategoryLabelToEnum(category);
  const serviceName = translateBookingCategories(category);
  const isPopular =
    bookingCategoryLabelToEnum(category) === BOOKING_CATEGORY_ENUM.WHEEL_SERVICES &&
    salesItemType === SALES_ITEM_TYPE.PRIMARY &&
    numberOfCategories > 1;

  const variant = variants(isMobile)[categoryType];

  return (
    <div className='relative w-full'>
      {isPopular && (
        <div className='absolute -top-6 right-4 z-10 w-fit rounded-r-3xl rounded-tl-3xl bg-primary-purple px-4 py-2 text-primary-white'>
          {t`Popular`}
        </div>
      )}
      <div
        className={cn(
          'relative flex w-full gap-2 overflow-hidden rounded-2xl bg-primary-white bg-no-repeat px-4 pb-8 pt-6 duration-300 sm:pb-12',
          isExpanded && '!min-h-[unset]'
        )}
      >
        <img
          className={cn('absolute right-0 top-1/2 duration-300 sm:top-6', isExpanded ? 'opacity-0' : 'opacity-100')}
          src={variant.backgroundImage}
          alt='bg_image'
        />
        <div className='absolute right-4 top-6 sm:right-11 sm:top-1/2'>
          <NoddiIcon name={isExpanded ? 'AltArrowUp' : 'AltArrowDown'} color={colors.primary.black} />
        </div>

        <div className='flex gap-2'>
          <div>
            {numberOfSelectedSalesItems > 0 ? (
              <div className='mt-1 flex size-8 items-center justify-center rounded-full bg-primary-purple text-primary-white'>
                {numberOfSelectedSalesItems}
              </div>
            ) : (
              <div className='mt-1'>
                <NoddiIcon name={variant.iconName} size='large' />
              </div>
            )}
          </div>
          <div className='flex flex-col gap-1'>
            <span className='text-6'>{serviceName}</span>
            {priceFrom && <span className='font-semibold'>{priceFrom}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};
