import { Circle, Path, Svg, SvgProps } from 'react-native-svg';
const FilledCircleChecked = ({ color, ...rest }: SvgProps) => (
  <Svg width={26} height={27} viewBox='0 0 26 27' fill='none' {...rest}>
    <Circle cx={13} cy={13.5} r={9.5} fill={color} stroke={color} />
    <Path fill={color} d='m9 14.5 3 3 5-7m8 3a12 12 0 1 1-24.002 0A12 12 0 0 1 25 13.5Z' />
    <Path
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='m9 14.5 3 3 5-7m8 3a12 12 0 1 1-24.002 0A12 12 0 0 1 25 13.5Z'
    />
    <Circle cx={13} cy={13.5} r={11.25} stroke='#fff' strokeWidth={1.5} />
    <Circle cx={13} cy={13.5} r={12.5} stroke={color} />
  </Svg>
);
export { FilledCircleChecked };
