import { Path, Svg, SvgProps } from 'react-native-svg';

const AltArrowDown = ({ color, ...props }: SvgProps) => (
  <Svg width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      d='M23.4667 12.8L16 19.2L8.53333 12.8'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export { AltArrowDown };
