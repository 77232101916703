import { plural, t } from '@lingui/macro';
import { NoddiButton, NoddiDialog, getCarDisplayName } from 'noddi-ui';
import { useState } from 'react';
import { useBookingCars } from '../BookingStore';

export const SelectedCars = () => {
  const selectedCars = useBookingCars();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const numberOfSelectedCars = selectedCars.length;

  const carMessage = plural(numberOfSelectedCars, {
    one: 'Car',
    other: 'Cars'
  });

  if (numberOfSelectedCars === 0) {
    return null;
  }

  return (
    <>
      <NoddiDialog
        sx={{ zIndex: 1400 }}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        title={t`Selected cars`}
        maxWidth='md'
      >
        <div className='flex flex-col gap-2'>
          {selectedCars.map((selectedCar) => (
            <span key={selectedCar.licensePlate.number}>{getCarDisplayName(selectedCar)}</span>
          ))}
        </div>
      </NoddiDialog>

      <NoddiButton
        variant='secondary'
        onClick={() => {
          setIsDialogOpen(true);
        }}
        startIcon='Car'
      >
        {numberOfSelectedCars} {carMessage}
      </NoddiButton>
    </>
  );
};
