import { i18n } from '@lingui/core';
import {
  DateArg,
  addDays as dateFnsAddDays,
  addMinutes as dateFnsAddMinutes,
  compareAsc as dateFnsCompareAsc,
  compareDesc as dateFnsCompareDesc,
  differenceInDays as dateFnsDifferenceInDays,
  differenceInHours as dateFnsDifferenceInHours,
  differenceInMilliseconds as dateFnsDifferenceInMilliseconds,
  differenceInMinutes as dateFnsDifferenceInMinutes,
  endOfMonth as dateFnsEndOfMonth,
  endOfToday as dateFnsEndOfToday,
  endOfYear as dateFnsEndOfYear,
  format as dateFnsFormat,
  getDay as dateFnsGetDay,
  intervalToDuration as dateFnsIntervalToDuration,
  isAfter as dateFnsIsAfter,
  isBefore as dateFnsIsBefore,
  isEqual as dateFnsIsEqual,
  isToday as dateFnsIsToday,
  isTomorrow as dateFnsIsTomorrow,
  minutesToMilliseconds as dateFnsMinutesToMilliseconds,
  parse as dateFnsParse,
  secondsToHours as dateFnsSecondsToHours,
  secondsToMinutes as dateFnsSecondsToMinutes,
  startOfMonth as dateFnsStartOfMonth,
  startOfToday as dateFnsStartOfToday,
  startOfTomorrow as dateFnsStartOfTomorrow,
  startOfYear as dateFnsStartOfYear,
  subDays as dateFnsSubDays,
  subMonths as dateFnsSubMonths,
  endOfTomorrow,
  getDate,
  getYear,
  isDate,
  isSameDay,
  parseISO
} from 'date-fns';
import { enUS, nb } from 'date-fns/locale';
import isNumber from 'lodash/isNumber';

import { Nullable } from 'vitest';
import { stringToUpperCase } from './string';

export enum DateFormats {
  MONTH_NAME = 'LLLL', // "January"
  TIME = 'HH:mm', // "14:30"
  FULL_MONTH_DATE_TIME = 'd. MMMM yyyy, HH:mm', // "7. October 2025, 14:30"
  FULL_WEEKDAY = 'EEEE', // "Monday"
  WEEKDAY_WITH_NUMBER_AND_MONTH = 'EE dd. MMM', // "Mon 07. Oct"
  DASHED_DATE_ISO_8601 = 'yyyy-MM-dd', // "2025-10-07"
  DASHED_DATE = 'dd-MM-yyyy', // "07-10-2025"
  DOTTED_DATE_SHORT_YEAR = 'dd.MM.yy', // "07.10.25"
  SHORT_WEEKDAY_DAY_MONTH = 'EE. dd.MM', // "Mon. 07.10"
  DASHED_DATE_TIME = 'dd-MM-yyyy HH:mm', // "07-10-2025 14:30"
  DASHED_DATE_TIME_ISO_8601 = 'yyyy-MM-dd HH:mm', // "2025-10-07 14:30"
  FULL_WEEKDAY_MONTH_AND_YEAR = 'EEEE, MMMM d, yyyy', // "Monday, October 7, 2025"
  SLASHED_DATE = 'dd/MM/yyyy', // "07/10/2025"
  SLASHED_DATE_TIME = 'dd/MM/yyyy HH:mm', // "07/10/2025 14:30"
  DOTTED_DATE_TIME = 'dd.MM.yyyy HH:mm', // "07.10.2025 14:30"
  DOTTED_DATE = 'dd.MM.yyyy', // "07.10.2025"
  FULL_DAY_MONTH_PARTIAL_YEAR = 'EEEE dd MMMM yy', // "Monday 07 October 25"
  FULL_DAY_MONTH = 'EEEE, d MMMM', // "Monday, 7 October"
  FULL_MONTH_YEAR = 'dd MMMM, yyyy', // "07 October, 2025"
  FULL_MONTH_YEAR_WITHOUT_COMMA = 'dd MMMM yyyy', // "07 October 2025"
  SHORT_DAY = 'dd', // "07"
  SHORT_MONTH = 'MMM', // "Oct"
  SHORT_DAY_SHORT_MONTH = 'd. MMM', // "7. Oct"
  HOURS = 'HH', // "14"
  FULL_MONTH = 'MMMM', // "October"
  FULL_YEAR = 'yyyy', // "2025"
  FULL_DAY_SHORT_MONTH_AND_YEAR = 'EEEE MMM dd', // "Monday Oct 07"
  YEAR_MONTH_DAY = 'yyyy-MM-d', // "2025-10-7"
  DAY_MONTH = 'dd MMMM', // "07 October"
  DAY_SHORT_MONTH_YEAR = 'dd. MMM yyyy', // "07. Oct 2025"
  FULL_MONTH_YEAR_DATE_TIME = 'dd MMMM yyyy HH:mm' // "07 October 2025 14:30"
}

export type DateFnsDate = DateArg<Date>;

export type MaybeDate = Nullable<DateFnsDate>;

export const INVALID_DATE_PLACEHOLDER = '';

/**
 * Get Day of the week
 * @param date - Date to get day of the week from
 * @returns - Day of the week
 */
export const getDay = (date: MaybeDate) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsGetDay(extractedDate);
  } catch (error) {
    console.error(`getDay: Failed to get day for date: ${date}`, error);

    return undefined;
  }
};

/**
 * Add months to a date
 * @param date - Date to add months to
 * @param amount - Amount of months to add
 * @returns - Date with added months
 */
export const subMonths = (date: MaybeDate, amount: number) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsSubMonths(extractedDate, amount);
  } catch (error) {
    console.error(`subMonths: Failed to sub amount: ${amount} months from date: ${date}`, error);

    return undefined;
  }
};

/**
 * Get end of the year
 * @param date - Date to get end of the year from
 * @returns - End of the year
 */
export const getEndOfYear = (date: MaybeDate) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsEndOfYear(extractedDate);
  } catch (error) {
    console.error(`getEndOfYear: Failed to get end of the year for date: ${date}`, error);

    return undefined;
  }
};

/**
 * Get start of the year
 * @param date - Date to get start of the year from
 * @returns - Start of the year
 */
export const getStartOfYear = (date: MaybeDate) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsStartOfYear(extractedDate);
  } catch (error) {
    console.error(`getStartOfYear: Failed to get start of the year for date: ${date}`, error);

    return undefined;
  }
};

/**
 * Get end of the month
 * @param date - Date to get end of the month from
 * @returns - End of the month
 */
export const getEndOfMonth = (date: MaybeDate) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsEndOfMonth(extractedDate);
  } catch (error) {
    console.error(`getEndOfMonth: Failed to get end of the month for date: ${date}`, error);

    return undefined;
  }
};

/**
 * Get start of the month
 * @param date - Date to get start of the month from
 * @returns - Start of the month
 */
export const getStartOfMonth = (date: MaybeDate) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsStartOfMonth(extractedDate);
  } catch (error) {
    console.error(`startOfMonth: Failed to get start of the month for date: ${date}`, error);

    return undefined;
  }
};

/**
 * Extract date from string, number or date
 * @param date - Date to extract
 * @returns - Extracted date
 */
export const extractDate = (date: MaybeDate) => {
  try {
    let parsedDate: Date | null | undefined = undefined;

    if (typeof date === 'string') {
      parsedDate = parseISO(date);
    } else if (isNumber(date)) {
      parsedDate = new Date(date);
    } else if (isDate(date)) {
      parsedDate = date;
    }

    return parsedDate;
  } catch (error) {
    throw Error(`extractDate: Failed to extract date from date: ${date} with error ${error}`);
  }
};

/**
 * Convert i18n locale to date-fns locale
 * @returns - Date-fns locale
 */
export const i18nLocaleToDateFnsLocale = () => {
  switch (i18n.locale) {
    case 'en':
      return enUS;
    case 'nb':
    default:
      return nb;
  }
};

/**
 * Format date to string
 * @param date - Date to format
 * @param format - Format to use
 * @returns - Formatted date
 */
export const format = (date: MaybeDate, format: DateFormats): string => {
  try {
    if (!date) {
      return INVALID_DATE_PLACEHOLDER;
    }

    const parsedDate = extractDate(date);

    if (!parsedDate) {
      return INVALID_DATE_PLACEHOLDER;
    }

    return dateFnsFormat(parsedDate, format, { locale: i18nLocaleToDateFnsLocale() ?? nb });
  } catch (error) {
    console.error(`format: Failed to format date: ${date} into format: ${format}`, error);
    return INVALID_DATE_PLACEHOLDER;
  }
};

export type Operator = 'isAfter' | 'isBefore' | 'areEqual';

/**
 * Compare dates
 * @param dateLeft
 * @param dateRight
 * @param operator
 * @returns - True if operator is true
 */
export const compareDates = (dateLeft: MaybeDate, dateRight: MaybeDate, operator: Operator) => {
  try {
    const extractedDateLeft = extractDate(dateLeft);
    const extractedDateRight = extractDate(dateRight);

    if (cannotCompareDates(extractedDateLeft, extractedDateRight)) {
      return false;
    }

    let operation: undefined | ((date: number | Date, date2: number | Date) => boolean);
    switch (operator) {
      case 'isAfter':
        operation = dateFnsIsAfter;
        break;
      case 'isBefore':
        operation = dateFnsIsBefore;
        break;
      case 'areEqual':
        operation = dateFnsIsEqual;
        break;
      default:
        return false;
    }

    return operation(extractedDateLeft!, extractedDateRight!);
  } catch (error) {
    console.error(`${operator}: Failed to compare dates for dateLeft: ${dateLeft} and dateRight: ${dateRight}`, error);

    return false;
  }
};

/**
 * Add minutes to a date
 * @param date - Date to add minutes to
 * @param amount - Amount of minutes to add
 * @returns - Date with added minutes
 */
export const addMinutes = (date: MaybeDate, amount: number) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsAddMinutes(extractedDate, amount);
  } catch (error) {
    console.error(`addMinutes: Failed to add amount: ${amount} minutes to date: ${date}`, error);

    return undefined;
  }
};

/**
 * Add days to a date
 * @param date - Date to add days to
 * @param amount - Amount of days to add
 * @returns - Date with added days
 */
export const addDays = (date: MaybeDate, amount: number) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsAddDays(extractedDate, amount);
  } catch (error) {
    console.error(`addDays: Failed to add amount: ${amount} days to date: ${date}!`, error);

    return undefined;
  }
};

/**
 * Subtract days from a date
 * @param date - Date to subtract days from
 * @param amount - Amount of days to subtract
 * @returns - Date with subtracted days
 */
export const subDays = (date: MaybeDate, amount: number) => {
  try {
    const extractedDate = extractDate(date);

    if (!extractedDate) {
      return undefined;
    }

    return dateFnsSubDays(extractedDate, amount);
  } catch (error) {
    console.error(`subDays: Failed to sub amount: ${amount} days from date: ${date}!`, error);

    return undefined;
  }
};

/**
 * Get start of tomorrow
 * @returns - Start of tomorrow
 */
export const getStartOfTomorrow = () => {
  try {
    return dateFnsStartOfTomorrow();
  } catch (error) {
    console.error('getStartOfTomorrow: Failed to get start of tomorrow', error);

    return new Date();
  }
};

/**
 * Get end of today
 * @returns - End of today
 */
export const getEndOfToday = () => {
  try {
    return dateFnsEndOfToday();
  } catch (error) {
    console.error('getEndOfToday: Failed to get end of today', error);

    return new Date();
  }
};

/**
 * Get start of today
 * @returns - Start of today
 */
export const getStartOfToday = () => {
  try {
    return dateFnsStartOfToday();
  } catch (error) {
    console.error('getStartOfToday: Failed to get start of today', error);

    return new Date();
  }
};

/**
 * Parse date from string
 * @param dateString - Date string to parseDate
 * @param format - Format to use
 * @param fallback - Fallback date
 * @returns - Parsed date
 */
export const parseDate = (dateString: Nullable<string>, format: DateFormats, fallback?: Date) => {
  try {
    if (!dateString) {
      return fallback ?? new Date();
    }

    return dateFnsParse(dateString, format, new Date());
  } catch (error) {
    console.error(
      `parseDate: Failed to parse dateString: ${dateString} to format: ${format} with fallback: ${fallback}`,
      error
    );

    return new Date();
  }
};

/**
 * Parse seconds to minutes
 * @param value - Value to parseDate
 * @returns - Value in minutes
 */
export const fromSecondsToMinutes = (value: number) => Math.round((value / 60) * 10) / 10;

/**
 * Parse seconds to hours
 * @param value - Value to parseDate
 * @returns - Value in hours
 */
export const fromSecondsToHours = (value: number) => Math.round((value / 3600) * 10) / 10;

/**
 * get day name with date string
 * @param dateString - Date string to get day name from
 * @param withYear - Include year in the string
 * @returns - Day name with date string
 */
export const getDayNameAndDateString = (dateString: string, withYear?: boolean) => {
  const dayName = format(dateString, DateFormats.FULL_WEEKDAY);
  const dateNumber = getDate(dateString);
  const monthName = format(dateString, DateFormats.MONTH_NAME);
  const year = getYear(new Date(dateString));

  return `${stringToUpperCase(dayName)}, ${dateNumber}.${monthName}${withYear ? ` ${year}` : ''}`;
};

/**
 * Parse seconds to hours
 * @param seconds - Seconds to parseDate
 * @returns - Hours
 */
export const secondsToHours = (seconds: number) => dateFnsSecondsToHours(seconds);

/**
 * Parse seconds to minutes
 * @param seconds - Seconds to parseDate
 * @returns - Minutes
 */
export const secondsToMinutes = (seconds: number) => dateFnsSecondsToMinutes(seconds);

/**
 * Parse hours to seconds
 * @param hours - Hours to parseDate
 * @returns - Seconds
 */
export const hoursToSeconds = (hours: number) => hours * 60 * 60;

/**
 * Parse minutes to seconds
 * @param minutes - Minutes to parseDate
 * @returns - Seconds
 */
export const minutesToSeconds = (minutes: number) => minutes * 60;

/**
 * Parse minutes to milliseconds
 * @param minutes - Minutes to parseDate
 * @returns - Milliseconds
 */
export const minutesToMilliseconds = (minutes: number) => dateFnsMinutesToMilliseconds(minutes);

/**
 * Check if date is today
 * @param date - Date to check
 * @returns - True if date is today
 */
export const isToday = (date: DateFnsDate) => dateFnsIsToday(date);

/**
 * Check if date is tomorrow
 * @param date - Date to check
 * @returns - True if date is tomorrow
 */
export const isTomorrow = (date: DateFnsDate) => dateFnsIsTomorrow(date);

/**
 * Gets tomorrow
 * @returns - Returns tomorrow
 */
export const getTomorrow = () => {
  return addDays(new Date(), 1);
};

export type SortMode = 'asc' | 'desc';

/**
 * Check if date is invalid
 * @param date
 * @returns - True if date is invalid
 */
export const isDateInvalid = (date: Date) => isNaN(date.getTime());

/**
 * Check if dates for comparison are valid
 * @param dateLeft - Date to compare
 * @param dateRight - Date to compare
 * @returns - True if dates are invalid
 */
export const cannotCompareDates = (dateLeft: Nullable<Date>, dateRight: Nullable<Date>) =>
  !dateLeft || !dateRight || isDateInvalid(dateLeft) || isDateInvalid(dateRight);

/**
 * SortMode compare
 * @param dateLeft - Date to compare
 * @param dateRight - Date to compare
 * @param sort - Sort mode
 * @returns - Comparison result
 */
export const datesSortCompare = (dateLeft: MaybeDate, dateRight: MaybeDate, sort: SortMode) => {
  try {
    const extractedDateLeft = extractDate(dateLeft);
    const extractedDateRight = extractDate(dateRight);

    if (cannotCompareDates(extractedDateLeft, extractedDateRight)) {
      return -1;
    }

    let operation: undefined | ((dateLeft: Date | number, dateRight: Date | number) => number);
    switch (sort) {
      case 'asc':
        operation = dateFnsCompareAsc;
        break;
      case 'desc':
        operation = dateFnsCompareDesc;
        break;
      default:
        return -1;
    }

    return operation(extractedDateLeft!, extractedDateRight!);
  } catch (error) {
    console.error(
      `compare ${sort}: Failed to compare dateLeft: ${dateLeft} and dateRight: ${dateRight} in ASC mode`,
      error
    );

    return -1;
  }
};

export type TimeUnit = 'days' | 'hours' | 'minutes' | 'milliseconds';

/**
 * Normalize date to midnight
 * @param date - Date to normalize
 * @returns - Normalized date
 * */
const normalizeToMidnight = (date: Date): Date => {
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);
  return normalized;
};

/**
 * Get difference between dates, Not sure this works with TzDates?
 * @param dateLeft - Date to compare
 * @param dateRight - Date to compare
 * @param timeUnit -  Time unit
 * @returns - Difference between dates
 */
export const differenceBetweenDates = (dateLeft: MaybeDate, dateRight: MaybeDate, timeUnit: TimeUnit) => {
  const extractedDateLeft = extractDate(dateLeft);
  const extractedDateRight = extractDate(dateRight);

  if (!extractedDateLeft || !extractedDateRight || cannotCompareDates(extractedDateLeft, extractedDateRight)) {
    return -1;
  }

  // If the time unit is days, normalize to midnight
  let operation: ((dateLeft: Date, dateRight: Date) => number) | undefined;
  if (timeUnit === 'days') {
    operation = (dateLeft, dateRight) =>
      dateFnsDifferenceInDays(normalizeToMidnight(dateLeft), normalizeToMidnight(dateRight));
  } else {
    switch (timeUnit) {
      case 'hours':
        operation = dateFnsDifferenceInHours;
        break;
      case 'minutes':
        operation = dateFnsDifferenceInMinutes;
        break;
      case 'milliseconds':
        operation = dateFnsDifferenceInMilliseconds;
        break;
      default:
        return -1;
    }
  }

  return operation(extractedDateLeft, extractedDateRight);
};

/**
 * Get interval to duration
 * @param start - Start interval
 * @param end - End interval
 * numbers are interpreted as milliseconds
 * @returns - Duration
 */
export const intervalToDuration = ({ start, end }: { start: number | Date; end: number | Date }) => {
  return dateFnsIntervalToDuration({
    start,
    end
  });
};
/**
 * Parse seconds to pretty time
 * @param seconds - Seconds to parseDate
 * @returns - Pretty time
 */
export const secondsToPrettyTime = (seconds: number) => {
  if (seconds < 60 * 60) {
    return Math.floor(seconds / 60) + ' min';
  } else {
    return Math.floor(seconds / 60 / 60) + ' h ' + Math.floor((seconds / 60) % 60) + ' m';
  }
};

/**
 * Time window string composition
 * @param start - Start date
 * @param end - End date
 * @returns - Time window string
 */
export const timeWindowToDisplayString = (start: Date, end: Date) => {
  const formattedDate1 = format(start, DateFormats.FULL_MONTH_DATE_TIME);
  const formattedDate2 = format(end, DateFormats.TIME);

  return `${formattedDate1} - ${formattedDate2}`;
};

type Translation = {
  today: string;
  tomorrow: string;
};

/**
 * Get date to `fromNow` format
 * @param date - Date to convert
 * @param translations - Translations
 * @returns - `fromNow` formatted date
 */
export const dateToFromNowDaily = ({ date, translations }: { date: string; translations: Translation }) => {
  const today = getStartOfToday();
  const tomorrow = endOfTomorrow();
  const evaluatedDate = new Date(date);
  if (isSameDay(today, evaluatedDate)) {
    return translations.today;
  } else if (isSameDay(tomorrow, evaluatedDate)) {
    return translations.tomorrow;
  } else {
    return format(evaluatedDate, DateFormats.WEEKDAY_WITH_NUMBER_AND_MONTH);
  }
};

/**
 * Parse `HH:mm` format to minutes since midnight
 * @param HHMM - Time in `HH:mm` format
 * @returns - Minutes since midnight
 */
export const HHMMtoMinutesSinceMidnight = (HHMM: string) => {
  const [hours, minutes] = HHMM.split(':');
  if (!hours || !minutes) {
    throw new Error('Invalid time format: ' + HHMM);
  }
  return parseInt(hours) * 60 + parseInt(minutes);
};

/**
 *
 * @param dateStr1 - Date1 in format DD-MM-YYYY
 * @param dateStr2 - Date2 in format DD-MM-YYYY
 * @returns - Comparison result for passed dates
 */
export const ddMmYyyySortComparator = (dateStr1: string, dateStr2: string) => {
  const parseDate = (dateStr: string): Date | null => {
    const [dayStr, monthStr, yearStr] = dateStr.split('-');
    const day = Number(dayStr);
    const month = Number(monthStr);
    const year = Number(yearStr);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return null; // Invalid format, return null
    }

    const date = new Date(year, month - 1, day);

    if (isNaN(date.getTime())) {
      return null;
    }

    return date;
  };

  // Parse dates
  const parsedDate1 = parseDate(dateStr1);
  const parsedDate2 = parseDate(dateStr2);

  // Handle invalid dates or null values
  if (!parsedDate1 && !parsedDate2) {
    return 0; // Both dates are invalid or null, consider them equal
  } else if (!parsedDate1) {
    return 1; // dateStr1 is invalid or null, so dateStr2 is greater
  } else if (!parsedDate2) {
    return -1; // dateStr2 is invalid or null, so dateStr1 is greater
  }

  // Compare valid dates by timestamp
  return parsedDate1.getTime() - parsedDate2.getTime();
};
