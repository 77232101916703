import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useRef, useState } from 'react';

import { InputAdornment } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import { URLKeys, noddiAsync } from 'noddi-async';
import { AddressSuggestion } from 'noddi-async/src/types/customerapp/booking/shared';
import { NoddiIcon, NoddiIconButton } from 'noddi-ui';

function useDebounceValue<T>({ value, delay = 150 }: { value: T; delay?: number }) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

// Backend does google places search and returns suggestions
type AddressSearchProps = {
  onSelect: (address: AddressSuggestion) => void;
  onClear?: () => void;
  translations: {
    noAddressesFound: string;
    loadingAddresses: string;
  };
};

export const AddressSearch = ({ onSelect, translations, onClear }: AddressSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounceValue({ value: inputValue });

  const { data: addressSuggestions, isLoading } = noddiAsync.useGet({
    type: URLKeys.getAddressSuggestions,
    input: { queryInput: debouncedInputValue, countryCodes: ['NO', 'SE'] },
    queryConfig: {
      enabled: debouncedInputValue.length > 0
    }
  });

  return (
    <Autocomplete
      getOptionLabel={(option) => option.mainText}
      options={addressSuggestions || []}
      loading={isLoading}
      loadingText={translations.loadingAddresses}
      filterOptions={(x) => x}
      autoComplete
      includeInputInList
      filterSelectedOptions
      noOptionsText={translations.noAddressesFound}
      onInputChange={(_, newInputValue, reason) => {
        if (reason === 'clear') {
          onClear?.();
        }
        setInputValue(newInputValue);
      }}
      onChange={(_, newValue: AddressSuggestion | null) => {
        if (newValue) {
          onSelect(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          inputRef={inputRef}
          label='Address'
          InputLabelProps={{
            sx: {
              ml: '40px',
              mt: '8px',
              transition: 'all 0.2s ease-in-out'
            },
            shrink:
              (inputRef?.current?.value && inputRef?.current?.value?.length > 0) ||
              document.activeElement === inputRef.current // Shrink label when there's input or focus
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start' className='relative bottom-4'>
                <NoddiIcon name='LocationPin' size='large' />
              </InputAdornment>
            )
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.matches || [];

        const parts = parse(
          option.mainText,
          matches.map((match) => [match.startOffset, match.startOffset + match.endOffset])
        );

        return (
          <li {...props} key={option.placeId} className={`${props.className} cursor-pointer`}>
            <div className='flex w-full items-center'>
              <div className='w-[calc(100%-44px)] grow break-words'>
                {parts.map((part) => (
                  <span key={part.text} className={part.highlight ? 'font-bold' : 'font-normal'}>
                    {part.text}
                  </span>
                ))}
                <p>{option.secondaryText}</p>
              </div>
              <div className='flex w-11'>
                <NoddiIconButton iconName='ArrowRight' variant='ghost' iconSize='medium' />
              </div>
            </div>
          </li>
        );
      }}
    />
  );
};
