import { Trans } from '@lingui/macro';

import { useAuthContext } from 'noddi-provider';
import { NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';
import routes from '../../appRoutes';
import { BasicHeaderNav } from '../../components/Layouts/BasicHeaderNav';
import ContentWrapper from '../../components/Layouts/ContentWrapper';

const CompanySignupSuccess = () => {
  const { userData, updateUserData } = useAuthContext();
  const navigate = useNavigate();

  function onNavigateToHomepage() {
    if (userData?.user) {
      const organizationToSelect = userData.user.userGroups.find((ug) => ug.isOrganization);
      if (organizationToSelect) {
        const updatedUserGroups = userData.user.userGroups.map((ug) => ({
          ...ug,
          isSelected: ug.id === organizationToSelect.id
        }));

        updateUserData({
          ...userData,
          user: { ...userData.user, userGroups: updatedUserGroups }
        });
      }
    }

    navigate(routes.homepage.getPath());
  }

  return (
    <div className='min-h-screen bg-peach-to-purple'>
      <BasicHeaderNav />
      <ContentWrapper>
        <div>
          <p className='mt-20 font-bold text-10 text-primary-darkPurple'>
            <Trans>Thank you! </Trans>
          </p>
          <p className='mt-7 font-bold text-7 text-primary-purple'>
            <Trans>We will be in touch with you shortly</Trans>🚀
          </p>
          <p className='mt-4 text-primary-darkPurple'>
            <Trans>If you have any questions, contact us at bedrift@noddi.no</Trans>
          </p>
          <p className='mt-6 text-primary-darkPurple'>
            <Trans>If you want to create a booking right away, you can easily do that through your homepage</Trans>{' '}
          </p>
          <NoddiButton className='mt-4 self-end' onClick={onNavigateToHomepage}>
            <Trans>Go to homepage</Trans>
          </NoddiButton>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default CompanySignupSuccess;
