import { Path, Svg, SvgProps } from 'react-native-svg';

const AltArrowRight = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      d='M12.8 8.53333L19.2 16L12.8 23.4667'
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export { AltArrowRight };
