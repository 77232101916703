import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { noddiAsync } from 'noddi-async';
import {
  AuthProvider,
  LanguageCodeType,
  LanguageProvider,
  NoddiLocalizationProvider,
  TrackScreen,
  useAuthContext,
  useLanguageContext
} from 'noddi-provider';
import { APIBanner, NbFallback, ToastProvider, createTheme } from 'noddi-ui';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { initLocales } from '../../../packages/config';
import routes from './appRoutes';
import { RouterLayout } from './components/Layouts/RouterLayout';
import { VITE_ENGLISH_LOCALE } from './constants/baseUrl';

import { ScreenLoader } from './components/Layouts/ScreenLoader';
import { MaintenanceScreen } from './components/SystemDownTimeBlocker';
import { BookingRouter } from './pages/BookingFlow/BookingRouter';
import ActiveBookingInfo from './pages/BookingInfo';
import Coupons from './pages/Campaigns';
import CompanySignup from './pages/CompanySignup';
import CompanySignupSuccess from './pages/CompanySignup/CompanySignupSuccess';
import Confirmation from './pages/Confirmation';
import { Error404PageWithTranslations } from './pages/Error404';
import Home from './pages/Home';
import { HomeLayout } from './pages/HomeLayout';
import { HomeRouter } from './pages/HomeRouter';
import Login from './pages/Login';
import { Membership } from './pages/Memberships/[slug]';
import PaymentInfo from './pages/PaymentInfo';
import Referrals from './pages/PublicReferrals';
import { TireOfferRouter } from './pages/TireOfferRouter';
import { dynamicActivateLocale } from './utils/lingui';
import { initRemoteConfig } from './utils/remoteConfig';
import { getNoddiRemoteConfig } from './utils/remoteConfigSetup';
import { getSupportedLocales } from './utils/translation';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function App() {
  const theme = createTheme();

  const [isLanguageLoaded, setIsLanguageLoaded] = useState(false);
  const locale = VITE_ENGLISH_LOCALE === 'true' ? 'en' : 'nb';

  useEffect(() => {
    initLocales({
      setIsLanguageLoaded,
      defaultLanguage: locale,
      dynamicActivateLocale,
      supportedLocales: getSupportedLocales()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLanguageLoaded) {
    return null;
  }

  const NoddiServerContext = noddiAsync.NoddiServerContext;

  return (
    <Sentry.ErrorBoundary fallback={<NbFallback />}>
      <I18nProvider i18n={i18n}>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify
          }}
        >
          <NoddiServerContext>
            <AuthProvider>
              <LanguageProvider defaultLocale={locale} dynamicActivateLocale={dynamicActivateLocale}>
                <ThemeProvider theme={theme}>
                  <ToastProvider>
                    <NoddiLocalizationProvider>
                      <RouterWithMaintenanceFallback />
                      <APIBanner />
                      <TrackScreen />
                    </NoddiLocalizationProvider>
                  </ToastProvider>
                </ThemeProvider>
              </LanguageProvider>
            </AuthProvider>
          </NoddiServerContext>
        </QueryParamProvider>
      </I18nProvider>
    </Sentry.ErrorBoundary>
  );
}

const RouterWithMaintenanceFallback = () => {
  const { isPending: isRemoteConfigLoading } = noddiAsync.getReactQuery().useQuery({
    queryKey: ['firebase'],
    queryFn: () => initRemoteConfig()
  });
  const maintenanceScreen = getNoddiRemoteConfig('maintenanceScreen');

  if (isRemoteConfigLoading) {
    return <ScreenLoader />;
  }

  if (maintenanceScreen?.shouldShow) {
    return <MaintenanceScreen maintenanceScreen={maintenanceScreen} />;
  }

  return <Router />;
};

const Router = () => {
  const { isTokenLoadingOnMount } = useAuthContext();
  const { setCurrentLanguage } = useLanguageContext();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const languageFromUrl = searchParams.get('lang');

  useEffect(() => {
    if (languageFromUrl) {
      setCurrentLanguage(languageFromUrl as LanguageCodeType);
      if (searchParams.has('lang')) {
        searchParams.delete('lang');
        setSearchParams(searchParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  if (isTokenLoadingOnMount) {
    return <ScreenLoader />;
  }

  return (
    <SentryRoutes>
      <Route path='/' element={<RouterLayout showChat />}>
        <Route path={routes.campaignsInfo.getBasePath()} element={<Coupons />} />
        <Route path={routes.referralsInfo.getBasePath()} element={<Referrals />} />
        <Route path={routes.paymentInfo.getBasePath()} element={<PaymentInfo />} />
        <Route path={routes.membership.getBasePath()} element={<Membership />} />
        <Route path={routes.tireOffer.getBasePath()} element={<TireOfferRouter />} />
        <Route path={routes.login.getBasePath()} element={<Login />} />
        <Route path='' element={<Navigate replace to={routes.homepage.getBasePath()} />} />
        <Route path={routes.bookingInfo.getBasePath()} element={<ActiveBookingInfo />} />
      </Route>
      <Route path='/' element={<RouterLayout />}>
        <Route path={routes.newBooking.getBasePath()} element={<BookingRouter />} />
      </Route>

      <Route path='/' element={<HomeLayout />}>
        <Route path={routes.confirmation.getBasePath()} element={<Confirmation />} />
        <Route path={routes.homepage.getBasePath()} element={<Home />} />
        <Route path={routes.home.getBasePath()} element={<HomeRouter />} />
      </Route>

      <Route path={routes.companySignup.getBasePath()} element={<CompanySignup />} />
      <Route path={routes.companySignupSuccess.getBasePath()} element={<CompanySignupSuccess />} />
      <Route path='*' element={<Error404PageWithTranslations />} />
    </SentryRoutes>
  );
};
