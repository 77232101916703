import { Trans } from '@lingui/macro';
import { cn, NoddiButton } from 'noddi-ui';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

import { NoddiAsyncError } from 'noddi-async/src/types';
import { ErrorPageWithTranslations } from '../ErrorPageWithTrans';
import NewBookingButton from '../NewBookingButton/NewBookingButton';

interface HomeContentWrapperProps {
  title?: string;
  subtitle?: string;
  showNewBookingButton?: boolean;
  widerScreen?: boolean;
  className?: string;
  hideBackButton?: boolean;
  error?: NoddiAsyncError | null;
  backButtonPath?: string;
}

const ContentWrapper = ({
  children,
  title,
  showNewBookingButton,
  subtitle,
  widerScreen,
  className,
  hideBackButton,
  backButtonPath,
  error
}: PropsWithChildren<HomeContentWrapperProps>) => {
  const navigate = useNavigate();

  const hasContent = !!title || !!subtitle || showNewBookingButton;
  return (
    <div
      className={cn(
        'mx-auto mt-8 flex max-w-screen-mdx flex-col px-6 pb-6 sm:px-10 md:mt-14',
        widerScreen && 'xl:max-w-screen-lg xl:px-6',
        className
      )}
    >
      {hasContent && (
        <div className='mb-4 flex flex-col gap-2'>
          <div>
            {!hideBackButton && (
              <div>
                <NoddiButton
                  startIcon='ArrowLeft'
                  onClick={() => {
                    backButtonPath ? navigate(backButtonPath) : navigate(-1);
                  }}
                  variant='ghost'
                  className='-ml-4'
                >
                  <Trans>Back</Trans>
                </NoddiButton>
              </div>
            )}
            <div className='flex items-center justify-between'>
              {title && <h1 className='font-bold text-8 text-primary-darkPurple md:text-11'>{title}</h1>}
              <div className='hidden md:block'>{showNewBookingButton && <NewBookingButton defaultSize />} </div>
            </div>
          </div>
          {subtitle && <p className='text-primary-darkPurple'>{subtitle}</p>}
          <div className='block md:hidden'>{showNewBookingButton && <NewBookingButton />} </div>
        </div>
      )}
      {error ? <ErrorPageWithTranslations apiError={error} /> : children}
    </div>
  );
};

export default ContentWrapper;
