import { Box } from '@mui/system';
import { PropsWithChildren } from 'react';

type FilterSpacerProps = {
  showTopBorder?: boolean;
};
export const FilterSpacer = ({ children }: PropsWithChildren<FilterSpacerProps>) => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: 1.5
      }}
    >
      {children}
    </Box>
  );
};
