import { Path, Svg, SvgProps } from 'react-native-svg';

const Car = ({ color, ...props }: SvgProps) => (
  <Svg width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <Path
      d='M19 17H21C21.6 17 22 16.6 22 16V13C22 12.1 21.3 11.3 20.5 11.1C18.7 10.6 16 10 16 10C16 10 14.7 8.6 13.8 7.7C13.3 7.3 12.7 7 12 7H5C4.4 7 3.9 7.4 3.6 7.9L2.2 10.8C2.06758 11.1862 2 11.5917 2 12V16C2 16.6 2.4 17 3 17H5'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path
      d='M7 19C8.10457 19 9 18.1046 9 17C9 15.8954 8.10457 15 7 15C5.89543 15 5 15.8954 5 17C5 18.1046 5.89543 19 7 19Z'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <Path d='M9 17H15' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
    <Path
      d='M17 19C18.1046 19 19 18.1046 19 17C19 15.8954 18.1046 15 17 15C15.8954 15 15 15.8954 15 17C15 18.1046 15.8954 19 17 19Z'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Svg>
);

export { Car };
