import { useLocation, useNavigate } from 'react-router-dom';

import { BOOKING_TYPES } from 'noddi-async/src/types';
import { useBookingType } from '../BookingStore';
import {
  addonsStepPath,
  addressStepPath,
  carStepPath,
  salesItemStepPath,
  summaryStepPath,
  tierHotelCancelStepPath,
  tierHotelPickupStepPath,
  timeWindowStepPath
} from './bookingFlowPaths';
import { navigateAndLog } from './navigateAndLog';
import { useIsStepValid } from './useStepCompletion';

export const useNavigateOnInvalidStep = () => {
  const navigate = useNavigate();
  const bookingType = useBookingType();

  const { pathname: activeStep } = useLocation();

  const {
    isAddressStepValid,
    isCarStepValid,
    isSalesItemStepValid,
    isTimeWindowsStepValid,
    isTierHotelPickUpStepValid
  } = useIsStepValid();
  /**
   * This function is used to validate the current step in a multi-step process.
   * It checks the active step and performs validation accordingly. If the validation fails, the function logs a validation message and navigates to the failed step.
   **/

  const isCancelBooking = bookingType === BOOKING_TYPES.CANCEL_TIER_HOTEL;

  const navigateOnInvalidStep = () => {
    // TODO | New booking flow, Because we cleanup store on summary page, we cant validate here?
    if (activeStep === addressStepPath || activeStep === summaryStepPath) {
      return;
    }

    if (!isAddressStepValid) {
      navigateAndLog(activeStep, addressStepPath, navigate);
      return;
    }

    if (activeStep === carStepPath) {
      return;
    }

    if (!isCarStepValid) {
      navigateAndLog(activeStep, carStepPath, navigate);
      return;
    }

    if (isCancelBooking ? activeStep === tierHotelCancelStepPath : activeStep === salesItemStepPath) {
      return;
    }

    if (!isSalesItemStepValid) {
      navigateAndLog(activeStep, isCancelBooking ? tierHotelCancelStepPath : salesItemStepPath, navigate);
      return;
    }

    // Addons step is optional
    if (activeStep === addonsStepPath || activeStep === tierHotelPickupStepPath) {
      return;
    }

    // This is also safe for the case when no TH items are selected, as the function will return true when no TH-pickUp items are selected
    if (!isTierHotelPickUpStepValid) {
      navigateAndLog(activeStep, tierHotelPickupStepPath, navigate);
      return;
    }

    if (activeStep === timeWindowStepPath) {
      return;
    }

    if (!isTimeWindowsStepValid) {
      navigateAndLog(activeStep, timeWindowStepPath, navigate);
    }
  };

  return { navigateOnInvalidStep };
};
