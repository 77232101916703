import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { CouponCard, LoadingScreen, NoddiBasicCard } from 'noddi-ui';
import { useParams } from 'react-router-dom';

import getCommonTranslations from '../../commonTranslations';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import { BenefitsLogin } from '../../components/BenefitsLogin';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import NewBookingButton from '../../components/NewBookingButton/NewBookingButton';
import { getImageSrc } from '../../utils/resolveCompanyLogo';
import ActivateCampaign from './ActivateCampaign';

const Coupons = () => {
  const data = useParams<{ slug: string }>();
  const { isLoggedIn, canActivateCouponsForNewUsersOnly } = useAuthContext();
  const safeSlug = data.slug?.toLocaleLowerCase();

  const {
    data: campaignData,
    isPending: isCampaignPending,
    error: campaignError
  } = noddiAsync.useGet({ type: URLKeys.getCampaignFromSlug, input: { slug: safeSlug } });

  if (isCampaignPending) {
    return <LoadingScreen />;
  }

  if (campaignError) {
    return <ApiErrorMessageWithTrans error={campaignError} />;
  }

  return (
    <ContentWrapper hideBackButton title={`${t`Hurray`}🥳`}>
      <div className='text-primary-darkPurple'>
        {isLoggedIn && campaignData.isForNewUserOnly && !canActivateCouponsForNewUsersOnly ? (
          <NoddiBasicCard className='flex flex-col gap-4'>
            <p className='text-5'>
              <Trans>Your user cannot activate coupons from this campaign</Trans> 😭
            </p>
            <p>
              <Trans>This promotion is only for new users without existing coupons</Trans>
            </p>
            <NewBookingButton className='w-full' />
          </NoddiBasicCard>
        ) : (
          <div className='flex flex-col gap-4'>
            {getImageSrc(safeSlug) && <img src={getImageSrc(safeSlug)} alt='Campaign logo' />}

            {!isLoggedIn && <BenefitsLogin />}

            {isLoggedIn && <ActivateCampaign campaign={campaignData} />}
            {campaignData.couponGroup && (
              <>
                {campaignData.couponGroup.couponTemplates.map((couponTemplate) => (
                  <CouponCard
                    key={couponTemplate.id}
                    namePublic={couponTemplate.namePublic}
                    descriptionPublic={couponTemplate.descriptionPublic}
                    discount={couponTemplate.discount?.amount}
                    currency={couponTemplate.discount?.currency}
                    discountPercentage={couponTemplate.discountPercentage}
                    containerSx={{ marginTop: '22px' }}
                    translations={{
                      activated: getCommonTranslations().activated,
                      used: getCommonTranslations().used,
                      amount: getCommonTranslations().amount,
                      discount: getCommonTranslations().discount
                    }}
                  />
                ))}
              </>
            )}
          </div>
        )}
      </div>
    </ContentWrapper>
  );
};

export default Coupons;
