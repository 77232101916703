import { Path, Svg, SvgProps } from 'react-native-svg';

const CalendarFilled = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      fill={color}
      d='M10.75 5.5a.75.75 0 0 0-1.5 0v1.58c-1.44.115-2.384.397-3.078 1.092-.695.694-.977 1.639-1.093 3.078h19.842c-.116-1.44-.398-2.384-1.093-3.078-.694-.695-1.639-.977-3.078-1.093V5.5a.75.75 0 0 0-1.5 0v1.513C18.585 7 17.839 7 17 7h-4c-.839 0-1.585 0-2.25.013V5.5Z'
    />
    <Path
      fill={color}
      fillRule='evenodd'
      d='M5 15c0-.839 0-1.585.013-2.25h19.974C25 13.415 25 14.161 25 15v2c0 3.771 0 5.657-1.172 6.828C22.657 25 20.771 25 17 25h-4c-3.771 0-5.657 0-6.828-1.172C5 22.657 5 20.771 5 17v-2Zm15 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-4-5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-6-3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z'
      clipRule='evenodd'
    />
  </Svg>
);
export { CalendarFilled };
