import { Money } from '../../adminapp';
import { AddressOutput } from '../address';
import { CarSize } from '../cars';
import { ServiceCategory } from '../serviceCategory';

export interface SalesItemListItem {
  id: number;
  name: string;
  price: number;
  currency: string;
  carSizes: CarSize[];
}

type CarIdInput = {
  serviceAreaIds: number[];
  carId: number;
};

type CarSizeIdInput = {
  serviceAreaIds: number[];
  carSizeId: number | undefined;
  licensePlateNumber: string;
};

export type AvailableSalesItemsForBookingInput = {
  carSizeId: number | undefined;
  latitude: number;
  longitude: number;
  licensePlateNumber: string;
};

export type AvailableSalesItemsForBooking = {
  salesItems: AvailableSalesItem[];
  meta: { hasActiveTireHotelContract: boolean };
};

export type SalesItemV2 = {
  salesItemId: number;
  name: string;
  slug: string;
  description: string;
  shortDescription: string;
  price: Money;
  uiSortOrder: number;
  bookingCategorySlug: string | null;
  mutuallyExclusiveSalesItemIds: number[];
  isWheelPickupRequired: boolean;
  isAddonsAvailable: boolean;
};

export type TierHotelPickupSalesItemOption = {
  requiresPickupAddressSelection: boolean;
} & SalesItemV2;

export type SalesItemsCar = {
  licensePlate: LicensePlateInfo;
  salesItems: SalesItemV2[];
};

export type AvailableSalesItemsForBookingV2 = {
  cars: SalesItemsCar[];
};

export interface ConflictingSalesItemV2 {
  salesItemId: number;
  name: string;
}

export interface UnavailableSalesItemV2 {
  salesItemId: number;
  conflictingSelectedSalesItems: ConflictingSalesItemV2[];
}

export enum BOOKING_TYPES {
  NORMAL = 'NORMAL',
  CANCEL_TIER_HOTEL = 'CANCEL_TIER_HOTEL'
}

export enum SALES_ITEM_TYPE {
  PRIMARY = 'PRIMARY',
  ADDON = 'ADDON',
  TIRE_HOTEL_PICKUP = 'TIRE_HOTEL_PICKUP',
  TIRE_HOTEL_CANCEL = 'TIRE_HOTEL_CANCEL'
}

export type LicensePlateInfo = {
  number: string;
  countryCode: string;
};

export interface ShoppingCartSalesItem {
  salesItemId: number;
  name: string;
  price: Money;
}

export interface ShoppingCartMembershipDiscount {
  membershipDiscountId: number;
  salesItemId: number;
  name: string;
  discount: Money;
}

export interface ShoppingCartCar {
  licensePlate: LicensePlateInfo;
  salesItems: ShoppingCartSalesItem[];
}

export interface ShoppingCartCouponDiscount {
  couponId: number;
  name: string;
  discount: Money;
}

export type ShoppingCart = {
  cars: ShoppingCartCar[];
  couponDiscounts: ShoppingCartCouponDiscount[];
  membershipDiscounts: ShoppingCartMembershipDiscount[];
  totalPrice: Money;
  deliveryFee: Money | null;
};

export type SelectedSalesItemsCarGrouped = {
  licensePlate: LicensePlateInfo;
  selectedSalesItemIds: number[];
};

export type SelectedSalesItemsCar = {
  licensePlate: LicensePlateInfo;
  salesItemId: number;
  type: SALES_ITEM_TYPE;
  metaData?: SalesItemMetaData;
  isWheelPickupRequired: boolean;
  isAddonsAvailable: boolean;
};

export type SalesItemMetaData = {
  selectedTierHotelPickUpAddress: AddressOutput;
};

export type GetSalesItemForBooking =
  | CarIdInput
  | CarSizeIdInput
  | {
      userGroupIds?: number[];
    };

export interface AvailableSalesItem {
  id: number;
  name: string;
  description: string;
  shortDescription: string;
  price: number;
  serviceTime: number;
  salesItemType: string;
  slug: string;
  serviceCategory: ServiceCategory;
  serviceCategoryId: number;
  incompatibleIds: number[];
  addonIds: number[];
  uiSortOrder: number;
  bookingCategorySlug: string;
}

export type SalesItemOption = {
  id: number;
  name: string;
};
