import { Route, Routes } from 'react-router-dom';

import { useEffect } from 'react';
import routes from '../../appRoutes';
import { MaintenanceContent } from '../../components/SystemDownTimeBlocker';
import { getNoddiRemoteConfig } from '../../utils/remoteConfigSetup';
import { Addons } from './Steps/Addons';
import { Address } from './Steps/Address';
import { Cars } from './Steps/Car';
import { ServiceItemsForCars } from './Steps/Services';
import { Summary } from './Steps/Summary';
import { TierHotelPickup } from './Steps/TierHotelPickup';
import { TimeWindow } from './Steps/TimeWindows';
import { CancelTireHotel } from './Steps/TireHotelCancel';
import { useNavigateOnInvalidStep } from './helpers/useNavigateOnInvalidStep';

export function BookingRouter() {
  const { navigateOnInvalidStep } = useNavigateOnInvalidStep();
  const maintenanceScreenBooking = getNoddiRemoteConfig('maintenanceScreenBooking');

  useEffect(() => {
    navigateOnInvalidStep();
  }, [navigateOnInvalidStep]);

  if (maintenanceScreenBooking?.shouldShow) {
    return <MaintenanceContent maintenanceScreen={maintenanceScreenBooking} />;
  }

  return (
    <Routes>
      <Route path={routes.newBookingAddress.getBasePath()} element={<Address />} />
      <Route path={routes.newBookingCars.getBasePath()} element={<Cars />} />
      <Route path={routes.newBookingPrimaryItems.getBasePath()} element={<ServiceItemsForCars />} />
      <Route path={routes.newBookingCancelTireHotel.getBasePath()} element={<CancelTireHotel />} />
      <Route path={routes.newBookingAddons.getBasePath()} element={<Addons />} />
      <Route path={routes.newBookingTierHotel.getBasePath()} element={<TierHotelPickup />} />
      <Route path={routes.newBookingTimeWindow.getBasePath()} element={<TimeWindow />} />
      <Route path={routes.newBookingSummary.getBasePath()} element={<Summary />} />
    </Routes>
  );
}
