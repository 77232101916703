import { View } from 'react-native';
import { IconName, IconSize, iconMap, sizeMap } from '../types/icons';

interface NoddiIconProps extends Pick<React.SVGProps<SVGSVGElement>, 'color' | 'className' | 'onClick'> {
  name: IconName;
  size?: IconSize;
}

export const NoddiIcon = ({ name, size = 'medium', color, className }: NoddiIconProps) => {
  const IconComponent = iconMap[name];
  const iconSize = sizeMap[size];

  return (
    <View>
      <IconComponent width={iconSize} height={iconSize} color={color ?? '#1C274C'} className={className} />
    </View>
  );
};
