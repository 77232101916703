import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';

import { SALES_ITEM_TYPE } from 'noddi-async/src/types';
import { LoadingScreen } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingAddress, useBookingSelectedSalesItemsCars, useIsTierHotelPickupRequired } from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { tierHotelPickupStepPath, timeWindowStepPath } from '../../helpers/bookingFlowPaths';
import { groupCars } from '../../helpers/utils';
import { CarWithAddons } from './CarWithAddons';

export const Addons = () => {
  const navigate = useNavigate();

  const isTierHotelPickupRequired = useIsTierHotelPickupRequired();

  const navigateToNextStep = () => {
    if (isTierHotelPickupRequired) {
      return navigate(tierHotelPickupStepPath);
    }

    return navigate(timeWindowStepPath);
  };

  return (
    <BookingScreen onNextClicked={navigateToNextStep} title={t`Do you need help with anything else?`}>
      <AddonsSelector />
    </BookingScreen>
  );
};

const AddonsSelector = () => {
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const selectedAddress = useBookingAddress();

  const {
    data: carsWithAddons,
    isPending: isCarsWithAddonsPending,
    error: carsWithAddonsError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.postFetchAddons,
    input: {
      addressId: selectedAddress?.id,
      // Which addons are available are only dependant on primary sales items
      cars: groupCars(selectedSalesItemsCars.filter((car) => car.type === SALES_ITEM_TYPE.PRIMARY))
    },
    queryConfig: {
      enabled: !!selectedAddress,
      staleTime: Infinity
    }
  });

  if (isCarsWithAddonsPending) {
    return <LoadingScreen />;
  }
  if (carsWithAddonsError) {
    return <ErrorPageWithTranslations apiError={carsWithAddonsError} />;
  }

  // move all cars without addons to the end of the list
  const sortedCarsWithAddons = carsWithAddons.data.cars.toSorted(
    (a, b) => Number(a.salesItems.length === 0) - Number(b.salesItems.length === 0)
  );

  return (
    <div className='flex flex-col gap-8'>
      {sortedCarsWithAddons.map((carWithAddons) => {
        return <CarWithAddons key={carWithAddons.licensePlate.number} carWithAddons={carWithAddons} />;
      })}
    </div>
  );
};
