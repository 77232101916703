import * as _ReactQuery from '@tanstack/react-query';
import { UseQueryOptions } from '@tanstack/react-query';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { InputData, OutputData, URLKeysTypes, getUrls } from './resources/resources';
import { NoddiAsyncError } from './types/shared/error';
import { convertObjectKeysToCamelCase, convertObjectKeysToSnakeCase } from './utils';

type ReactQuery = typeof _ReactQuery;

class NoddiAsync {
  // @ts-expect-error no initializer
  public queryClient: _ReactQuery.QueryClient;
  // @ts-expect-error no initializer
  private reactQuery: ReactQuery;
  // @ts-expect-error no initializer
  private baseUrl: string;
  // @ts-expect-error no initializer
  private authToken: string;
  // @ts-expect-error no initializer
  private impersonatedAuthToken: string;
  // @ts-expect-error no initializer
  private axiosInstance: AxiosInstance;

  init({
    reactQuery,
    baseUrl,
    refetchOnWindowFocus = false,
    queryCacheConfig,
    mutationCacheConfig
  }: {
    reactQuery: ReactQuery;
    baseUrl: string;
    refetchOnWindowFocus?: boolean;
    queryCacheConfig?: _ReactQuery.QueryCache['config'];
    mutationCacheConfig?: _ReactQuery.MutationCache['config'];
  }) {
    this.queryClient = new reactQuery.QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus,
          retry: false
        }
      },

      queryCache: queryCacheConfig ? new reactQuery.QueryCache(queryCacheConfig) : undefined,
      mutationCache: mutationCacheConfig ? new reactQuery.MutationCache(mutationCacheConfig) : undefined
    });
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false
    });
    this.reactQuery = reactQuery;
    this.baseUrl = baseUrl;
  }

  getReactQuery() {
    return this.reactQuery;
  }

  getAxiosInstance() {
    return this.axiosInstance;
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public getBaseUrl() {
    return this.baseUrl;
  }

  public setAuthToken(authToken: string) {
    this.authToken = authToken;
  }

  public getAuthToken() {
    return this.authToken;
  }

  public setImpersonatedAuthToken(impersonatedAuthToken: string) {
    this.impersonatedAuthToken = impersonatedAuthToken;
  }

  public getImpersonatedAuthToken() {
    return this.impersonatedAuthToken;
  }

  public getAuthHeaders({ useSuperUserToken = false }: { useSuperUserToken?: boolean }) {
    let token = this.getAuthToken();
    const impersonatedToken = this.getImpersonatedAuthToken();

    if (impersonatedToken) {
      token = impersonatedToken;
    }

    if (useSuperUserToken) {
      token = this.getAuthToken();
    }

    return token ? { Authorization: `Token ${token}` } : {};
  }

  NoddiServerContext = ({ children }: { children: React.ReactNode }) => (
    <this.reactQuery.QueryClientProvider client={this.queryClient}>{children}</this.reactQuery.QueryClientProvider>
  );

  useGet<UrlKey extends URLKeysTypes, Metadata extends InputData<UrlKey>, SelectedData = ReturnType<UrlKey, Metadata>>({
    type,
    queryConfig = {},
    input
  }: GetProps<UrlKey, Metadata, SelectedData>) {
    const data = this.reactQuery.useQuery<ReturnType<UrlKey, Metadata>, NoddiAsyncError, SelectedData>({
      queryKey: input ? [type, input] : [type],
      queryFn: () => {
        const {
          getUrl,
          getHeaders,
          useSuperUserToken,
          skipConvertingToCamelCase,
          handleRes,
          responseType = 'json'
        } = getUrls[type];

        const authHeaders = this.getAuthHeaders({ useSuperUserToken });

        const url = getUrl(input as InputDataOrVoid<UrlKey>);
        const headers = getHeaders?.(input as InputDataOrVoid<UrlKey>) || {};

        return this.axiosInstance
          .get<OutputData<UrlKey>>(url, { ...headers, headers: authHeaders, responseType })
          .then((res) => {
            if (handleRes) {
              return handleRes(res);
            }
            return convertToCorrectFrontendFormat({ res, skipConvertingToCamelCase });
          }) as Promise<ReturnType<UrlKey, Metadata>>;
      },
      ...queryConfig
    });

    return data;
  }

  /**
   * useGetAll is a custom hook that wraps the useQueries function from react-query.
   * It allows you to perform multiple GET requests concurrently.
   *
   * now the types only support one type of query, however, the code actually supports different kinds of queries
   *
   * @template UrlKey - A type that extends URLKeysTypes. It represents the key for the URL of the request.
   * @template Metadata - A type that extends InputData<UrlKey>. It represents the metadata for the request.
   * @template SelectedData - The type of the data returned by the request. By default, it's ReturnType<UrlKey, Metadata>.
   *
   * @param {Array<GetProps<UrlKey, Metadata, SelectedData>>} queriesInputs - An array of objects, where each object represents a query.
   * Each object must have a 'type' property (which corresponds to the UrlKey), and can optionally have 'queryConfig' and 'input' properties.
   * 'queryConfig' is an object of additional configuration options for the query.
   * 'input' is the metadata for the request.
   *
   *
   * @returns {Array<UseQueryResult>} An array of query results. Each result is an object with properties like 'isLoading', 'isError', 'data', and 'error'.
   */
  useGetAll<
    UrlKey extends URLKeysTypes,
    Metadata extends InputData<UrlKey>,
    SelectedData = ReturnType<UrlKey, Metadata>
  >(queriesInputs: Array<GetProps<UrlKey, Metadata, SelectedData>>) {
    const queries = queriesInputs.map(({ type, queryConfig = {}, input }) => {
      const { getUrl, getHeaders, useSuperUserToken, skipConvertingToCamelCase } = getUrls[type];
      const authHeaders = this.getAuthHeaders({ useSuperUserToken });
      const url = getUrl(input as InputDataOrVoid<UrlKey>);
      const headers = getHeaders?.(input as InputDataOrVoid<UrlKey>) || {};

      return {
        queryKey: input ? [type, input] : [type],
        queryFn: () =>
          this.axiosInstance
            .get<OutputData<UrlKey>>(url, { ...headers, headers: authHeaders })
            .then((res) => convertToCorrectFrontendFormat({ res, skipConvertingToCamelCase })) as Promise<
            ReturnType<UrlKey, Metadata>
          >,
        ...queryConfig
      };
    });

    return this.reactQuery.useQueries({ queries });
  }

  usePost<Type extends URLKeysTypes>({ type, queryConfig }: PostProps<Type>): ReturnTypeMutation<Type> {
    if (!queryConfig) {
      queryConfig = {};
    }
    return this.reactQuery.useMutation({
      mutationFn: (input) => {
        const {
          bodyWithSnakeCaseFormat,
          url,
          responseType,
          handleRes,
          getHeaders,
          body,
          skipBodyTransformation,
          useSuperUserToken,
          skipConvertingToCamelCase
        } = prepareMutation({
          type,
          input
        });
        const _responseType = responseType ? { responseType } : undefined;
        const authHeaders = this.getAuthHeaders({ useSuperUserToken });
        const headers = getHeaders?.(input) || {};

        return this.axiosInstance
          .post<OutputData<Type>>(url, skipBodyTransformation ? body : bodyWithSnakeCaseFormat, {
            ..._responseType,
            headers: { ...authHeaders, ...headers }
          })
          .then((res) => {
            if (handleRes) {
              return handleRes(res);
            }

            //@ts-expect-error
            return skipConvertingToCamelCase ? res : convertObjectKeysToCamelCase(res);
          }) as Promise<OutputData<Type>>;
      },
      ...queryConfig
    });
  }
  // This allows us to use a POST request as a GET request
  // Useful in cases we want to fetch data with complex params, like [{ carId: 1 , salesItemIds : [1,2,3] }, { carId: 2 , salesItemIds : [3] }]
  // Then we put the input in the body of the request, instead of the query params
  usePostAsGetQuery<
    UrlKey extends URLKeysTypes,
    Metadata extends InputData<UrlKey>,
    SelectedData = ReturnType<UrlKey, Metadata>
  >({ type, queryConfig = {}, input }: GetProps<UrlKey, Metadata, SelectedData>) {
    const data = noddiAsync.getReactQuery().useQuery<ReturnType<UrlKey, Metadata>, NoddiAsyncError, SelectedData>({
      queryKey: input ? [type, input] : [type],
      queryFn: () => {
        const {
          bodyWithSnakeCaseFormat,
          url,
          responseType,
          getHeaders,
          body,
          skipBodyTransformation,
          useSuperUserToken,
          skipConvertingToCamelCase
        } = prepareMutation({
          type,
          input
        });

        const _responseType = responseType ? { responseType } : undefined;
        const authHeaders = noddiAsync.getAuthHeaders({ useSuperUserToken });
        const headers = getHeaders?.(input) || {};

        return noddiAsync
          .getAxiosInstance()
          .post<OutputData<UrlKey>>(url, skipBodyTransformation ? body : bodyWithSnakeCaseFormat, {
            ..._responseType,
            headers: { ...authHeaders, ...headers }
          })
          .then((res) => {
            //@ts-expect-error
            return skipConvertingToCamelCase ? res : convertObjectKeysToCamelCase(res);
          }) as Promise<OutputData<UrlKey>>;
      },
      ...queryConfig
    });

    return data;
  }

  usePatch<Type extends URLKeysTypes>({ type, queryConfig }: PostProps<Type>): ReturnTypeMutation<Type> {
    if (!queryConfig) {
      queryConfig = {};
    }

    const data = this.reactQuery.useMutation({
      mutationFn: (input) => {
        const {
          skipBodyTransformation,
          bodyWithSnakeCaseFormat,
          url,
          useSuperUserToken,
          body,
          skipConvertingToCamelCase,
          getHeaders
        } = prepareMutation({
          type,
          input
        });
        const authHeaders = this.getAuthHeaders({ useSuperUserToken });
        const headers = getHeaders?.(input) || {};

        return (
          this.axiosInstance
            .patch<OutputData<Type>>(url, skipBodyTransformation ? body : bodyWithSnakeCaseFormat, {
              headers: { ...authHeaders, ...headers }
            })
            //@ts-expect-error
            .then((res) => (skipConvertingToCamelCase ? res : convertObjectKeysToCamelCase(res))) as Promise<
            OutputData<Type>
          >
        );
      },
      ...queryConfig
    });

    return data;
  }

  usePut<Type extends URLKeysTypes>({ type, queryConfig }: PostProps<Type>): ReturnTypeMutation<Type> {
    if (!queryConfig) {
      queryConfig = {};
    }

    const data = this.reactQuery.useMutation({
      mutationFn: (input) => {
        const { bodyWithSnakeCaseFormat, url, useSuperUserToken, skipConvertingToCamelCase } = prepareMutation({
          type,
          input
        });
        const authHeaders = this.getAuthHeaders({ useSuperUserToken });

        return (
          this.axiosInstance
            .put<OutputData<Type>>(url, bodyWithSnakeCaseFormat, { headers: authHeaders })
            //@ts-expect-error
            .then((res) => (skipConvertingToCamelCase ? res : convertObjectKeysToCamelCase(res))) as Promise<
            OutputData<Type>
          >
        );
      },
      ...queryConfig
    });

    return data;
  }

  useDelete<Type extends URLKeysTypes>({ type, queryConfig }: PostProps<Type>): ReturnTypeMutation<Type> {
    if (!queryConfig) {
      queryConfig = {};
    }

    const data = this.reactQuery.useMutation({
      mutationFn: (input) => {
        const { url, useSuperUserToken, skipConvertingToCamelCase } = prepareMutation({ type, input });
        const authHeaders = this.getAuthHeaders({ useSuperUserToken });

        return (
          this.axiosInstance
            .delete<OutputData<Type>>(url, { headers: authHeaders })
            //@ts-expect-error
            .then((res) => (skipConvertingToCamelCase ? res : convertObjectKeysToCamelCase(res))) as Promise<
            OutputData<Type>
          >
        );
      },
      ...queryConfig
    });

    return data;
  }
}

function prepareMutation<Type extends URLKeysTypes>({ type, input }: { type: Type; input: InputDataOrVoid<Type> }) {
  const {
    getBody,
    getUrl,
    responseType,
    handleRes,
    getHeaders,
    useSuperUserToken,
    skipConvertingToCamelCase,
    skipBodyTransformation
  } = getUrls[type];
  const body = getBody?.(input) || {};
  const bodyWithSnakeCaseFormat = convertBodyToCorrectFormat({ body });
  const url = getUrl(input);

  return {
    bodyWithSnakeCaseFormat,
    url,
    responseType,
    handleRes,
    getHeaders,
    useSuperUserToken,
    body,
    skipConvertingToCamelCase,
    skipBodyTransformation
  };
}

function convertToCorrectFrontendFormat({
  res,
  skipConvertingToCamelCase
}: {
  res: AxiosResponse;
  skipConvertingToCamelCase?: boolean;
}) {
  // 204 indicates no content from server
  if (res.status === 204) {
    return null;
  }

  return skipConvertingToCamelCase ? res.data : convertObjectKeysToCamelCase(res.data);
}

type Body = Record<string | number, unknown> | Record<string | number, unknown>[];

function convertBodyToCorrectFormat({ body }: { body: Body }) {
  return convertObjectKeysToSnakeCase(body) as AxiosRequestConfig<Body>;
}

interface PostProps<Type extends URLKeysTypes> {
  type: Type;
  queryConfig?: _ReactQuery.UseMutationOptions<OutputData<Type>, NoddiAsyncError, InputDataOrVoid<Type>, unknown>;
}

type ReturnTypeMutation<Type extends URLKeysTypes> = _ReactQuery.UseMutationResult<
  OutputData<Type>,
  NoddiAsyncError,
  InputDataOrVoid<Type>,
  unknown
>;

interface GetProps<Type extends URLKeysTypes, QueryParams, SelectedData> {
  type: Type;
  queryConfig?: Omit<UseQueryOptions<ReturnType<Type, QueryParams>, NoddiAsyncError, SelectedData>, 'queryKey'>;
  input?: InputDataOrVoid<Type>;
}

export type InputDataOrVoid<Type extends URLKeysTypes> = InputData<Type> extends void ? void : InputData<Type>;

export type ReturnType<Type extends URLKeysTypes, _> = OutputData<Type>;

const noddiAsync = new NoddiAsync();
export default noddiAsync;
