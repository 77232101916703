import { useState } from 'react';

interface Params {
  initialPage?: number;
  initialPageSize?: number;
  initialSearch?: string;
}

export function usePagination(params?: Params) {
  const { initialPage = 1, initialPageSize = 10, initialSearch = '' } = params ?? {};

  const [page, setPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [search, setSearch] = useState(initialSearch);

  return { page, setPage, pageSize, setPageSize, search, setSearch };
}
