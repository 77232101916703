import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const Earth = (props: SvgProps) => (
  <Svg width={24} height={24} fill='none' viewBox='0 0 24 24' {...props}>
    <Circle cx={12} cy={12} r={8} stroke='#1F1F1F' strokeWidth={1.5} />
    <Path
      stroke='#1F1F1F'
      strokeWidth={1.5}
      d='M7.2 6.168c.624.568 1.91 2.122 2.06 3.79.14 1.558 1.169 2.825 2.74 2.842.604.006 1.215-.43 1.213-1.034 0-.187-.031-.378-.08-.553a1.13 1.13 0 0 1 .067-.813c.488-1.006 1.448-1.277 2.208-1.823.337-.242.645-.498.78-.704.375-.568.75-1.705.562-2.273M20 12.8c-.264.745-.45 2.7-3.426 2.73 0 0-2.635 0-3.425 1.49-.632 1.192-.263 2.483 0 2.98'
    />
  </Svg>
);
export { Earth };
