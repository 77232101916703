import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getAll, getRemoteConfig, isSupported, Value } from 'firebase/remote-config';
import { FeatureFlag } from './featureFlags';
import { RemoteConfigKey, setNoddiRemoteConfig } from './remoteConfigSetup';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_CONFIG_API_KEY,
  authDomain: 'noddi-web.firebaseapp.com',
  projectId: 'noddi-web',
  storageBucket: 'noddi-web.firebasestorage.app',
  messagingSenderId: '255988546586',
  appId: '1:255988546586:web:98497d30297716210fa5a3',
  measurementId: 'G-3JKLRXLKCN'
};

const environment = import.meta.env.MODE;

const app = initializeApp(firebaseConfig);

export async function initRemoteConfig(): Promise<boolean> {
  const isRemoteConfigSupported = await isSupported();
  if (!isRemoteConfigSupported || environment === 'development') {
    // Remote config is not supported in the current environment
    setRemoteConfigForNonSupportedEnvironment();
    return false;
  }

  const firebaseRemoteConfig = getRemoteConfig(app);
  firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 30000;

  const success = await fetchAndActivate(firebaseRemoteConfig);

  setNoddiRemoteConfig(getAll(firebaseRemoteConfig));
  return success;
}

function setRemoteConfigForNonSupportedEnvironment() {
  setNoddiRemoteConfig({
    maintenanceScreen: {
      asString: () => `{ "shouldShow": false }`,
      asBoolean: () => false,
      asNumber: () => 0,
      getSource: () => 'static'
    },
    maintenanceScreenBooking: {
      asString: () => `{ "shouldShow": false }`,
      asBoolean: () => false,
      asNumber: () => 0,
      getSource: () => 'static'
    },
    featureFlags: {
      asString: () => `{ "flags": { ${createFeatureFlagConfig('NEW_BOOKING_FLOW')} } }`,
      asBoolean: () => false,
      asNumber: () => 0,
      getSource: () => 'static'
    }
  } satisfies Partial<Record<RemoteConfigKey, Value>>);
}

function createFeatureFlagConfig(flag: FeatureFlag) {
  return `"${flag}": { "enabled": true }`;
}
