import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';

import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import {
  useBookingAddress,
  useBookingCars,
  useIsAddonsAvailable,
  useIsTierHotelPickupRequired
} from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { addonsStepPath, tierHotelPickupStepPath, timeWindowStepPath } from '../../helpers/bookingFlowPaths';
import { useIsStepValid } from '../../helpers/useStepCompletion';
import { ServiceItemsForCar } from './ServiceItemsForCar';
import { useGetUnavailableSalesItems } from './useGetUnavailableSalesItems';

export const ServiceItemsForCars = () => {
  const navigate = useNavigate();

  const { isSalesItemStepValid } = useIsStepValid();

  const { unavailableSalesItemsError } = useGetUnavailableSalesItems();

  const isAddonsAvailable = useIsAddonsAvailable();
  const isTierHotelPickupRequired = useIsTierHotelPickupRequired();

  const navigateToNextStep = () => {
    if (isAddonsAvailable) {
      return navigate(addonsStepPath);
    }
    if (isTierHotelPickupRequired) {
      return navigate(tierHotelPickupStepPath);
    }

    return navigate(timeWindowStepPath);
  };

  if (unavailableSalesItemsError) {
    return <ErrorPageWithTranslations apiError={unavailableSalesItemsError} />;
  }

  return (
    <BookingScreen
      onNextClicked={navigateToNextStep}
      disableNextButton={!isSalesItemStepValid}
      title={t`What can we help you with?`}
    >
      <SalesItemsSelector />
    </BookingScreen>
  );
};

const SalesItemsSelector = () => {
  const selectedAddress = useBookingAddress();
  const selectedCars = useBookingCars();

  const {
    data: carsWithSalesItems,
    isPending: isSalesItemsPending,
    error: salesItemsError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.getInitialAvailableSalesItems,
    input: {
      addressId: selectedAddress?.id,
      licensePlates: selectedCars.map((car) => ({
        number: car.licensePlate.number,
        countryCode: car.licensePlate.countryCode
      }))
    },
    queryConfig: { staleTime: Infinity, enabled: !!selectedAddress && !!selectedCars?.length }
  });

  if (isSalesItemsPending) {
    return (
      <div className='flex flex-col gap-8'>
        {selectedCars.map((car) => (
          <div key={car.licensePlate.number} className='flex animate-pulse flex-col gap-2'>
            <div className='h-4 rounded bg-primary-darkPurple/30' />
            <div className='h-20 rounded bg-primary-darkPurple/30' />
            <div className='h-20 rounded bg-primary-darkPurple/30' />
          </div>
        ))}
      </div>
    );
  }

  if (salesItemsError) {
    return <ErrorPageWithTranslations apiError={salesItemsError} />;
  }

  return (
    <div className='flex flex-col gap-8'>
      {carsWithSalesItems.data.cars.map((carWithSalesItems) => (
        <ServiceItemsForCar key={carWithSalesItems.licensePlate.number} salesItemsCar={carWithSalesItems} />
      ))}
    </div>
  );
};
