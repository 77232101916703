import { noddiAsync, URLKeys } from 'noddi-async';
import { SALES_ITEM_TYPE } from 'noddi-async/src/types';
import { useBookingAddress, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { groupCars } from '../../helpers/utils';

export const useGetUnavailableSalesItems = () => {
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const selectedAddress = useBookingAddress();
  const {
    data: unavailableSalesItems,
    isLoading: isUnavailableSalesItemsLoading,
    error: unavailableSalesItemsError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.getUnavailableSalesItems,
    input: {
      addressId: selectedAddress?.id,
      // Incompatibles are only relevant for primary sales items for now
      cars: groupCars(selectedSalesItemsCars.filter((car) => car.type === SALES_ITEM_TYPE.PRIMARY))
    },
    queryConfig: {
      staleTime: Infinity,
      enabled: !!selectedAddress && !!selectedSalesItemsCars.length
    }
  });

  return {
    unavailableSalesItems,
    isUnavailableSalesItemsLoading,
    unavailableSalesItemsError
  };
};
