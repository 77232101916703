import { Path, Svg, SvgProps } from 'react-native-svg';

const Bill = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeWidth={1.5}
      d='M22 25.6a1.806 1.806 0 0 1 2.4 0 .721.721 0 0 0 1.2-.539V6.94a.721.721 0 0 0-1.2-.54 1.806 1.806 0 0 1-2.4 0 1.806 1.806 0 0 0-2.4 0 1.806 1.806 0 0 1-2.4 0 1.806 1.806 0 0 0-2.4 0 1.806 1.806 0 0 1-2.4 0 1.806 1.806 0 0 0-2.4 0 1.806 1.806 0 0 1-2.4 0 .721.721 0 0 0-1.2.54V25.06c0 .622.735.952 1.2.539a1.806 1.806 0 0 1 2.4 0 1.806 1.806 0 0 0 2.4 0 1.806 1.806 0 0 1 2.4 0 1.806 1.806 0 0 0 2.4 0 1.806 1.806 0 0 1 2.4 0 1.806 1.806 0 0 0 2.4 0Z'
    />
    <Path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='M11.2 19.733h9.6M11.2 16h9.6M11.2 12.267h9.6' />
  </Svg>
);

export { Bill };
