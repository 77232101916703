import { t } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';
import { BookingScreen } from '../../components/BookingScreen';
import { salesItemStepPath } from '../../helpers/bookingFlowPaths';
import { useIsStepValid } from '../../helpers/useStepCompletion';

import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { NoddiLinearProgressLoader } from 'noddi-ui';

import { ApiErrorMessageWithTrans } from '../../../../components/ApiErrorMessageWithTrans';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import { SelectCars } from './SelectCars';

export const Cars = () => {
  const { isCarStepValid } = useIsStepValid();
  const navigate = useNavigate();

  const { currentUserGroupId: userGroupId } = useAuthContext();

  // need to use isLoading here, not isPending if not page doesn't work for not logged in users
  const {
    isLoading: isSavedCarsLoading,
    data: savedCars,
    error: savedCarsError
  } = noddiAsync.useGet({
    type: URLKeys.getPaginatedCarsForBookingUserGroups,
    input: { userGroupId: userGroupId as number, page: 1, pageSize: 1000 },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  function onNavigateToNextStep() {
    GoogleTrackingService.trackEvent(GtmEvents.addToCart);
    navigate(salesItemStepPath);
  }

  return (
    <BookingScreen
      onNextClicked={onNavigateToNextStep}
      disableNextButton={!isCarStepValid}
      title={t`Add one or more cars`}
      hideShoppingCart
      showSelectedCars
    >
      {isSavedCarsLoading ? (
        <NoddiLinearProgressLoader />
      ) : savedCarsError ? (
        <ApiErrorMessageWithTrans error={savedCarsError} />
      ) : (
        <SelectCars savedCars={savedCars?.results} />
      )}
    </BookingScreen>
  );
};
