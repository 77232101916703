import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const Phone = ({ color, ...props }: SvgProps) => (
  <Svg width='30' height='30' viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      d='M7 13C7 9.22876 7 7.34315 8.17157 6.17157C9.34315 5 11.2288 5 15 5C18.7712 5 20.6569 5 21.8284 6.17157C23 7.34315 23 9.22876 23 13V17C23 20.7712 23 22.6569 21.8284 23.8284C20.6569 25 18.7712 25 15 25C11.2288 25 9.34315 25 8.17157 23.8284C7 22.6569 7 20.7712 7 17V13Z'
      stroke={color}
      strokeWidth='1.5'
    />
    <Path d='M18 8H12' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
    <Circle cx='15' cy='20' r='2' stroke={color} strokeWidth='1.5' />
  </Svg>
);

export { Phone };
