import { t } from '@lingui/macro';
import { BOOKING_TYPES } from 'noddi-async/src/types';
import { useNavigate } from 'react-router-dom';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import { useBookingActions, useBookingAddress, useBookingType } from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { carStepPath, tierHotelCancelStepPath } from '../../helpers/bookingFlowPaths';
import { useIsStepValid } from '../../helpers/useStepCompletion';
import { AddressPicker } from './AddressPicker';

export const Address = () => {
  const { setSelectedAddress } = useBookingActions();
  const selectedAddress = useBookingAddress();
  const { isAddressStepValid } = useIsStepValid();
  const bookingType = useBookingType();

  const navigate = useNavigate();

  const isCancelTierHotelBooking = bookingType === BOOKING_TYPES.CANCEL_TIER_HOTEL;

  const navigateToNextStep = () => {
    GoogleTrackingService.trackEvent(GtmEvents.viewItem);

    isCancelTierHotelBooking ? navigate(tierHotelCancelStepPath) : navigate(carStepPath);
  };

  return (
    <BookingScreen
      title={t`Where do you need our help?`}
      onNextClicked={navigateToNextStep}
      disableNextButton={!isAddressStepValid}
      hideShoppingCart
      hideBackButton
    >
      <AddressPicker
        setSelectedAddress={setSelectedAddress}
        selectedAddress={selectedAddress}
        onSavedAddressClick={navigateToNextStep}
      />
    </BookingScreen>
  );
};
