import { Trans, t } from '@lingui/macro';
import { NoddiDialog } from 'noddi-ui';

interface TimeWindowModalProps {
  open: boolean;
  onClose: () => void;
}

export const TimeWindowModal = ({ open, onClose }: TimeWindowModalProps) => {
  return (
    <NoddiDialog title={t`Delivery`} onClose={onClose} open={open}>
      <div className='flex flex-col gap-4'>
        <Trans>
          We'll send you an SMS the day before your scheduled booking with an estimated time of arrival. This will be
          based on our finalized route for the technicians, giving you a better idea of when to expect us. Additionally,
          we'll send you another shortly before we arrive.
        </Trans>
        <div>
          <p className='font-bold'>
            <Trans>Price</Trans>
          </p>
          <Trans>
            Most of our time slots are free, but for shorter, more specific time slots, there is a small additional
            charge. This is due to the added limitations these time slots create for our scheduling.
          </Trans>
        </div>
      </div>
    </NoddiDialog>
  );
};
