import { cva, type VariantProps } from 'class-variance-authority';
import { ReactNode } from 'react';

import { colors } from '../../tailwind-design-preset';
import { IconName, NoddiIcon } from '../atoms';
import { cn } from '../helpers/utils';

const linkVariants = cva(
  'inline-flex size-fit min-w-fit items-center justify-center gap-1 text-nowrap font-medium underline-offset-4 ring-offset-systemColors-raspberryBg transition-colors hover:underline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-systemColors-grey focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        link: 'text-primary-purple  active:text-primary-purple/60',
        lightLink: 'text-text-secondary active:text-text-secondary/60'
      },
      size: {
        default: 'px-4 py-2.5',
        small: 'px-3.5 py-[4.5px]'
      }
    },
    defaultVariants: {
      variant: 'link',
      size: 'default'
    }
  }
);

const iconColorVariantsInNoddiButton = cva('', {
  variants: {
    variant: {
      link: colors.primary.purple,
      lightLink: colors.text.secondary
    }
  },
  defaultVariants: {
    variant: 'link'
  }
});

export type NoddiExternalLinkProps = VariantProps<typeof linkVariants> & {
  children: ReactNode;
  className?: string;
  href: string;
} & ({ startIcon?: IconName; endIcon?: never } | { endIcon?: IconName; startIcon?: never });

export const NoddiExternalLink = ({
  variant,
  size,
  endIcon,
  startIcon,
  children,
  className,
  href
}: NoddiExternalLinkProps) => {
  const iconSize = size === 'small' ? 'small' : 'medium';
  const iconColor = iconColorVariantsInNoddiButton({ variant });

  return (
    <a className={cn(linkVariants({ variant, size, className }))} href={href} target='_blank' rel='noopener noreferrer'>
      {startIcon && (
        <div>
          <NoddiIcon color={iconColor} name={startIcon} size={iconSize} />
        </div>
      )}
      <span>{children}</span>
      {endIcon && (
        <div>
          <NoddiIcon color={iconColor} name={endIcon} size={iconSize} />
        </div>
      )}
    </a>
  );
};
