import { GtmEvents } from '../../types/gtmTracking';

const trackEvent = (event: GtmEvents) => {
  if (import.meta.env.MODE === 'production') {
    return window.dataLayer.push({ event });
  }

  return;
};

export const GoogleTrackingService = {
  trackEvent
};
