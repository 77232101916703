import { Path, Svg, SvgProps } from 'react-native-svg';

const OutlinedCircle = (props: SvgProps) => (
  <Svg width={26} height={27} viewBox='0 0 26 27' fill='none' {...props}>
    <Path
      stroke='#000'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M25 13.5a12 12 0 1 1-24.002 0A12 12 0 0 1 25 13.5Z'
    />
  </Svg>
);
export { OutlinedCircle };
