import { TireHotelContract, TireHotelOptionsResponse } from 'noddi-async/src/types';

import ContractCard from './ContractCard';

type ContractListProps = { tireHotelContracts: TireHotelContract[]; tireHotelOptions: TireHotelOptionsResponse[] };

const ContractList = ({ tireHotelContracts, tireHotelOptions }: ContractListProps) => {
  return (
    <div className='flex flex-col gap-3'>
      {tireHotelContracts.map((contract) => {
        const vinNumber = contract.car.vinNumber;
        const tireHotelOption = tireHotelOptions.find((option) => option.vinNumber === vinNumber);

        return (
          <ContractCard key={contract.id} tireHotelContract={contract} tireHotelOption={tireHotelOption?.tireHotel} />
        );
      })}
    </div>
  );
};

export default ContractList;
