import { NoddiBasicCard, NoddiIconButton } from 'noddi-ui';
import { useBookingAddress } from '../../BookingStore';
import { useAnnouncementActions, useIsAnnouncementHidden } from './store';

export const PublicAnnouncement = () => {
  const bookingAddress = useBookingAddress();

  const addressId = bookingAddress?.id;

  const { hideAnnouncement } = useAnnouncementActions();

  const isAnnouncementHidden = useIsAnnouncementHidden(addressId);

  if (isAnnouncementHidden || !bookingAddress?.publicAnnouncement || !addressId) {
    return null;
  }

  return <Announcement announcement={bookingAddress.publicAnnouncement} onClick={() => hideAnnouncement(addressId)} />;
};

const Announcement = ({ announcement, onClick }: { announcement: string; onClick: () => void }) => {
  return (
    <NoddiBasicCard className='bg-signal-warning50'>
      <div className='flex justify-between gap-4'>
        <span>{announcement}</span>
        <NoddiIconButton iconName='Cross' className='-mt-2' variant='ghost' onClick={onClick} />
      </div>
    </NoddiBasicCard>
  );
};
