import { DateFormats, format } from 'noddi-util';

import { NoddiBasicCard } from '../../../../atoms';
import { KeyValueRow } from '../../../Elements';

type EuCheckDetailsProps = {
  euControlNext: string | undefined;
  euControlPrevious: string | undefined;
  translations: {
    nextCheck: string;
    lastApproval: string;
    euCheck: string;
  };
};

const EuCheckDetails = ({ euControlNext, euControlPrevious, translations }: EuCheckDetailsProps) => {
  return (
    (euControlNext || euControlPrevious) && (
      <div className='flex flex-col gap-4'>
        <h6 className='font-bold text-5 md:text-7'>{translations.euCheck}</h6>
        <NoddiBasicCard>
          {euControlNext && (
            <KeyValueRow
              header={translations.nextCheck}
              value={format(euControlNext, DateFormats.DASHED_DATE)}
              showDivider={!!euControlPrevious}
            />
          )}
          {euControlPrevious && (
            <KeyValueRow
              header={translations.lastApproval}
              value={format(euControlPrevious, DateFormats.DASHED_DATE)}
              showDivider={false}
            />
          )}
        </NoddiBasicCard>
      </div>
    )
  );
};

export { EuCheckDetails };
