import { NoddiBasicCard } from '../../../../atoms';
import { NoddiDummyButton } from '../../../../molecules';
import { KeyValueRow } from '../../../Elements';
import { WheelHeader } from '../WheelMeasurementHeader';
import { WheelSetStatus, WheelSetStatusType } from '../types';

type TireDetailsProps = {
  onClick?: () => void;
  statusSummerWheelSet: WheelSetStatusType;
  statusWinterWheelSet: WheelSetStatusType;
  translations: {
    seeMeasurements: string;
    tires: string;
    summerTires: string;
    winterTires: string;
    missingMeasurements: string;
    soonWornOut: string;
    wornOut: string;
    inAGoodCondition: string;
  };
};
const TireDetails = ({ onClick, statusSummerWheelSet, statusWinterWheelSet, translations }: TireDetailsProps) => {
  const hasMeasurements =
    statusSummerWheelSet !== WheelSetStatus.MissingMeasurements ||
    statusWinterWheelSet !== WheelSetStatus.MissingMeasurements;
  return (
    <div className='flex flex-col gap-4'>
      <h6 className='font-bold text-5 md:text-7'>{translations.tires}</h6>
      <NoddiBasicCard onClick={hasMeasurements ? onClick : undefined}>
        <div className='flex flex-col justify-between gap-4'>
          <div>
            <KeyValueRow
              header={<WheelHeader type='summer' wheelSetStatus={statusSummerWheelSet} translations={translations} />}
            />
            <KeyValueRow
              header={<WheelHeader type='winter' wheelSetStatus={statusWinterWheelSet} translations={translations} />}
              showDivider={false}
            />
          </div>
          <div className='flex justify-end'>
            {hasMeasurements && (
              <NoddiDummyButton endIcon='ArrowRight' className='pr-0' variant='link'>
                {translations.seeMeasurements}
              </NoddiDummyButton>
            )}
          </div>
        </div>
      </NoddiBasicCard>
    </div>
  );
};

export { TireDetails };
