import { AxiosError } from 'axios';
import { errorCodes as errorCodesWithoutTrans } from '../../../../errorCodes';
import { NoddiFeedbackBox } from '../../../../molecules';
import { useCaptureSentryException } from '../utils';

export type NoddiAsyncError = AxiosError<{
  type: string;
  errors: { code: string; detail: string; attr: string }[];
}>;

export const mapErrorCodeToUiMessage = ({
  error,
  errorCodes
}: {
  error: NoddiAsyncError | (NoddiAsyncError | null)[];
  errorCodes?: Record<string, string> & { genericErrorMessage: string };
}) => {
  const errorToRender = Array.isArray(error) ? error.find((e) => e !== null) : error;

  const errors = errorToRender?.response?.data.errors ?? [];

  const errorCodeTexts = errorCodes ?? errorCodesWithoutTrans;

  if (!errors) {
    return errorCodeTexts.genericErrorMessage;
  }
  if (errors.length === 1) {
    const code = errors[0]?.code;

    return errorCodeTexts[code as keyof typeof errorCodeTexts] ?? errorCodeTexts.genericErrorMessage;
  }
  if (errors.length > 1) {
    //writes reducer that iterates through errors and returns a string
    const message = errors.reduce((prev, curr, index) => {
      const code = curr?.code ?? '';

      const errorCode = errorCodeTexts[code as keyof typeof errorCodeTexts] ?? errorCodeTexts.genericErrorMessage;

      //dont apply error code if errorCodeTexts.genericErrorMessage is already in string
      // also strip away any trailing commas
      if (errorCode === errorCodeTexts.genericErrorMessage && prev.includes(errorCodeTexts.genericErrorMessage)) {
        const cleanedPrev = prev.replace(/(,\s*)+$/, '');
        return cleanedPrev;
      }

      if (index === errors.length - 1) {
        prev += errorCode;
      } else if (errorCode) {
        prev += `${errorCode}, `;
      }

      return prev;
    }, '');
    return message;
  }
  return errorCodeTexts.genericErrorMessage;
};

export type ApiErrorMessageProps = {
  error: NoddiAsyncError | Array<NoddiAsyncError | null>;
  errorCodes?: Record<string, string> & { genericErrorMessage: string };
};

export function ApiErrorMessage({ error, errorCodes }: ApiErrorMessageProps) {
  const errorToRender = Array.isArray(error) ? error.find((e): e is NoddiAsyncError => e !== null) : error;

  useCaptureSentryException(errorToRender, 'ApiErrorMessage');

  if (!errorToRender) {
    return null;
  }

  return (
    <div className='mt-2'>
      <NoddiFeedbackBox
        variant='error'
        description={mapErrorCodeToUiMessage({
          error: errorToRender,
          errorCodes: errorCodes ?? errorCodesWithoutTrans
        })}
      />
    </div>
  );
}

interface ErrorMessageProps {
  message: string;
}

export function ErrorMessage({ message }: ErrorMessageProps) {
  useCaptureSentryException(message, 'ErrorMessage');

  return (
    <div className='mt-2'>
      <NoddiFeedbackBox variant='error' description={message} />
    </div>
  );
}
