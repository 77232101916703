import { Route, Routes } from 'react-router-dom';

import routes from '../appRoutes';
import Account, { EditAccount } from './Account';
import Bonus from './Bonus';
import { Bookings } from './Bookings';
import BookingDetails from './Bookings/[id]';
import EditBookingTimeWindow from './Bookings/[id]/EditBookingTimeWindow';
import CarDetailPage from './Cars/CarDetailPage';
import WheelMeasureMentPage from './Cars/WheelMeasureMentPage';
import { Error404PageWithTranslations } from './Error404';
import Membership from './Memberships';
import { TireHotel } from './TireHotel';

export function HomeRouter() {
  return (
    <Routes>
      <Route path='/'>
        <Route path={routes.myBookingDetails.getBasePath()} element={<BookingDetails />} />

        <Route path={routes.myCarDetails.getBasePath()} element={<CarDetailPage />} />
        <Route path={routes.myCarWheelMeasurements.getBasePath()} element={<WheelMeasureMentPage />} />
        <Route path={routes.bonus.getBasePath()} element={<Bonus />} />
        <Route path={routes.homeError.getBasePath()} element={<Error404PageWithTranslations />} />
        <Route path={routes.editProfile.getBasePath()} element={<EditAccount />} />
        <Route path={routes.profile.getBasePath()} element={<Account />} />

        <Route path={routes.myMembershipPrograms.getBasePath()} element={<Membership />} />
        <Route path={routes.bookings.getBasePath()} element={<Bookings />} />

        <Route path={routes.tireHotel.getBasePath()} element={<TireHotel />} />
      </Route>
      <Route path='/'>
        <Route path={routes.editMyBookingTimeWindow.getBasePath()} element={<EditBookingTimeWindow />} />
      </Route>
    </Routes>
  );
}
