import { Path, Svg, SvgProps } from 'react-native-svg';

const Cross = ({ color, ...rest }: SvgProps) => {
  return (
    <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...rest}>
      <Path stroke={color} strokeLinecap='round' strokeWidth={1.5} d='m10 10 11.782 11.782M22 10 10.218 21.782' />
    </Svg>
  );
};
export { Cross };
