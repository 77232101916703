import { t } from '@lingui/macro';
import groupBy from 'lodash/groupBy';
import { SALES_ITEM_TYPE, SalesItemsCar } from 'noddi-async/src/types';
import { NoddiFeedbackBox } from 'noddi-ui';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingCars, useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { CarIdentifierWithIcon } from '../../components/CarIdentifierWithIcon';
import { ServiceCategoryCollapseCard } from '../../components/salesItems/ServiceCategoryCollapseCard';
import { compareLicensePlates } from '../../helpers/utils';

type AddonPickerProps = {
  carWithAddons: SalesItemsCar;
};

export const CarWithAddons = ({ carWithAddons }: AddonPickerProps) => {
  const { licensePlate, salesItems } = carWithAddons;
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();

  const selectCars = useBookingCars();

  const car = selectCars.find((car) => compareLicensePlates(car.licensePlate, licensePlate));

  if (!car) {
    return <ErrorPageWithTranslations />;
  }

  const sortedSalesItems = salesItems.toSorted((a, b) => a.uiSortOrder - b.uiSortOrder);

  const bookingCategories = groupBy(sortedSalesItems, 'bookingCategorySlug');

  const numberOfCategories = Object.keys(bookingCategories).length;

  // Api returns empty array when no addons are available for car,
  // this will only be rendered when no addons are available for a car in same booking with other cars having addons
  if (!numberOfCategories) {
    return (
      <div className='flex flex-col gap-6'>
        <CarIdentifierWithIcon car={car} />
        <NoddiFeedbackBox
          heading={t`Unfortunately, we don't have offer any addons for the services you've selected on this car`}
        />
      </div>
    );
  }

  const selectedSalesItemsCar = selectedSalesItemsCars.filter((item) =>
    compareLicensePlates(item.licensePlate, licensePlate)
  );

  return (
    <div className='flex flex-col gap-6'>
      <CarIdentifierWithIcon car={car} />

      <div className='flex flex-col gap-4'>
        {Object.entries(bookingCategories).map(([category, salesItems]) => {
          const numberOfSelectedSalesItems = selectedSalesItemsCar.filter((item) =>
            salesItems.map((item) => item.salesItemId).includes(item.salesItemId)
          ).length;

          return (
            <ServiceCategoryCollapseCard
              key={category}
              category={category}
              salesItemType={SALES_ITEM_TYPE.ADDON}
              salesItems={salesItems}
              licensePlate={licensePlate}
              numberOfSelectedSalesItems={numberOfSelectedSalesItems}
              numberOfCategories={numberOfCategories}
            />
          );
        })}
      </div>
    </div>
  );
};
