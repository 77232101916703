import { useNavigate } from 'react-router-dom';
import { commonTexts } from '../../commonTexts';
import { ErrorPageBase } from '../Elements';

interface ForbiddenPageProps {
  translations?: {
    forbiddenAccess: string;
    youDoNotHavePermission: string;
    goHome: string;
  };
}

const ForbiddenPage = ({ translations }: ForbiddenPageProps) => {
  const texts = translations ?? {
    forbiddenAccess: commonTexts.forbiddenAccess,
    youDoNotHavePermission: commonTexts.youDoNotHavePermission,
    goHome: commonTexts.goHome
  };
  const navigate = useNavigate();

  return (
    <ErrorPageBase
      title={texts.forbiddenAccess}
      description={texts.youDoNotHavePermission}
      buttonText={texts.goHome}
      onButtonClick={() => navigate('/')}
    />
  );
};

// expect that all apps have same url to forbidden page
const forbiddenPageUrl = '/forbidden';

export { ForbiddenPage, forbiddenPageUrl };
