import { cva, type VariantProps } from 'class-variance-authority';

import { ReactNode } from 'react';
import { cn } from '../helpers/utils';
import { NoddiIconButton } from './NoddiIconButton';

const toastVariants = cva('flex animate-toastInOut items-center gap-4 rounded-lg border-l-8 px-3 py-2', {
  variants: {
    variant: {
      success: 'border-l-8 border-signal-success bg-signal-success50',
      error: 'border-l-signal-danger bg-signal-danger50',
      warning: 'border-l-signal-warning bg-signal-warning50',
      info: 'border border-l-8 border-primary-purple bg-primary-white'
    }
  },
  defaultVariants: {
    variant: 'info'
  }
});

export type NoddiFeedbackBoxProps = VariantProps<typeof toastVariants> & {
  heading?: string;
  description?: string;
  onClose?: () => void;
  className?: string;
};

export const NoddiFeedbackBox = ({ variant, heading, description, onClose, className }: NoddiFeedbackBoxProps) => {
  const isWithoutAnimation = onClose === undefined;

  return (
    <div className={cn(toastVariants({ variant }), isWithoutAnimation && '!animate-none', className)}>
      <div className='flex flex-col gap-1'>
        {!!heading && <span className={cn(!!description && 'font-bold')}>{heading}</span>}
        {!!description && <span>{description}</span>}
      </div>

      {onClose && (
        <NoddiIconButton
          onClick={() => {
            onClose();
          }}
          className='-mr-3'
          iconName='Cross'
          iconSize='large'
          variant='ghost'
        />
      )}
    </div>
  );
};

interface ToastsWrapperProps {
  children: ReactNode;
}
export const NoddiFeedbackWrapper = ({ children }: ToastsWrapperProps) => {
  return <div className='fixed right-1 top-4 z-[100000] mx-2'>{children}</div>;
};
