import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const DottedClockCircle = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
    <Path stroke='#1F1F1F' strokeLinecap='round' strokeWidth={1.5} d='M4 12a8 8 0 1 0 8-8' />
    <Path
      stroke='#1F1F1F'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M12 9.6v3.2h3.2'
      opacity={0.5}
    />
    <Circle
      cx={12}
      cy={12}
      r={8}
      stroke='#1F1F1F'
      strokeDasharray='0.5 3.5'
      strokeLinecap='round'
      strokeWidth={1.5}
      opacity={0.5}
    />
  </Svg>
);
export { DottedClockCircle };
