import { Path, Svg, SvgProps } from 'react-native-svg';

const Calendar = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeWidth={1.5}
      d='M5.333 16c0-4.023 0-6.034 1.25-7.284s3.261-1.25 7.284-1.25h4.266c4.023 0 6.034 0 7.284 1.25s1.25 3.261 1.25 7.284v2.133c0 4.023 0 6.034-1.25 7.284s-3.261 1.25-7.284 1.25h-4.266c-4.023 0-6.034 0-7.284-1.25s-1.25-3.261-1.25-7.284V16Z'
    />
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={1.5}
      d='M10.667 7.467v-1.6M21.333 7.467v-1.6M5.867 12.8h20.266'
    />
    <Path
      fill={color}
      d='M22.4 21.333a1.067 1.067 0 1 1-2.133 0 1.067 1.067 0 0 1 2.133 0ZM22.4 17.067a1.067 1.067 0 1 1-2.133 0 1.067 1.067 0 0 1 2.133 0ZM17.067 21.333a1.067 1.067 0 1 1-2.134 0 1.067 1.067 0 0 1 2.134 0ZM17.067 17.067a1.067 1.067 0 1 1-2.134 0 1.067 1.067 0 0 1 2.134 0ZM11.733 21.333a1.067 1.067 0 1 1-2.133 0 1.067 1.067 0 0 1 2.133 0ZM11.733 17.067a1.067 1.067 0 1 1-2.133 0 1.067 1.067 0 0 1 2.133 0Z'
    />
  </Svg>
);

export { Calendar };
