import { Path, Svg, SvgProps } from 'react-native-svg';

const ArrowDown = ({ color, ...props }: SvgProps) => (
  <Svg color={color} width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M16 7.467v17.066m0 0 6.4-6.4m-6.4 6.4-6.4-6.4'
    />
  </Svg>
);
export { ArrowDown };
