import { t, Trans } from '@lingui/macro';
import { noddiAsync, URLKeys } from 'noddi-async';

import { useAuthContext } from 'noddi-provider';
import { getCarDisplayName, NoddiButton, TireMeasurement } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';
import routes from '../../appRoutes';
import getCommonTranslations from '../../commonTranslations';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import { useScrollToTop } from '../BookingFlow/helpers/useScrollToTop';
import TireOfferExplanation from './TireOfferExplanation';

const TireOfferSection = ({ slug }: { slug: string }) => {
  const navigate = useNavigate();
  const {
    isPending: isTireOfferPending,
    error: tireOfferError,
    data: tireOffer
  } = noddiAsync.useGet({
    type: URLKeys.getCarTireQuoteBySlug,
    input: { slug },
    queryConfig: { staleTime: Infinity }
  });

  noddiAsync.useGet({
    type: URLKeys.getTireSetOfferPageView,
    input: { slug },
    queryConfig: { staleTime: Infinity }
  });

  if (isTireOfferPending || tireOfferError) {
    return null;
  }

  return (
    <div className='size-full'>
      <TireMeasurement
        HeaderElement={
          <div>
            <p className='font-bold text-primary-darkPurple'>{getCarDisplayName(tireOffer.carWheelSet.car)}</p>
            <p className='mb-4 text-primary-darkPurple'>
              {tireOffer.carWheelSet.type.label === 'Winter' ? t`Winter tires` : t`Summer tires`}
            </p>
            <NoddiButton onClick={() => navigate(routes.tireOfferSelection.getPath({ slug }))} endIcon='ArrowRight'>
              <Trans>See offer for new tires</Trans>
            </NoddiButton>
          </div>
        }
        tireType={tireOffer.carWheelSet.type.label === 'Winter' ? 'winter' : 'summer'}
        wheels={tireOffer.carWheelSet.wheels}
        translations={getCommonTranslations().wheelMeasurement}
      />
    </div>
  );
};

const MultipleTireoffers = () => {
  const { currentUserGroupId: userGroupId } = useAuthContext();

  useScrollToTop();

  const query = noddiAsync.useGet({
    type: URLKeys.getTireSetOffersForUserGroup,
    input: { userGroupId: userGroupId! }
  });

  return (
    <ContentWrapperWithQuery widerScreen hideBackButton query={query}>
      {({ data }) => (
        <div>
          <h1 className='font-bold text-8 text-primary-darkPurple md:text-11'>
            <Trans>Time for new tires?</Trans>
          </h1>
          <div className='mt-6 flex flex-col gap-6 md:flex-row-reverse md:items-center mdx:gap-25 xl:gap-40'>
            {data.map((tireOffer) => (
              <TireOfferSection key={tireOffer.slug} slug={tireOffer.slug} />
            ))}
          </div>
          <div className='mt-10 flex flex-col gap-2 md:mt-20'>
            <h2 className='font-bold text-primary-darkPurple md:text-6'>
              <Trans>How it works</Trans>
            </h2>
            <TireOfferExplanation />
          </div>
        </div>
      )}
    </ContentWrapperWithQuery>
  );
};

export default MultipleTireoffers;
