import { Trans } from '@lingui/macro';
import { cn, LoadingScreen, NoddiButton, useIsWithinTailWindBreakpoint } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import { UseQueryResult } from '@tanstack/react-query';
import { NoddiAsyncError } from 'noddi-async/src/types';
import { JSX } from 'react';
import { ErrorPageWithTranslations } from '../ErrorPageWithTrans';

interface HomeContentWrapperProps<DataType> {
  title?: string;
  subtitle?: string;
  HeaderElement?: JSX.Element;
  widerScreen?: boolean;
  className?: string;
  hideBackButton?: boolean;
  query: UseQueryResult<DataType, NoddiAsyncError>;
  children: (props: { data: Exclude<DataType, undefined> }) => React.ReactNode;
}

const ContentWrapperWithQuery = <DataType,>({
  children,
  title,
  HeaderElement,
  subtitle,
  widerScreen,
  className,
  hideBackButton,
  query
}: HomeContentWrapperProps<DataType>) => {
  const navigate = useNavigate();

  const { isPending, isFetching, error, data } = query;

  const showLoader = isPending || isFetching;

  const hasContent = !!title || !!subtitle || HeaderElement;

  const isMd = useIsWithinTailWindBreakpoint('md');
  return (
    <div
      className={cn(
        'mx-auto mt-8 max-w-screen-mdx px-6 pb-20 sm:px-10 md:mt-14',
        widerScreen && 'xl:max-w-screen-lg xl:px-6',
        className
      )}
    >
      {hasContent && (
        <div className='mb-4 flex flex-col gap-2'>
          <div>
            {!hideBackButton && (
              <div>
                <NoddiButton
                  startIcon='ArrowLeft'
                  onClick={() => {
                    navigate(-1);
                  }}
                  variant='ghost'
                  className='pl-0'
                >
                  <Trans>Back</Trans>
                </NoddiButton>
              </div>
            )}
            <div className='flex items-center justify-between'>
              {title && <h1 className='font-bold text-8 text-primary-darkPurple md:text-11'>{title}</h1>}
              {!isMd && HeaderElement}
            </div>
          </div>
          {subtitle && <p className='text-primary-darkPurple'>{subtitle}</p>}
          {isMd && HeaderElement}
        </div>
      )}
      {error && <ErrorPageWithTranslations apiError={error} />}
      {showLoader && <LoadingScreen />}
      {!error && !showLoader && children({ data: data as Exclude<DataType, undefined> })}
    </div>
  );
};

export default ContentWrapperWithQuery;
