import { LicensePlateInfo } from 'noddi-async/src/types';

export const getCarDisplayName = (carProps: {
  make?: string | null;
  model?: string | null;
  licensePlateNumber?: string | null;
  makeName?: string | null;
  licensePlate?: LicensePlateInfo | null;
}) => {
  let carText = '';

  if ('make' in carProps) {
    carText += carProps.make;
  }
  if ('makeName' in carProps) {
    carText += carProps.makeName;
  }
  if ('model' in carProps) {
    carText += ` ${carProps.model}`;
  }

  // licensePlateNumber is old, now it's licensePlate object with number and country code
  if ('licensePlateNumber' in carProps) {
    carText += ` - ${carProps.licensePlateNumber}`;
  } else if ('licensePlate' in carProps && carProps.licensePlate?.number) {
    carText += ` - ${carProps.licensePlate.number}`;
  }
  return carText;
};

export const psiToBar = (psi: number) => psi * 0.0689476;
