import { FeatureFlags } from './featureFlags';

type RemoteType = 'json' | 'string' | 'boolean' | 'number';
declare type RemoteValueSource = 'static' | 'default' | 'remote';

export type RemoteConfigKey = 'maintenanceScreen' | 'maintenanceScreenBooking' | 'featureFlags';

export interface MaintenanceScreen {
  shouldShow: boolean;
  title: { swedish: string; norwegian: string; english: string };
  subtitle: { swedish: string; norwegian: string; english: string };
}

export interface RemoteConfigOutputType {
  maintenanceScreen: MaintenanceScreen;
  maintenanceScreenBooking: MaintenanceScreen;
  featureFlags: FeatureFlags;
}
export const remoteConfigInputTypes: Record<RemoteConfigKey, RemoteType> = {
  maintenanceScreen: 'json',
  maintenanceScreenBooking: 'json',
  featureFlags: 'json'
};

export declare interface RemoteConfigValue {
  /**
   * Gets the value as a boolean.
   *
   * The following values (case insensitive) are interpreted as true:
   * "1", "true", "t", "yes", "y", "on". Other values are interpreted as false.
   */
  asBoolean(): boolean;
  /**
   * Gets the value as a number. Comparable to calling <code>Number(value) || 0</code>.
   */
  asNumber(): number;
  /**
   * Gets the value as a string.
   */
  asString(): string;
  /**
   * Gets the {@link RemoteValueSource} for the given key.
   */
  getSource(): RemoteValueSource;
}

let remoteConfig: Record<string, RemoteConfigValue>;

export function setNoddiRemoteConfig(config: Record<string, RemoteConfigValue>) {
  remoteConfig = config;
}

export function getNoddiRemoteConfig<T extends RemoteConfigKey>(key: T): RemoteConfigOutputType[T] | undefined {
  const remoteConfigValue = remoteConfig?.[key];
  const remoteConfigType = remoteConfigInputTypes[key];

  if (!remoteConfigValue) {
    return undefined;
  }

  switch (remoteConfigType) {
    case 'json': {
      const asString = remoteConfigValue.asString();
      try {
        const parsed = JSON.parse(asString) as T;
        return Object.entries(parsed).reduce((previous, [key, value]) => {
          if (value === 'true') {
            return { ...previous, [key]: true };
          } else if (value === 'false') {
            return { ...previous, [key]: false };
          }

          return {
            ...previous,
            [key]:
              typeof value === 'string'
                ? // asString automatically replaces \n with new lines. Therefore we use \\n in remote config.
                  value.split('\\n').join('\n')
                : value
          };
        }, {}) as unknown as RemoteConfigOutputType[T];
      } catch (error) {
        console.warn('error when parsing remote config', error);
        return asString as unknown as RemoteConfigOutputType[T];
      }
    }
    case 'string':
      return remoteConfigValue.asString() as unknown as RemoteConfigOutputType[T];
    case 'boolean':
      return remoteConfigValue.asBoolean() as unknown as RemoteConfigOutputType[T];
    case 'number':
      return remoteConfigValue.asNumber() as unknown as RemoteConfigOutputType[T];
  }

  // Value not present
  throw new Error(`Tried to get remote config, but key was missing: ${key}`);
}
