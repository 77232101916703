import { t } from '@lingui/macro';
import { Skeleton } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { tracking, useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiDialog } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { ErrorPageWithTranslations } from '../../../components/ErrorPageWithTrans';
import { useBookingAddress, useBookingSelectedSalesItemsCars, useBookingTimeWindow } from '../BookingStore';
import { groupCars } from '../helpers/utils';
import { PriceSummary } from './PriceSummary';

export const ShoppingCart = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { currentUserGroupId: userGroupId } = useAuthContext();
  const selectedAddress = useBookingAddress();
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const bookingTimeWindow = useBookingTimeWindow();

  const {
    data: shoppingCart,
    isLoading: isShoppingCartLoading,
    error: shoppingCartError
  } = noddiAsync.usePostAsGetQuery({
    type: URLKeys.postGetShoppingCartForNewBooking,
    input: {
      addressId: selectedAddress?.id,
      cars: groupCars(selectedSalesItemsCars),
      bookingTimeWindowId: bookingTimeWindow?.id,
      userGroupId
    },
    queryConfig: {
      staleTime: Infinity,
      enabled: !!selectedAddress && !!selectedSalesItemsCars.length
    }
  });

  if (shoppingCartError) {
    return <ErrorPageWithTranslations apiError={shoppingCartError} />;
  }

  if (isShoppingCartLoading) {
    return (
      <div className='flex w-full items-end justify-end'>
        <Skeleton variant='rounded' height={42} width={125} />
      </div>
    );
  }

  if (!shoppingCart?.data) {
    return null;
  }

  const { totalPrice } = shoppingCart.data;

  return (
    <>
      <NoddiDialog
        sx={{ zIndex: 1400 }}
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        title={t`Your shopping cart`}
      >
        <PriceSummary shoppingCart={shoppingCart.data} />
      </NoddiDialog>

      <NoddiButton
        variant='secondary'
        onClick={() => {
          tracking.track('shoppingCartOpened');
          setIsDialogOpen(true);
        }}
        startIcon='ShoppingCart'
      >
        {formatCurrencyAmount(totalPrice.amount, 0, totalPrice.currency)}
      </NoddiButton>
    </>
  );
};
