import { Trans } from '@lingui/macro';
import { captureException } from '@sentry/react';
import { URLKeys, noddiAsync } from 'noddi-async';
import { commonTrackingEvents, tracking, useAuthContext } from 'noddi-provider';
import { NoddiButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../../../appRoutes';
import { ApiErrorMessageWithTrans } from '../../../../components/ApiErrorMessageWithTrans';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import {
  useBookingActions,
  useBookingAddress,
  useBookingCars,
  useBookingComment,
  useBookingSelectedSalesItemsCars,
  useBookingTimeWindow
} from '../../BookingStore';
import { compareLicensePlates, groupCars } from '../../helpers/utils';

export const ConfirmBooking = () => {
  const { clearStore } = useBookingActions();
  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const cars = useBookingCars();
  const selectedAddress = useBookingAddress();
  const bookingTimeWindow = useBookingTimeWindow();
  const comment = useBookingComment();

  const { currentUserGroupId: userGroupId, userData } = useAuthContext();

  const navigate = useNavigate();

  const {
    mutateAsync: createNewBooking,
    isPending,
    error
  } = noddiAsync.usePost({
    type: URLKeys.postCreateBooking,
    queryConfig: {
      onSuccess: async (data) => {
        try {
          tracking.track(commonTrackingEvents.bookingCreated, {
            numberOfCars: cars.length
          });
          GoogleTrackingService.trackEvent(GtmEvents.purchase);
        } catch (error) {
          captureException(error);
        }
        clearStore();
        navigate(routes.confirmation.getPath({ bookingId: data.data.id }));
      }
    }
  });

  const onSubmit = async () => {
    await createNewBooking({
      cars: groupCars(selectedSalesItemsCars).map((car) => ({
        licensePlate: car.licensePlate,
        selectedSalesItemIds: car.selectedSalesItemIds,
        pickupAddressId: selectedSalesItemsCars.find(
          (item) =>
            compareLicensePlates(item.licensePlate, car.licensePlate) && item.metaData?.selectedTierHotelPickUpAddress
        )?.metaData?.selectedTierHotelPickUpAddress?.id
      })),
      addressId: selectedAddress!.id,
      bookingTimeWindowId: bookingTimeWindow!.id,
      userId: userData!.user.id,
      userGroupId: userGroupId!,
      commentsUser: comment ?? '',
      // TODO | New booking
      notificationsToOverride: []
    });
  };

  return (
    <div className='flex w-full items-center justify-end gap-2'>
      {error && (
        <div className='absolute bottom-20 mb-2 mdx:relative mdx:bottom-0'>
          <ApiErrorMessageWithTrans error={error} />
        </div>
      )}

      <NoddiButton
        variant='success'
        loading={isPending}
        disabled={!userGroupId || !userData || !selectedAddress || !bookingTimeWindow}
        onClick={onSubmit}
      >
        <Trans>Confirm booking</Trans>
      </NoddiButton>
    </div>
  );
};
