import { useParams } from 'react-router-dom';

import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import BookingConfirmation from './Booking';

const ConfirmationPage = () => {
  const { bookingId } = useParams();

  if (!bookingId) {
    // TODO : Log to sentry? CU-86c1z88k0
    return <ErrorPageWithTranslations />;
  }

  return <BookingConfirmation id={bookingId} />;
};

export default ConfirmationPage;
