import { noddiAsync, URLKeys } from 'noddi-async';

import { t, Trans } from '@lingui/macro';
import { Currency } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { NoddiBasicCard, NoddiButton, NoddiIcon, NoddiTextInput } from 'noddi-ui';
import { formatCurrencyAmount } from 'noddi-util';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import routes from '../../appRoutes';
import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import { CustomerSMSLogin } from '../../components/CustomerSMSLogin';
import ContentWrapperWithQuery from '../../components/Layouts/ContentWrapperWithQuery';
import { useScrollToTop } from '../BookingFlow/helpers/useScrollToTop';
import { useTireOfferStore } from './TireOfferStore';
import { tireOfferAction } from './types';

const PriceRow = ({
  label,
  price,
  quantity
}: {
  label: string;
  price: { amount: number; currency: Currency };
  quantity: number;
}) => (
  <div className='flex justify-between'>
    <p>{label}</p>
    <p>{formatCurrencyAmount(price.amount * quantity, 0, price.currency)}</p>
  </div>
);

const TireOfferSummary = () => {
  const { slug } = useParams() as { slug: string };
  const { tireSelection } = useTireOfferStore();
  const { isLoggedIn } = useAuthContext();
  const [userComment, setUserComment] = useState('');

  const navigate = useNavigate();
  useScrollToTop();

  const query = noddiAsync.useGet({
    type: URLKeys.getCarTireQuoteBySlug,
    input: { slug },
    queryConfig: { staleTime: Infinity }
  });

  const {
    mutateAsync: confirmTireQuoteOffer,
    isPending: isConfirmTireQuoteOfferPending,
    error: confirmTireQuoteOfferError
  } = noddiAsync.usePost({
    type: URLKeys.postConfirmTireQuoteOffer,
    queryConfig: {
      onSuccess: async () => {
        navigate(routes.tireOfferConfirmation.getPath({ action: tireOfferAction.ACCEPTED }));
      }
    }
  });

  if (!tireSelection) {
    return null;
  }

  const {
    selectedOffer: { brand, model, price }
  } = tireSelection;

  const totalTireSum = tireSelection.quantity * price.amount;

  return (
    <ContentWrapperWithQuery
      title={t`Ready to confirm your order?`}
      subtitle={t`Upon confirmation, we will order the tires from our central warehouse and schedule a time for installation at your home.`}
      query={query}
    >
      {({ data }) => (
        <div className='flex flex-col gap-4'>
          <NoddiBasicCard className='mt-5 flex flex-col gap-2 text-primary-darkPurple'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-1'>
                <NoddiIcon name='WheelAngle' />
                <p className='font-bold'>
                  {brand} - {model}
                </p>
              </div>
              <p>{formatCurrencyAmount(totalTireSum, 0, tireSelection.selectedOffer.price.currency)}</p>
            </div>
            <div className='mb-2 flex justify-end'>
              <p>
                {tireSelection.quantity} <Trans>pcs</Trans>
              </p>
            </div>
            {data.fixedItems.map(({ name, price, quantity }, index) => (
              <PriceRow key={index} label={name} price={price} quantity={quantity} />
            ))}

            <div className='flex justify-between font-bold'>
              <p>
                <Trans>Total sum including VAT</Trans>
              </p>
              <p>
                {formatCurrencyAmount(
                  data.fixedItems.reduce((acc, item) => acc + item.price.amount * item.quantity, 0) + totalTireSum,
                  0
                )}
              </p>
            </div>
          </NoddiBasicCard>

          {isLoggedIn ? (
            <div>
              <NoddiBasicCard className='flex flex-col gap-3'>
                <p className='text-5'>
                  <Trans>Anything more we should know?</Trans>
                </p>

                <NoddiTextInput
                  rows={2}
                  onChange={(e) => setUserComment(e.target.value)}
                  value={userComment ?? ''}
                  placeholder={t`Optional note if any extra information is needed...`}
                  multiline
                />
              </NoddiBasicCard>
              {confirmTireQuoteOfferError && <ApiErrorMessageWithTrans error={confirmTireQuoteOfferError} />}
              <div className='md:flex md:justify-end'>
                <NoddiButton
                  variant='success'
                  className='mt-5 w-full md:w-fit'
                  loading={isConfirmTireQuoteOfferPending}
                  onClick={async () =>
                    await confirmTireQuoteOffer({
                      selectedTireOption: {
                        id: tireSelection.selectedOffer.id,
                        quantity: tireSelection.quantity
                      },
                      comments: userComment,
                      carTireQuoteId: data.id
                    })
                  }
                >
                  <Trans>Confirm order</Trans>
                </NoddiButton>
              </div>
            </div>
          ) : (
            <div className='text-primary-darkPurple'>
              <p className='mb-2 font-bold'>
                <Trans>You need to log in in order to confirm the order</Trans>
              </p>
              <CustomerSMSLogin />
            </div>
          )}
        </div>
      )}
    </ContentWrapperWithQuery>
  );
};

export default TireOfferSummary;
