import { t, Trans } from '@lingui/macro';
import { NoddiDialog } from 'noddi-ui';

import { SalesItemV2, UnavailableSalesItemV2 } from 'noddi-async/src/types';
import { ContactUs } from '../../../../components/ContactUs';

type UnavailableModalProps = {
  open: boolean;
  onClose: () => void;
  salesItem: SalesItemV2;
  unavailableSalesItems?: UnavailableSalesItemV2[];
};

export const UnavailableModal = ({ open, onClose, salesItem, unavailableSalesItems }: UnavailableModalProps) => {
  const unavailableSalesItem = unavailableSalesItems?.find(
    (unavailableSalesItem) => unavailableSalesItem.salesItemId === salesItem.salesItemId
  );
  return (
    <NoddiDialog title={t`Unavailable combination`} onClose={onClose} open={open}>
      <div className='flex flex-col gap-4'>
        <p>
          <span className='font-bold'>{salesItem.name}</span>{' '}
          <Trans>is currently not offered together with the following services:</Trans>
        </p>
        <div className='flex flex-col gap-2'>
          <ul>
            {unavailableSalesItem?.conflictingSelectedSalesItems.map((item) => (
              <li key={item.name}>
                <p className='font-bold'>{item.name}</p>
              </li>
            ))}
          </ul>
        </div>
        <p>
          <Trans>To book this combination, please create two separate bookings.</Trans> <ContactUs />
        </p>
      </div>
    </NoddiDialog>
  );
};
