import { URLKeys, noddiAsync } from 'noddi-async';
import { UserDataProps } from 'noddi-async/src/types';
import { LoadingScreen, useIsMobile } from 'noddi-ui';

import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import NoBookingsCard from '../../components/Home/NoUpcomingBookingsCard';
import ContentWrapper from '../../components/Layouts/ContentWrapper';
import { TireHotelContent } from '../TireHotel/TireHotel';
import Cars from './Cars';
import Discounts from './Discounts';
import HomePageHeader from './HomePageHeader';
import PreviousBookings from './PreviousBooking';
import { TireSetOffers } from './TireSetOffers';
import UpcomingBookings from './UpcomingBookings';

export const DEFAULT_NUMBER_OF_BOOKINGS_SHOWN = 1;

type HomeContentProps = {
  userGroupId: number;
  userData: UserDataProps;
  hasTireHotel: boolean;
};

const HomeContent = ({ userGroupId, userData, hasTireHotel }: HomeContentProps) => {
  const isMobile = useIsMobile();

  const {
    isPending: isNumberOfBookingsPending,
    data: numberOfBookings,
    error: numberOfBookingsError
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupNumberOfBookings,
    input: { userGroupId }
  });

  if (numberOfBookingsError) {
    return <ErrorPageWithTranslations apiError={numberOfBookingsError} />;
  }

  if (isNumberOfBookingsPending) {
    return <LoadingScreen />;
  }

  const isFirstTimeCustomer = !numberOfBookings;

  return (
    <ContentWrapper hideBackButton widerScreen>
      <div className='flex flex-col gap-6 text-primary-darkPurple md:gap-17'>
        <HomePageHeader userData={userData} isFirstTimeCustomer={isFirstTimeCustomer} isMobile={isMobile} />
        <div className='flex flex-col gap-8'>
          <TireSetOffers userGroupId={userGroupId} />
          <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
            {isFirstTimeCustomer && <NoBookingsCard />}
            <UpcomingBookings userGroupId={userGroupId} />
            {hasTireHotel && <TireHotelContent showHeading />}
            <Cars userGroupId={userGroupId} />
            <PreviousBookings userGroupId={userGroupId} />
            <Discounts userGroupId={userGroupId} />
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default HomeContent;
