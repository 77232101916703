import { Trans, t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, NoddiFeedbackBox, useIsMobile } from 'noddi-ui';
import { useState } from 'react';

import { NoddiAsyncError } from 'noddi-async/src/types';
import { ApiErrorMessageWithTrans } from '../../../components/ApiErrorMessageWithTrans';
import ContentWrapper from '../../../components/Layouts/ContentWrapper';
import { getImageSrc } from '../../../utils/resolveCompanyLogo';
import { bookingCategoryLabelToEnum } from '../../BookingFlow/helpers/utils';
import { UserAlreadyAMember } from '../AlreadyMember';
import { HighlightedSalesItem } from '../[slug]/HighLightedSalesItem';
import { NafMembershipValidationForm } from './MembershipNumberForm';

const MembershipNAF = () => {
  const isMobile = useIsMobile();
  const imageSize = isMobile ? 48 : 64;
  const { isLoggedIn, getCurrentUserGroupId } = useAuthContext();
  const userGroupId = getCurrentUserGroupId();

  const [customErrorMessage, setCustomErrorMessage] = useState<string | null | NoddiAsyncError>(null);

  const { data: isMemberData, isLoading } = noddiAsync.useGet({
    type: URLKeys.getIsNAFMember,
    input: { userGroupId: userGroupId as number },
    queryConfig: {
      enabled: !!userGroupId
    }
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  const isMember = isMemberData?.isMember || false;
  const safeSlug = 'naf';

  return (
    <ContentWrapper>
      <div className='flex flex-col gap-8 text-primary-darkPurple'>
        <div className='flex items-center justify-between'>
          <h1 className='font-bold text-8 text-primary-darkPurple md:text-11'>
            <Trans>NAF benefits</Trans>
          </h1>
          {getImageSrc(safeSlug) && (
            <div
              className='mr-2'
              style={{
                height: imageSize,
                width: imageSize
              }}
            >
              <img alt='NAF' src={getImageSrc(safeSlug)} />
            </div>
          )}
        </div>
        {!isMember && <NafMembershipValidationForm setCustomErrorMessage={setCustomErrorMessage} />}

        {typeof customErrorMessage === 'string' && <NoddiFeedbackBox variant='error' heading={customErrorMessage} />}
        {typeof customErrorMessage === 'object' && (
          <ApiErrorMessageWithTrans error={customErrorMessage as NoddiAsyncError} />
        )}

        {isLoggedIn && isMember ? (
          <UserAlreadyAMember />
        ) : (
          <p className='opacity-80'>
            <Trans>
              NAF cares about the safety of all its members and therefore it is important that you have the right tires
              on at the right time. But why drive to the workshop, when it can come to you? Noddi is car made easy!
            </Trans>
          </p>
        )}

        <HighlightedSalesItem
          serviceName={t`Get 10 % off on every service`}
          type={bookingCategoryLabelToEnum('wheel_services')}
          hidePopular
        />
      </div>
    </ContentWrapper>
  );
};
export default MembershipNAF;
