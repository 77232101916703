import { cn, useIsMobile } from 'noddi-ui';
import { Outlet, useLocation } from 'react-router-dom';

import routes from '../../appRoutes';
import HelpScoutChat from '../BookingFlow/HelpScoutChat';
import { CustomerHeaderNav } from './CustomerHeaderNav';

export const RouterLayout = ({ className, showChat }: { className?: string; showChat?: boolean }) => {
  const { pathname } = useLocation();
  const isTimePickerStep = pathname.includes(routes.newBookingTimeWindow.getBasePath());
  const isMobile = useIsMobile();

  const applyTimePickerMobileGradient = isTimePickerStep && isMobile;

  return (
    <div
      className={cn(
        `min-h-screen ${applyTimePickerMobileGradient ? 'bg-pinto-to-white-gradient' : 'bg-pint-to-lighterPint-gradient'}`,
        className
      )}
    >
      <CustomerHeaderNav /> <Outlet />
      {showChat && (
        <div className='fixed bottom-10 right-10 z-50'>
          <HelpScoutChat position='right' size='small' />
        </div>
      )}
    </div>
  );
};
