import { tracking, useAuthContext } from 'noddi-provider';

import { TZDate } from '@date-fns/tz';
import { addDays } from 'date-fns';
import { AvailableBookingTimeWindowV2 } from 'noddi-async/src/types';
import { BookingTimeWindowPickerV2 } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';
import getCommonTranslations from '../../../../commonTranslations';
import { GoogleTrackingService } from '../../../../services/GoogleTrackingService';
import { GtmEvents } from '../../../../types/gtmTracking';
import {
  useBookingActions,
  useBookingAddress,
  useBookingSelectedSalesItemsCars,
  useBookingTimeWindow
} from '../../BookingStore';
import { summaryStepPath } from '../../helpers/bookingFlowPaths';

export const TimeWindowPicker = () => {
  const { isSuperUser } = useAuthContext();

  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();
  const selectedAddress = useBookingAddress();
  const selectedBookingTimeWindow = useBookingTimeWindow();
  const { setSelectedTimeWindow } = useBookingActions();
  const navigate = useNavigate();

  const navigateToNextStep = (_: AvailableBookingTimeWindowV2) => {
    GoogleTrackingService.trackEvent(GtmEvents.addShippingInfo);
    GoogleTrackingService.trackEvent(GtmEvents.beginCheckout);
    navigate(summaryStepPath);
  };

  const onSelect = (timeWindow: AvailableBookingTimeWindowV2) => {
    setSelectedTimeWindow(timeWindow);
    navigateToNextStep(timeWindow);
  };

  return (
    <BookingTimeWindowPickerV2
      addressId={selectedAddress?.id}
      selectedSalesItemIds={selectedSalesItemsCars.map((selectedSalesItemsCar) => selectedSalesItemsCar.salesItemId)}
      fromDate={addDays(new TZDate(new Date(), selectedAddress?.timeZone), 1)}
      onUnavailableSelect={(timeWindow) => {
        if (!isSuperUser) {
          return;
        }
        onSelect(timeWindow);
      }}
      selectedTimeWindowId={selectedBookingTimeWindow?.id}
      onAvailableSelect={(timeWindow) => {
        if (selectedBookingTimeWindow?.id !== timeWindow.id) {
          tracking.track('timeWindowChanged');
        }
        onSelect(timeWindow);
      }}
      translations={getCommonTranslations().timePicker}
    />
  );
};
