import { t } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { LoadingScreen } from 'noddi-ui';

import { useNavigate } from 'react-router-dom';
import { ErrorPageWithTranslations } from '../../../../components/ErrorPageWithTrans';
import { useBookingSelectedSalesItemsCars } from '../../BookingStore';
import { BookingScreen } from '../../components/BookingScreen';
import { timeWindowStepPath } from '../../helpers/bookingFlowPaths';
import { useIsStepValid } from '../../helpers/useStepCompletion';
import { CarWithTierPickUpOptions } from './CarWithTierPickUpOptions';

export const TierHotelPickup = () => {
  const navigate = useNavigate();

  const nextButtonClick = () => {
    // GoogleTrackingService.trackEvent({
    //   eventType: GtmEvents.beginCheckout,
    //   data: { salesItems, membershipData: membershipData ?? [], coupons }
    // });
    return navigate(timeWindowStepPath);
  };

  const selectedSalesItemsCars = useBookingSelectedSalesItemsCars();

  const carsWithTierPickupOptions = selectedSalesItemsCars.filter((car) => car.isWheelPickupRequired);

  const { isTierHotelPickUpStepValid } = useIsStepValid();

  const {
    data: pickUpOptions,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getTierHotelPickupSalesItemsOptionsV2
  });

  if (error) {
    return <ErrorPageWithTranslations />;
  }

  if (isPending) {
    return <LoadingScreen />;
  }

  return (
    <BookingScreen
      onNextClicked={nextButtonClick}
      disableNextButton={!isTierHotelPickUpStepValid}
      title={t`Where should we pick up your tires?`}
    >
      <div className='flex flex-col gap-8'>
        {carsWithTierPickupOptions.map((item) => (
          <CarWithTierPickUpOptions
            key={item.licensePlate.number}
            licensePlate={item.licensePlate}
            pickUpOptions={pickUpOptions}
          />
        ))}
      </div>
    </BookingScreen>
  );
};
