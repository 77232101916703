import { t } from '@lingui/macro';
import { Drawer } from '@mui/material';
import { useAuthContext } from 'noddi-provider';
import { cn, LoadingScreen, NoddiLanguagePicker, useIsMobile } from 'noddi-ui';
import { DrawerContent, PaperProps } from 'noddi-ui/src/components/Elements/Layouts/VerticalLayout/SideNav';
import { useMobileNav } from 'noddi-ui/src/components/Elements/Layouts/VerticalLayout/useMobileNav';
import { useFilteredNavItems } from 'noddi-ui/src/components/Elements/Layouts/useFilteredNavItems';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { captureMessage } from '@sentry/react';
import routes from '../appRoutes';
import HelpScoutChat from '../components/BookingFlow/HelpScoutChat';
import { HomeHeaderNav } from '../components/Layouts/HomeHeaderNav';
import ImpersonateUserButton from '../components/UserData/ImpersonateUserButton';
import { useNavItems } from '../hooks/useNavItems';
import { getSupportedLocales } from '../utils/translation';
import { useBookingActions } from './BookingFlow/BookingStore';

export function HomeLayout() {
  const { tokenLoginError, isLoggedIn, isTokenLoadingOnMount } = useAuthContext();
  const { getNavItems } = useNavItems();
  const { pathname } = useLocation();

  const mobileNav = useMobileNav();
  const isMobile = useIsMobile();

  const filteredNavItems = useFilteredNavItems(getNavItems());

  const { clearStore } = useBookingActions();

  //Closes the MobileNav after user clicks one of the menu items (route change)
  useEffect(() => {
    mobileNav.handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (isTokenLoadingOnMount) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return <Navigate to={routes.login.getPath()} />;
  }

  if (tokenLoginError && !isLoggedIn) {
    // This should never happen? Remove check if this never occurs in logs
    captureMessage('TokenLoginError and !isLoggedIn private/homeLayout', {
      level: 'warning'
    });
    if (tokenLoginError?.response?.status === 401) {
      return <Navigate to={routes.login.getPath()} />;
    }
  }

  const isTimePickerStep = pathname.includes(routes.newBookingTimeWindow.getBasePath());
  const isBookingEditPath = pathname.startsWith('/home/bookings') && pathname.endsWith('edit');

  const applyTimePickerMobileGradient = (isTimePickerStep || isBookingEditPath) && isMobile;

  const hideChat = pathname.includes(routes.homepage.getBasePath()) || pathname.includes(routes.bookings.getBasePath());

  return (
    <div
      className={cn('min-h-screen bg-peach-to-purple', applyTimePickerMobileGradient && 'bg-pinto-to-white-gradient')}
    >
      <HomeHeaderNav onNavOpen={mobileNav.handleOpen} />

      <Drawer
        anchor='left'
        onClose={mobileNav.handleClose}
        open={mobileNav.open}
        PaperProps={PaperProps()}
        variant='temporary'
      >
        <DrawerContent
          translations={{ profile: t`My account`, logout: t`Log out` }}
          navItems={filteredNavItems}
          LanguagePicker={<NoddiLanguagePicker supportedLanguages={getSupportedLocales()} />}
          CustomNavElement={<ImpersonateUserButton handleClearDataWhenImpersonating={clearStore} />}
          customPathToProfile={routes.profile.getPath()}
          handleClearDataWhenImpersonating={clearStore}
        />
      </Drawer>

      <Outlet />
      {!hideChat && (
        <div className='fixed bottom-10 right-10 z-50'>
          <HelpScoutChat position='right' size='small' />
        </div>
      )}
    </div>
  );
}
