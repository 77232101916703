import { Trans, plural } from '@lingui/macro';
import { URLKeys, noddiAsync } from 'noddi-async';
import { LoadingScreen, NoddiBasicCard, NoddiDummyButton } from 'noddi-ui';
import { useNavigate } from 'react-router-dom';

import routes from '../../appRoutes';
import { ErrorPageWithTranslations } from '../../components/ErrorPageWithTrans';
import MembershipDiscount from '../Memberships/MembershipDiscount';

const Discounts = ({ userGroupId }: { userGroupId: number }) => {
  const navigate = useNavigate();

  const { isPending: isMembershipProgramsPending, data: membershipPrograms } = noddiAsync.useGet({
    type: URLKeys.getMembershipPrograms,
    input: { userGroupId }
  });

  const {
    data: coupons,
    isPending,
    error
  } = noddiAsync.useGet({
    type: URLKeys.getUserGroupCoupons,
    input: { id: userGroupId, includeUsedOnBookingIds: [] }
  });

  if (isPending || isMembershipProgramsPending) {
    return <LoadingScreen />;
  }
  if (error) {
    return <ErrorPageWithTranslations apiError={error} />;
  }

  const userHasCoupons = !!coupons.length;

  const currentCouponsMessage = plural(coupons.length, {
    one: 'coupon on your account!',
    other: 'coupons on your account!'
  });

  return (
    <div className='flex flex-col gap-2 md:gap-4'>
      <h2 className='font-bold text-5 md:text-7'>
        <Trans>Discounts</Trans>
      </h2>
      <NoddiBasicCard onClick={() => navigate(routes.bonus.getPath())} className='flex flex-col gap-5'>
        <p className='font-bold text-5'>
          {userHasCoupons ? <Trans>Hurray!</Trans> : <Trans>Invite friends to Noddi</Trans>} 🥳
        </p>

        {userHasCoupons ? (
          <p>
            <Trans>You have</Trans> <b>{coupons.length}</b> {currentCouponsMessage}
          </p>
        ) : (
          <p>
            <Trans>
              Refer a friend and you both get up to 300 kr in discounts on future bookings. Save money and time together
              with Noddi!
            </Trans>
            💜
          </p>
        )}
        <div className='flex justify-end'>
          <NoddiDummyButton variant='link' endIcon='ArrowRight' className='pb-0 pr-0'>
            <Trans>See details</Trans>
          </NoddiDummyButton>
        </div>
      </NoddiBasicCard>
      <div className='flex flex-col gap-6'>
        {membershipPrograms?.map((membershipProgram) => (
          <div className='flex flex-col gap-2' key={membershipProgram.id}>
            <h2 className='font-bold'>
              <Trans>Membership benefits</Trans> {membershipProgram.name}
            </h2>
            <div className='flex w-full flex-col gap-3'>
              {membershipProgram.discounts.map((discount) => (
                <div key={discount.id}>
                  <MembershipDiscount discount={discount} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Discounts;
