import * as Sentry from '@sentry/react';
import * as ReactQuery from '@tanstack/react-query';
import { noddiAsync } from 'noddi-async';
import { tracking } from 'noddi-provider';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import sentryConfig from '../../../packages/config/sentry';
import '../global.css';
import App from './App';
import './index.css';

const environment = import.meta.env.MODE;

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_APP_GOOGLE_TAG_MANAGER_ID ?? ''
};

Sentry.init({
  ...sentryConfig,
  integrations: [
    ...sentryConfig.integrations,
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    })
  ],
  // This sets the sample rate at 10%. We may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: environment === 'development' ? 1.0 : 0.1,
  // Session Replay
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

tracking.initMixpanel({ projectToken: import.meta.env.VITE_APP_MIXPANEL_PROJECT_TOKEN ?? '' });

if (import.meta.env.MODE === 'production') {
  TagManager.initialize(tagManagerArgs);
}

noddiAsync.init({
  reactQuery: ReactQuery,
  baseUrl: import.meta.env.VITE_APP_BE_URL ?? ''
});

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
