import routes from '../../../appRoutes';

export const addressStepPath = routes.newBookingAddress.getPath();
export const carStepPath = routes.newBookingCars.getPath();
export const salesItemStepPath = routes.newBookingPrimaryItems.getPath();
export const tierHotelCancelStepPath = routes.newBookingCancelTireHotel.getPath();
export const tierHotelPickupStepPath = routes.newBookingTierHotel.getPath();
export const addonsStepPath = routes.newBookingAddons.getPath();
export const timeWindowStepPath = routes.newBookingTimeWindow.getPath();
export const summaryStepPath = routes.newBookingSummary.getPath();
