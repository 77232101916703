import { Card, CardContent, CardHeader, CardProps, Divider, Stack, Typography } from '@mui/material';
import { ServerTypes, URLKeys, noddiAsync } from 'noddi-async';
import { CarOrderLine, Currency } from 'noddi-async/src/types';
import { formatCurrencyAmount } from 'noddi-util';
import { ReactElement, useState } from 'react';

import { invalidateQueryExactMatch } from 'noddi-async/src/utils';
import { NoddiButton, NoddiDialog, NoddiIconButton } from 'noddi-ui';
import { NoddiIcon } from '../../atoms';
import { commonTexts } from '../../commonTexts';
import { useNoddiToast } from '../../providers';

type AdminProps = {
  bookingId: number;
  userGroupId: number;
};

type OrderSummaryProps = {
  MetaSection?: ReactElement;
  isPaid: boolean;
  isInvoiced: boolean;
  carItemLines: CarOrderLine[];
  discountLines: ServerTypes.BookingOrderLineProps[];
  otherOrderLines: ServerTypes.BookingOrderLineProps[];
  adminProps?: AdminProps;
  showHeader?: boolean;
  title?: string;
  translations?: {
    total: string;
    vat: string;
    yourOrder: string;
    discount: string;
    other: string;
  };
} & CardProps;

type OrderLineType = {
  id: number;
  isPaid: boolean;
  isInvoiced: boolean;
  title: string;
  price: number;
  currency: Currency;
  unableToComplete?: boolean;
  adminProps?: AdminProps;
};
type SubHeader = {
  title: string;
  icon: ReactElement;
};

type BookingItemSummary = {
  subHeader: SubHeader;
  orderLines: OrderLineType[];
};

const BookingItemHeader = ({ title, icon }: SubHeader) => {
  return (
    <Stack direction='row' gap={2}>
      {icon}

      <Typography variant='h6' color='text.secondary' fontSize='13px'>
        {title}
      </Typography>
    </Stack>
  );
};

interface DeleteOrderLineModalProps {
  open: boolean;
  onClose: () => void;
  id: number;
  adminProps: AdminProps;
}

const DeleteOrderLineModal = ({ open, onClose, id, adminProps }: DeleteOrderLineModalProps) => {
  const { mutateAsync: deleteOrderLine } = noddiAsync.useDelete({
    type: URLKeys.deleteOrderLine,
    queryConfig: {
      onSuccess: async () => {
        invalidateQueryExactMatch({ urlKey: URLKeys.getBookingOrder, input: { bookingId: adminProps.bookingId } });
        noddiToast.success('Order line deleted!');
      },
      onError: async (error) => {
        noddiToast.asyncError(error);
      }
    }
  });

  const { noddiToast } = useNoddiToast();

  return (
    <NoddiDialog title='Delete order line' onClose={onClose} open={open}>
      <div className='flex flex-col gap-4'>
        <p>Are you sure you want to delete this order line?</p>
        <NoddiButton
          variant='destructive'
          onClick={() => {
            deleteOrderLine({ orderLineId: id.toString() });
            onClose();
          }}
        >
          Delete
        </NoddiButton>
      </div>
    </NoddiDialog>
  );
};

const OrderLine = ({ id, isPaid, isInvoiced, title, price, currency, unableToComplete, adminProps }: OrderLineType) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const isDeleteAllowed = !isPaid && !isInvoiced;

  const Header = () => (
    <Typography
      variant='body2'
      color='text.secondary'
      fontSize='13px'
      sx={{ textDecoration: unableToComplete ? 'line-through' : 'none' }}
    >
      {title}
    </Typography>
  );

  const Value = () => (
    <Typography
      variant='body2'
      color='text.secondary'
      fontSize='13px'
      sx={{ textDecoration: unableToComplete ? 'line-through' : 'none' }}
    >
      {formatCurrencyAmount(price, 2, currency)}
    </Typography>
  );
  return (
    <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
      {adminProps ? (
        <div className='flex w-full items-center justify-between gap-2'>
          <Header />
          <div className='flex items-center gap-2'>
            <Value />
            {isDeleteAllowed && (
              <NoddiIconButton
                iconName='TrashBin'
                iconSize='small'
                variant='destructive'
                onClick={() => setShowDeleteModal(true)}
              />
            )}
          </div>
          <DeleteOrderLineModal
            open={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            id={id}
            adminProps={adminProps}
          />
        </div>
      ) : (
        <>
          <Header />
          <Value />
        </>
      )}
    </Stack>
  );
};

const OrderSummary = ({
  showHeader = true,
  isPaid,
  isInvoiced,
  carItemLines,
  MetaSection,
  discountLines,
  otherOrderLines,
  adminProps,
  title,
  translations,
  ...cardProps
}: OrderSummaryProps) => {
  const carOrders: BookingItemSummary[] = [];
  const discountOrderLines: OrderLineType[] = [];
  const others: BookingItemSummary[] = [];

  const texts = translations ?? commonTexts.orderSummary;
  carItemLines.forEach((car) => {
    const subHeader = {
      title: car.name,
      bookingItemId: car.bookingItemId,
      icon: <NoddiIcon name='Car' size='medium' />
    };
    const orderLines: OrderLineType[] = [];

    car.orderLines.forEach((orderLine) => {
      const price = orderLine.pricePerUnit * orderLine.quantity;
      if (price < 0) {
        discountOrderLines.push({
          isPaid,
          isInvoiced,
          title: orderLine.description,
          id: orderLine.id,
          price,
          currency: orderLine.currency,
          unableToComplete: !!orderLine.unableToComplete
        });
      } else {
        orderLines.push({
          isPaid,
          isInvoiced,
          id: orderLine.id,
          title: orderLine.description,
          price,
          currency: orderLine.currency,
          unableToComplete: !!orderLine.unableToComplete
        });
      }
    });
    carOrders.push({
      subHeader,
      orderLines
    });
  });

  if (otherOrderLines.length > 0) {
    const subHeader = {
      title: texts.other,
      icon: <NoddiIcon name='ShoppingCart' size='medium' />
    };
    const orderLines = otherOrderLines.map((orderLine) => {
      return {
        title: orderLine.description,
        isPaid,
        isInvoiced,
        id: orderLine.id,
        price: orderLine.pricePerUnit * orderLine.quantity,
        currency: orderLine.currency,
        unableToComplete: !!orderLine.unableToComplete
      };
    });
    others.push({
      subHeader,
      orderLines
    });
  }

  discountLines.forEach((orderLine) => {
    discountOrderLines.push({
      isPaid,
      isInvoiced,
      id: orderLine.id,
      title: orderLine.description,
      price: orderLine.pricePerUnit * orderLine.quantity,
      currency: orderLine.currency,
      unableToComplete: !!orderLine.unableToComplete
    });
  });

  const allOrders = [...carOrders, ...others];

  if (discountOrderLines.length > 0) {
    allOrders.push({
      subHeader: {
        title: texts.discount,
        icon: <NoddiIcon name='Bill' size='medium' />
      },
      orderLines: discountOrderLines
    });
  }

  const allOrderLines = allOrders.flatMap((x) => x.orderLines);
  if (!allOrderLines || allOrderLines.length === 0) {
    throw new Error('No order lines found');
  }

  const currency = allOrderLines[0]?.currency;
  if (!currency) {
    throw new Error('No currency found');
  }

  allOrderLines.forEach((orderLine) => {
    if (orderLine.currency !== currency) {
      throw new Error('Currencies do not match');
    }
  });

  const totalPrice = allOrderLines.filter((x) => !x.unableToComplete).reduce((acc, x) => acc + x.price, 0);
  const mva = totalPrice * 0.2;

  return (
    <Card sx={{ width: '100%' }} {...cardProps}>
      {showHeader && (
        <>
          <CardHeader title={title ?? texts.yourOrder} />
          <Divider />
        </>
      )}
      <CardContent>
        {MetaSection && (
          <>
            {MetaSection}
            <Divider sx={{ marginY: '1rem' }} />
          </>
        )}
        {allOrders.map((order) => (
          <div key={order.subHeader.title}>
            <BookingItemHeader {...order.subHeader} />

            {order.orderLines.map((orderLine) => (
              <OrderLine {...orderLine} key={orderLine.id} adminProps={adminProps} />
            ))}
            <Divider sx={{ marginY: '1rem' }} />
          </div>
        ))}
        <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
          <p>{texts.vat}</p>
          <p>{formatCurrencyAmount(mva, 2, currency)}</p>
        </Stack>
        <Stack direction='row' justifyContent='space-between' paddingTop={1} alignItems='center'>
          <p className='font-bold text-5'>{texts.total}</p>

          <p>{formatCurrencyAmount(totalPrice, 2, currency)}</p>
        </Stack>
      </CardContent>
    </Card>
  );
};

export { OrderSummary };
