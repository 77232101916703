import { Path, Svg, SvgProps } from 'react-native-svg';

const HomeFilled = ({ color, ...props }: SvgProps) => (
  <Svg width={30} height={30} viewBox='0 0 30 30' fill='none' {...props}>
    <Path
      fill={color}
      fillRule='evenodd'
      d='M5.52 10.823C5 11.77 5 12.915 5 15.203v1.522c0 3.9 0 5.851 1.172 7.063C7.343 25 9.229 25 13 25h4c3.771 0 5.657 0 6.828-1.212C25 22.576 25 20.626 25 16.725v-1.521c0-2.289 0-3.433-.52-4.381-.518-.949-1.467-1.537-3.364-2.715l-2-1.241C17.111 5.622 16.108 5 15 5c-1.108 0-2.11.622-4.116 1.867l-2 1.241c-1.897 1.178-2.846 1.766-3.365 2.715ZM12 20.25a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5h-6Z'
      clipRule='evenodd'
    />
  </Svg>
);
export { HomeFilled };
