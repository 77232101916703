import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { URLKeys, noddiAsync } from 'noddi-async';
import { UserData, UserDataProps } from 'noddi-async/src/types';
import { useAuthContext } from 'noddi-provider';
import { useEffect, useRef } from 'react';

import { commonTexts } from '../../../commonTexts';
import { errorCodes as errorCodesWithoutTranslation } from '../../../errorCodes';
import { NoddiButton } from '../../../molecules';
import { ApiErrorMessage, ErrorCodes, NoddiFormTextInput, SectionSpacer } from '../../Elements';
import userRegistrationFormSchema from './validators';

interface IFormInput {
  firstName: string;
  surname: string;
  email: string;
  password?: string;
  confirmPassword?: string;
  orgName?: string;
  orgNumber?: string;
}

type UserRegistrationFormProps = {
  phoneNumber: string;
  skipPasswordCreation?: boolean;
  trackUserIdentity?: (userData: UserData) => void;
  customCTAText?: string;
  orgProperties?: {
    orgName: string;
    orgNumber: string;
  };
  onLoginSuccess?: (userData: UserDataProps) => void;
  translations?: {
    thisFieldIsRequired: string;
    thisFieldMustBeAtLeast2CharactersLong: string;
    mustProvideAValidEmailAddress: string;
    passwordsMustMatch: string;
    thePasswordMustBeAtLeast8CharactersLong: string;
    firstName: string;
    surname: string;
    email: string;
    password: string;
    confirmPassword: string;
    userInformation: string;
    createUser: string;
  };
  errorCodes?: ErrorCodes;
};

const UserRegistrationForm = ({
  phoneNumber,
  skipPasswordCreation,
  trackUserIdentity,
  customCTAText,
  orgProperties,
  onLoginSuccess,
  translations,
  errorCodes
}: UserRegistrationFormProps) => {
  const texts = translations ?? commonTexts.registrationTranslations;
  const errorCodeTexts = errorCodes ?? errorCodesWithoutTranslation;
  const { loginUser } = useAuthContext();
  const commonInitialValues = {
    firstName: '',
    surname: '',
    email: ''
  };

  const initialValues = skipPasswordCreation
    ? commonInitialValues
    : { ...commonInitialValues, password: '', confirmPassword: '' };

  const extendedInitialValues = orgProperties ? { ...initialValues, ...orgProperties } : initialValues;

  const {
    mutateAsync: createUser,
    isPending: isCreateUserPending,
    error: createUserError
  } = noddiAsync.usePost({
    type: URLKeys.postUser,
    queryConfig: {
      onSuccess: ({ data }) => {
        trackUserIdentity?.(data.user);
        loginUser(data);
        onLoginSuccess?.(data);
      }
    }
  });

  const {
    mutateAsync: createOrgUser,
    isPending: isCreateOrgUserPending,
    error: orgUserError
  } = noddiAsync.usePost({
    type: URLKeys.postUserWithOrganization,
    queryConfig: {
      onSuccess: ({ data }) => {
        trackUserIdentity?.(data.user);
        loginUser(data);
        onLoginSuccess?.(data);
      }
    }
  });

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }, [ref]);

  const ctaText = customCTAText ?? texts.createUser;
  const hasError = createUserError ?? orgUserError;

  return (
    <Formik
      initialValues={extendedInitialValues}
      onSubmit={async (data: IFormInput) => {
        const commonPayload = {
          firstName: data.firstName,
          lastName: data.surname,
          phoneNumber,
          email: data.email,
          password: data.password,
          confirmPassword: data.confirmPassword
        };
        if (orgProperties) {
          await createOrgUser({
            ...commonPayload,
            organizationName: orgProperties.orgName,
            organizationNumber: orgProperties.orgNumber
          });
          return;
        }
        await createUser(commonPayload);
      }}
      validationSchema={userRegistrationFormSchema(!!skipPasswordCreation, texts)}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Typography variant='h4'>{texts.userInformation}</Typography>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput ref={ref} name='firstName' label={texts.firstName} fullWidth />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput name='surname' label={texts.surname} />
          </SectionSpacer>
          <SectionSpacer fullWidth variant='small'>
            <NoddiFormTextInput name='email' label={texts.email} placeholder='test@example.com' />
          </SectionSpacer>
          {!skipPasswordCreation && (
            <>
              <SectionSpacer fullWidth variant='small'>
                <NoddiFormTextInput
                  autoComplete='new-password'
                  name='password'
                  label={texts.password}
                  type='password'
                />
              </SectionSpacer>
              <SectionSpacer fullWidth variant='small'>
                <NoddiFormTextInput
                  autoComplete='new-password'
                  name='confirmPassword'
                  label={texts.confirmPassword}
                  type='password'
                />
              </SectionSpacer>
            </>
          )}
          {hasError && <ApiErrorMessage errorCodes={errorCodeTexts} error={hasError} />}
          <NoddiButton
            type='submit'
            className='mt-6 w-full'
            disabled={!isValid}
            loading={isCreateUserPending || isSubmitting || isCreateOrgUserPending}
          >
            {ctaText}
          </NoddiButton>
        </Form>
      )}
    </Formik>
  );
};

export { UserRegistrationForm };
