import { Trans } from '@lingui/macro';
import { Stack } from '@mui/material';
import { ServerTypes, URLKeys, noddiAsync } from 'noddi-async';
import { useAuthContext } from 'noddi-provider';
import { NoddiButton, NoddiIcon, colors } from 'noddi-ui';
import styled from 'styled-components';

import { ApiErrorMessageWithTrans } from '../../components/ApiErrorMessageWithTrans';
import NewBookingButton from '../../components/NewBookingButton/NewBookingButton';

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 42px;
`;

interface ActivateCampaignProps {
  campaign: ServerTypes.CampaignPublic;
}
export default function ActivateCampaign({ campaign }: ActivateCampaignProps) {
  const { currentUserGroupId } = useAuthContext();

  const {
    mutateAsync: activateCampaign,
    isPending: isActivateCampaignPending,
    isSuccess: isActivateCampaignSuccess,
    error: activateCampaignError
  } = noddiAsync.usePost({
    type: URLKeys.postActivateCampaign
  });

  if (!currentUserGroupId) {
    throw new Error('User group id is required');
  }

  return (
    <Stack>
      {isActivateCampaignSuccess ? (
        <Stack>
          <FlexContainer>
            <NoddiIcon name='Check' color={colors.primary.orange} className='mr-1' />
            <span style={{ marginLeft: '7px', fontSize: '1.2rem' }}>
              <Trans>The coupons have been activated!</Trans> 🎉
            </span>
          </FlexContainer>
          <p className='mt-4'>
            <Trans>You can now make a new booking with your coupons.</Trans>
          </p>
          <NewBookingButton loading={isActivateCampaignPending} className='mt-6' />
        </Stack>
      ) : (
        <>
          {activateCampaignError && <ApiErrorMessageWithTrans error={activateCampaignError} />}
          <NoddiButton
            style={{ marginTop: '24px' }}
            loading={isActivateCampaignPending}
            onClick={async () =>
              await activateCampaign({
                userGroupId: currentUserGroupId,
                campaignId: campaign.id
              })
            }
          >
            <Trans>Activate coupons</Trans>
          </NoddiButton>
        </>
      )}
    </Stack>
  );
}
