import { Circle, Path, Svg, SvgProps } from 'react-native-svg';

const FilledCircleCross = ({ color, ...props }: SvgProps) => (
  <Svg width={26} height={27} fill='none' viewBox='0 0 26 27' {...props}>
    <Circle cx={13} cy={13.5} r={9.5} fill={color} stroke={color} />
    <Path stroke='#fff' strokeLinecap='round' strokeWidth={1.5} d='m9 9.5 8 8m0-8-8 8' />
    <Circle cx={13} cy={13.5} r={11.25} stroke='#fff' strokeWidth={1.5} />
    <Circle cx={13} cy={13.5} r={12.5} stroke={color} />
  </Svg>
);
export { FilledCircleCross };
