import { TZDate } from '@date-fns/tz';
import { AvailableBookingTimeWindowV2 } from 'noddi-async/src/types';
import { DateFormats, format } from 'noddi-util';

export const getBookingTimeWindowDisplayText = (timeWindow: AvailableBookingTimeWindowV2) => {
  const startDate = new TZDate(timeWindow.startsAt, timeWindow.timeZone);
  const endDate = new TZDate(timeWindow.endsAt, timeWindow.timeZone);
  const startDateFormatted = format(startDate, DateFormats.FULL_MONTH_YEAR_WITHOUT_COMMA);
  const startTimeFormatted = format(startDate, DateFormats.TIME);
  const endTimeFormatted = format(endDate, DateFormats.TIME);

  return `${startDateFormatted}, ${startTimeFormatted} - ${endTimeFormatted}`;
};
