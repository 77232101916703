import { NoddiIcon } from '../../../../atoms';
import { NoddiCollapseCard } from '../../../Elements/Cards/NoddiCollapseCard';

interface GeneralTireInfoProps {
  translations: {
    standardsForWinterAndSummerTires: string;
    winterTires: string;
    summerTires: string;
    winterTiresMinimumNotice: string;
    summerTiresMinimumNotice: string;
  };
}

const GeneralTireInfo = ({ translations }: GeneralTireInfoProps) => {
  return (
    <NoddiCollapseCard
      sx={{ marginTop: 2 }}
      header={
        <div className='flex items-center gap-3'>
          <div>
            <NoddiIcon name='Info' />
          </div>
          <p className='text-5 font-semibold'>{translations.standardsForWinterAndSummerTires}</p>
        </div>
      }
      collapseBody={
        <div className='mt-4 flex flex-col gap-3'>
          <p className='font-bold'>{translations.winterTires} </p>
          <p>{translations.winterTiresMinimumNotice}</p>

          <p className='font-bold'>{translations.summerTires}</p>
          <p>{translations.summerTiresMinimumNotice}</p>
        </div>
      }
    />
  );
};

export { GeneralTireInfo };
