import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

const Gallery = ({ color, ...props }: SvgProps) => (
  <Svg viewBox='0 0 30 30' width={30} height={30} fill='none' {...props}>
    <Path
      stroke={color}
      strokeWidth={1.5}
      d='M5 15c0-4.714 0-7.071 1.464-8.536C7.93 5 10.286 5 15 5c4.714 0 7.071 0 8.535 1.464C25 7.93 25 10.286 25 15c0 4.714 0 7.071-1.465 8.535C22.072 25 19.714 25 15 25s-7.071 0-8.536-1.465C5 22.072 5 19.714 5 15Z'
    />
    <Circle cx={19} cy={11} r={2} stroke={color} strokeWidth={1.5} />
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeWidth={1.5}
      d='m5 15.5 1.752-1.533a2.3 2.3 0 0 1 3.14.105l4.29 4.29a2 2 0 0 0 2.564.222l.299-.21a3 3 0 0 1 3.731.225L24 21.5'
    />
  </Svg>
);
export { Gallery };
