import { Path, Svg, SvgProps } from 'react-native-svg';

const ArrowLeft = ({ color, ...props }: SvgProps) => (
  <Svg width={32} height={32} viewBox='0 0 32 32' fill='none' {...props}>
    <Path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M24.533 16H7.467m0 0 6.4-6.4m-6.4 6.4 6.4 6.4'
    />
  </Svg>
);
export { ArrowLeft };
